export const validateIcaoTypeDesignator = (
  enabled: boolean,
  value: string | null,
  asset: Pick<AssetBasic, 'category'>,
  validTypeDesignators: string[] | undefined,
) => {
  if (!enabled) return true;
  if (!['Aircraft', 'Helicopter'].includes(asset.category)) return true;
  if (!validTypeDesignators) return true;
  return value !== null && (value === 'other' || validTypeDesignators.includes(value));
};
