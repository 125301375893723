import DownloadMenu from 'components/shared/downloadMenu';
import React, { useMemo } from 'react';
import DownloadEngineUsageMenuItem from './downloadEngineUsageMenuItem';
import DownloadSummaryMenuItem from './downloadSummaryMenuItem';
import DownloadTripsMenuItem from './downloadTripsMenuItem';

type Query = ReduxState['settings']['tripAnalysis']['query'];

interface DownloadButtonProps {
  query: Query;
  assets: AssetWithDevice[];
  timezone: string;
  disabled: boolean;
}

const NO_ASSETS: AssetWithDevice[] = [];

const Download = ({ query, assets, timezone, disabled }: DownloadButtonProps) => {
  const relevantAssets = useMemo(
    () => assets.filter(asset => query.assets?.includes(asset.id)) ?? NO_ASSETS,
    [assets, query.assets],
  );

  return (
    <DownloadMenu disabled={disabled}>
      {close => [
        <DownloadSummaryMenuItem
          key="summary"
          assets={relevantAssets}
          from={query.from}
          until={query.until}
          close={close}
        />,
        <DownloadTripsMenuItem
          key="trips"
          assets={relevantAssets}
          from={query.from}
          until={query.until}
          timezone={timezone}
          close={close}
        />,
        <DownloadEngineUsageMenuItem
          key="engineCycles"
          assets={relevantAssets}
          from={query.from}
          until={query.until}
          timezone={timezone}
          close={close}
        />,
      ]}
    </DownloadMenu>
  );
};

export default Download;
