import { HelpOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useGetAssetBasicAssignedToDevice } from 'apis/rest/assets/hooks';
import { useAssignAssetToDevice, useGetDevicesList, useUnassignAssetFromDevice } from 'apis/rest/devices/hooks';
import { TPDialogActions } from 'components/dialogs/shared/TPDialogActions';
import { TPDialogTitle } from 'components/dialogs/shared/TPDialogTitle';
import DetailPanel from 'components/shared/DetailPanel';
import { useAssetLabel } from 'components/shared/assetLabel';
import { TPButton } from 'components/shared/button/TPButton';
import SettingsSection from 'components/shared/settingsSection';
import { deviceStatus } from 'constants/deviceStatus';
import useFeatureFlag from 'hooks/useFeatureFlag';
import type { SnackbarSettings } from 'hooks/useSnackbar';
import mixpanel from 'mixpanel-browser';
import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import useStyles from './asset-styles';

interface AssetDevicesProps {
  asset: AssetBasic;
  hasDevice: boolean;
  device: DeviceBasic | undefined;
  organisationId: string;
  displaySnackbar: (options: SnackbarSettings) => void;
  serialType: string;
  readOnly: boolean;
}

const AssetDevices = ({
  asset,
  hasDevice,
  device,
  organisationId,
  displaySnackbar,
  serialType,
  readOnly,
}: AssetDevicesProps) => {
  const t = useTranslations('pages.assetView');
  const tDevices = useTranslations('pages.devices');
  const classes = useStyles();
  const [selectDeviceDialogOpen, setSelectDeviceDialogOpen] = useState(false);
  const [confirmChangeDeviceDialogOpen, setConfirmChangeDeviceDialogOpen] = useState(false);
  const [confirmRemoveDeviceDialogOpen, setConfirmRemoveDeviceDialogOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<DeviceBasic | undefined>();
  const [transferSharesEnabled, setTransferSharesEnabled] = useState(false);
  const assetAssignedToDeviceQuery = useGetAssetBasicAssignedToDevice(
    selectedDevice?.id ?? 0,
    !!selectedDevice?.id,
  ).query;
  const assetLabel = useAssetLabel();

  const canChangeDevice = useMemo(() => !(asset.deviceMake === 'TracPlus' && asset.deviceModel === 'Beacon'), [asset]);

  const devicesQuery = useGetDevicesList(undefined, true).query;
  const devices = useMemo(
    () =>
      devicesQuery.data?.filter(d => d.id !== asset.deviceId && !(d.make === 'TracPlus' && d.model === 'Beacon')) ?? [],
    [devicesQuery.data, asset],
  );

  useEffect(() => {
    if (devicesQuery.isError) {
      displaySnackbar({ id: 'getDevicesFailedSnackbar', text: t('getDevicesFailed'), type: 'error' });
    }
  }, [devicesQuery.isError, displaySnackbar, t]);

  const clearState = () => {
    setSelectedDevice(undefined);
    setTransferSharesEnabled(false);
  };

  const assignDeviceAssetMutation = useAssignAssetToDevice();
  const unassignAssetMutation = useUnassignAssetFromDevice();

  const enableTransferShares = useFeatureFlag('frontendTransferShares');

  const handleConfirmChangeDeviceConfirm = () => {
    if (!selectedDevice?.id) {
      return;
    }

    assignDeviceAssetMutation.mutate(
      { assetId: asset.id, deviceId: selectedDevice.id, transferShares: transferSharesEnabled },
      {
        onError: () => {
          displaySnackbar({ id: 'assetDetailsSaveFailed', text: t('assignDeviceToAssetFailed'), type: 'error' });
          mixpanel.track('Assign Device to Asset', { success: false, asset, device: selectedDevice, organisationId });
        },
        onSuccess: () => {
          displaySnackbar({ id: 'assetDetailsSaved', text: t('assignDeviceToAssetSaved'), type: 'success' });
          mixpanel.track('Assign Device to Asset', { success: true, asset, device: selectedDevice, organisationId });
        },
        onSettled: async () => {
          setConfirmChangeDeviceDialogOpen(false);
          clearState();
        },
      },
    );
  };

  const handleRemoveDeviceDialogConfirm = () => {
    if (hasDevice && !device) throw new Error('expected to have a device but it is undefined');
    unassignAssetMutation.mutate(
      // biome-ignore lint/style/noNonNullAssertion: checked above
      { assetId: asset.id, deviceId: device!.id },
      {
        onError: () => {
          displaySnackbar({ id: 'removeDeviceFromAssetFailed', text: t('removeDeviceFromAssetFailed'), type: 'error' });
          mixpanel.track('Unassign Device from Asset', { success: false, asset, organisationId });
        },
        onSuccess: () => {
          displaySnackbar({
            id: 'removeDeviceFromAssetSucceeded',
            text: t('removeDeviceFromAssetSucceeded'),
            type: 'success',
          });
          mixpanel.track('Unassign Device from Asset', { success: true, asset, organisationId });
        },
        onSettled: async () => {
          setConfirmRemoveDeviceDialogOpen(false);
          clearState();
        },
      },
    );
  };

  const openRemoveDeviceDialog = () => {
    setConfirmRemoveDeviceDialogOpen(true);
  };

  const handleRemoveDeviceDialogClose = () => {
    setConfirmRemoveDeviceDialogOpen(false);
  };

  const handleChangeDeviceConfirm = () => {
    setSelectDeviceDialogOpen(false);
    setConfirmChangeDeviceDialogOpen(true);
  };

  const openSelectDeviceDialog = () => {
    setSelectDeviceDialogOpen(true);
  };

  const handleChangeDeviceDialogClose = () => {
    setSelectDeviceDialogOpen(false);
    clearState();
  };

  const handleConfirmChangeDeviceDialogClose = () => {
    setConfirmChangeDeviceDialogOpen(false);
    clearState();
  };

  const getDeviceOptionLabel = (option?: DeviceBasic) => {
    if (!option) {
      return '';
    }
    let serial = '';
    if (serialType === 'tpSerial') {
      serial = option.tpSerial;
    } else if (serialType === 'manufacturerSerial') {
      serial = option.manufacturerSerial;
    } else if (serialType === 'imei') {
      serial = option.imei;
    }
    const makeModel = option.make || option.model ? `${option?.make} ${option?.model}` : '';
    return makeModel ? `${makeModel} (${serial})` : '';
  };

  const isSystemAsset = (assetToCheck?: AssetBasic) => {
    if (!assetToCheck) {
      return false;
    }
    return assetToCheck.category === 'System';
  };

  const getAssetDisplayInformation = (assetToDisplay?: AssetBasic) => {
    if (!assetToDisplay) {
      return '...';
    }
    let display = '';
    if (assetToDisplay.category !== 'System') {
      display += assetLabel(assetToDisplay, '');
    }
    return display;
  };

  return (
    <SettingsSection title={t('deviceTitle')} description={t('deviceDescription')}>
      <DetailPanel p={3}>
        {hasDevice && device && (
          <>
            <Typography variant="h3">
              {device.make} {device.model}
            </Typography>
            <Grid container sx={theme => ({ margin: theme.spacing(2, 0) })} component="dl">
              <Grid item xs={6} xl={4} component="dt">
                {t('tpSerial')}:
              </Grid>
              <Grid item xs={6} xl={8} component="dd">
                {device.tracPlusSerial}
              </Grid>
              <Grid item xs={6} xl={4} component="dt">
                {t('imei')}:
              </Grid>
              <Grid item xs={6} xl={8} component="dd">
                {device.imei}
              </Grid>
              <Grid item xs={6} xl={4} component="dt">
                {t('manufacturerSerial')}:
              </Grid>
              <Grid item xs={6} xl={8} component="dd">
                {device.manufacturerSerial}
              </Grid>
              <Grid item xs={6} xl={4} component="dt">
                {t('status')}:
              </Grid>
              <Grid item xs={6} xl={8} component="dd">
                {deviceStatus.find(s => s.id === device.status)?.description ?? t('unknownStatus')}
              </Grid>
            </Grid>
          </>
        )}
        {readOnly && hasDevice && device && (
          <Box>
            <Button
              className={classes.inputButton}
              variant="contained"
              color="primary"
              component={Link}
              to={`/manage/devices/${device.id}`}
            >
              {tDevices('viewDevice')}
            </Button>
          </Box>
        )}
        {!readOnly && hasDevice && device && (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Button
              className={classes.inputButton}
              variant="contained"
              color="primary"
              component={Link}
              to={`/manage/devices/${device.id}`}
            >
              {tDevices('editDevice')}
            </Button>
            {canChangeDevice && (
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  id="removeDeviceButton"
                  className={classes.inputButton}
                  variant="contained"
                  color="primary"
                  onClick={openRemoveDeviceDialog}
                >
                  {t('removeDevice')}
                </Button>
                <Button
                  id="changeDeviceButton"
                  className={classes.inputButton}
                  variant="contained"
                  color="primary"
                  onClick={openSelectDeviceDialog}
                >
                  {t('changeDevice')}
                </Button>
              </Stack>
            )}
          </Stack>
        )}
        {!readOnly && !hasDevice && (
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>{t('noDeviceAssigned')}</Typography>
              <Button
                id="changeDeviceButton"
                className={classes.inputButton}
                variant="contained"
                color="primary"
                onClick={openSelectDeviceDialog}
                disabled={asset.archived}
              >
                {t('assign')}
              </Button>
            </Stack>
            {asset.archived && <Alert severity="warning">{t('deviceAssignDisabled')}</Alert>}
          </Stack>
        )}
      </DetailPanel>
      <Dialog open={selectDeviceDialogOpen} onClose={handleChangeDeviceDialogClose} aria-labelledby="form-dialog-title">
        <TPDialogTitle>{hasDevice ? t('changeDeviceDialogTitle') : t('assignDeviceDialogTitle')}</TPDialogTitle>
        <DialogContent className={classes.changeDeviceDialog} sx={{ mt: 3 }}>
          <DialogContentText>
            {hasDevice ? t('changeDeviceDialogContent') : t('assignDeviceDialogContent')}
          </DialogContentText>
          <FormControl variant="standard" className={classes.formControl}>
            <Autocomplete
              id="dialog-select"
              options={devices.sort((a, b) => a.tpSerial?.localeCompare(b.tpSerial))}
              getOptionLabel={option => getDeviceOptionLabel(option)}
              isOptionEqualToValue={(option, value) => Object.keys(value).length === 0 || value.id === option.id}
              value={selectedDevice}
              onChange={(_, newValue) => {
                if (newValue) {
                  setSelectedDevice(newValue);
                }
              }}
              renderInput={params => <TextField variant="standard" {...params} label={t('device')} fullWidth />}
              filterOptions={createFilterOptions({
                stringify: option => getDeviceOptionLabel(option),
              })}
            />
          </FormControl>
          {enableTransferShares && hasDevice && (
            <Stack direction="row" pt={3} alignItems="center" justifyContent="space-between">
              <FormControlLabel
                label={t('changeDeviceDialogTransferShares')}
                control={
                  <Checkbox
                    checked={transferSharesEnabled}
                    onChange={e => setTransferSharesEnabled(e.target.checked)}
                  />
                }
              />
              <Tooltip title={t('changeDeviceDialogTransferSharesHelp')} placement="left">
                <HelpOutline sx={{ color: 'common.text' }} />
              </Tooltip>
            </Stack>
          )}
        </DialogContent>
        <TPDialogActions>
          <TPButton variant="outlined" autoFocus onClick={handleChangeDeviceDialogClose}>
            {t('cancelButton')}
          </TPButton>
          <TPButton onClick={handleChangeDeviceConfirm} color="primary" disabled={!selectedDevice?.id}>
            {t('selectDeviceButton')}
          </TPButton>
        </TPDialogActions>
      </Dialog>
      <Dialog
        open={confirmChangeDeviceDialogOpen}
        onClose={handleConfirmChangeDeviceDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <TPDialogTitle>{t('confirmChangeDeviceDialogTitle')}</TPDialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          {isSystemAsset(assetAssignedToDeviceQuery.data) && (
            <DialogContentText>
              {t('confirmChangeDeviceDialogDevice')} {getDeviceOptionLabel(selectedDevice)}{' '}
              {t('confirmChangeDeviceDialogWillBeAssignedTo')} {assetLabel(asset)}.
            </DialogContentText>
          )}
          {!isSystemAsset(assetAssignedToDeviceQuery.data) && (
            <DialogContentText>
              {t('confirmChangeDeviceDialogDevice')} {getDeviceOptionLabel(selectedDevice)}{' '}
              {t('confirmChangeDeviceDialogWillBeUnassignedFrom')}{' '}
              {getAssetDisplayInformation(assetAssignedToDeviceQuery.data)}{' '}
              {t('confirmChangeDeviceDialogAndAssignedTo')} {assetLabel(asset)}.
            </DialogContentText>
          )}
        </DialogContent>
        <TPDialogActions>
          <TPButton variant="outlined" autoFocus onClick={handleConfirmChangeDeviceDialogClose}>
            {t('cancelButton')}
          </TPButton>
          <TPButton onClick={handleConfirmChangeDeviceConfirm} color="primary" disabled={!selectedDevice?.id}>
            {t('changeDeviceButton')}
          </TPButton>
        </TPDialogActions>
      </Dialog>
      <Dialog
        open={confirmRemoveDeviceDialogOpen}
        onClose={handleRemoveDeviceDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <TPDialogTitle>{t('removeDeviceDialogTitle')}</TPDialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <DialogContentText>{t('removeDeviceDialogText')}</DialogContentText>
        </DialogContent>
        <TPDialogActions>
          <Button autoFocus onClick={handleRemoveDeviceDialogClose}>
            {t('cancelButton')}
          </Button>
          <Button variant="contained" onClick={handleRemoveDeviceDialogConfirm} color="primary">
            {t('removeDeviceConfirmationButton')}
          </Button>
        </TPDialogActions>
      </Dialog>
    </SettingsSection>
  );
};

export default AssetDevices;
