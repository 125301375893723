import {
  Autocomplete,
  Box,
  Button,
  Container,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGetIcaoTypeDesignators } from 'apis/aircraftTypes/hooks';
import { useCreateAsset, useGetAssetsList } from 'apis/rest/assets/hooks';
import Page from 'components/pages/page/page-view';
import DetailPanel from 'components/shared/DetailPanel';
import { useAssetLabel } from 'components/shared/assetLabel';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { camelCase } from 'lodash';
import { useSnackbar } from 'notistack';
import { type ReducerState, useCallback, useReducer, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { validateIcaoTypeDesignator } from '../helpers';
import { AircraftFields } from './aircraftFields-view';
import { type GroupId, categories } from './categories';
import { initialState, reducer } from './reducer';

interface ValidState extends ReducerState<typeof reducer> {
  name: string;
  category: string;
}

const validate = (
  state: ReducerState<typeof reducer>,
  groupId: GroupId | undefined,
  invalidMessagingHandle: string | undefined,
  enableIcaoTypeDesignator: boolean,
  validIcaoTypeDesignators: string[] | undefined,
): state is ValidState => {
  if (!state.category || !state.name) return false;
  if (groupId === 'Air' && (!state.make || !state.model)) return false;
  if (
    validateIcaoTypeDesignator(
      enableIcaoTypeDesignator,
      state.icaoTypeDesignator,
      { category: state.category ?? '' },
      validIcaoTypeDesignators,
    )
  )
    return true;
  if ((state.messagingHandle ?? '').trim().toLowerCase() === invalidMessagingHandle) return false;
  return true;
};

export const CreateAssetPage = (): JSX.Element => {
  const t = useTranslations('pages.assets.create');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [groupId, setGroupId] = useState<GroupId>();
  const [invalidMessagingHandle, setInvalidMessagingHandle] = useState<string>();
  const assetLabel = useAssetLabel();

  const group = categories.find(c => c.id === groupId);

  const [state, dispatch] = useReducer(reducer, initialState);

  const assetsQuery = useGetAssetsList().query;

  const watchlistGroups =
    assetsQuery.data
      ?.map(a => a.watchlistGroup)
      .filter((value, index, array): value is string => !!value && array.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b)) ?? [];

  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  const typeDesignatorsQuery = useGetIcaoTypeDesignators({ enabled: enableIcaoTypeFlag });

  const valid = validate(
    state,
    groupId,
    invalidMessagingHandle,
    enableIcaoTypeFlag ?? false,
    typeDesignatorsQuery.data,
  );

  const mutation = useCreateAsset();

  const save = useCallback(
    (onSuccess: (asset: AssetBasic) => void) => {
      if (!valid) return;

      const value = {
        name: state.name.trim(),
        category: state.category.trim(),
        make: state.make?.trim() ?? null,
        model: state.model?.trim() ?? null,
        variant: state.variant?.trim() ?? null,
        icaoTypeDesignator: state.icaoTypeDesignator?.trim() ?? null,
        tailNumber: state.tailNumber?.trim() ?? null,
        messagingHandle: state.messagingHandle?.trim() ?? null,
        watchlistGroup: state.watchlistGroup?.trim() ?? null,
        callSign: state.callSign?.trim() ?? null,
      };

      mutation.mutate(value, {
        onSuccess: asset => {
          enqueueSnackbar(t('snackbar.success', { name: assetLabel(asset) }), {
            key: 'createAsset.success',
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
          onSuccess(asset);
        },
        onError: error => {
          const isMessagingHandleInvalid = error.response.status === 400;
          if (isMessagingHandleInvalid && value.messagingHandle) setInvalidMessagingHandle(value.messagingHandle);

          enqueueSnackbar(t(isMessagingHandleInvalid ? 'snackbar.errorMessagingHandle' : 'snackbar.error'), {
            key: 'createAsset.error',
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          });
        },
      });
    },
    [valid, mutation, state, assetLabel, enqueueSnackbar, t],
  );

  const onSave = useCallback(() => {
    save(asset => navigate(`/manage/assets/${asset.id}`));
  }, [save, navigate]);

  const onSaveAndAddAnother = useCallback(() => {
    save(() => {
      setGroupId(undefined);
      dispatch({ type: 'RESET' });
    });
  }, [save]);

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper>
        <Container>
          <Box>
            <LinkAbove />
            <Typography variant="h1" gutterBottom>
              {t('title')}
            </Typography>
          </Box>

          <DetailPanel mt={4}>
            <Stack spacing={6} px={3}>
              <Stack spacing={3}>
                <Typography variant="h3">{t('prompt.group')}</Typography>

                <Stack direction="row" spacing={3} role="radiogroup" justifyContent="stretch">
                  {categories.map(c => (
                    <Stack
                      key={c.id}
                      // biome-ignore lint/a11y/useSemanticElements:
                      role="radio"
                      aria-checked={c.id === groupId}
                      onClick={() => {
                        if (c.id === groupId) return;
                        setGroupId(c.id);
                        dispatch({ type: 'RESET' });
                        dispatch({ type: 'SET_CATEGORY', payload: c.items ? '' : c.id });
                      }}
                      spacing={1}
                      sx={{
                        cursor: 'pointer',
                        borderRadius: 1,
                        flex: 1,
                        p: 4,
                        pt: 6,
                        color: c.id === groupId ? 'common.white' : undefined,
                        bgcolor: c.id === groupId ? 'primary.main' : 'common.lightGrey',
                        alignItems: 'center',
                        height: 150,
                        '&:hover': {
                          bgcolor: c.id === groupId ? 'primary.main' : 'common.midGrey',
                        },
                      }}
                    >
                      <Typography color="inherit" fontSize="1.5em" textAlign="center">
                        {t('group.name', { group: camelCase(c.id) })}
                      </Typography>
                      <Typography color="inherit" fontSize="0.9em" textAlign="center">
                        {t('group.description', { group: camelCase(c.id) })}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>

              {group?.items && (
                <Stack spacing={3}>
                  <Typography variant="h3">{t('prompt.type', { group: camelCase(group.id) })}</Typography>
                  <TextField
                    select
                    label={t('type')}
                    value={state.category ?? ''}
                    onChange={event => dispatch({ type: 'SET_CATEGORY', payload: event.target.value })}
                    sx={{ width: '50ch' }}
                    required
                    disabled={mutation.isPending}
                  >
                    {group.items.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {t('category', { category: camelCase(c.id) })}
                      </MenuItem>
                    ))}
                  </TextField>

                  {state.category && (
                    <>
                      {group?.id === 'Air' && (
                        <AircraftFields
                          category={group.items?.find(c => c.id === state.category)}
                          state={state}
                          dispatch={dispatch}
                          disabled={mutation.isPending}
                        />
                      )}
                      {group?.id !== 'Air' && group?.id !== 'Person' && (
                        <Stack direction="row" spacing={3}>
                          <TextField
                            label={t('manufacturer')}
                            value={state.make ?? ''}
                            onChange={event => dispatch({ type: 'SET_MAKE', payload: event.target.value })}
                            fullWidth
                            disabled={mutation.isPending}
                          />
                          <TextField
                            label={t('model')}
                            value={state.model ?? ''}
                            onChange={event => dispatch({ type: 'SET_MODEL', payload: event.target.value })}
                            fullWidth
                            disabled={mutation.isPending}
                          />
                          <TextField
                            label={t('variant')}
                            value={state.variant ?? ''}
                            onChange={event => dispatch({ type: 'SET_VARIANT', payload: event.target.value })}
                            fullWidth
                            disabled={mutation.isPending}
                          />
                        </Stack>
                      )}
                    </>
                  )}
                </Stack>
              )}

              {state.category && (
                <Stack spacing={3}>
                  <Typography variant="h3">{t('prompt.identify', { category: camelCase(state.category) })}</Typography>

                  <Stack direction="row" spacing={3}>
                    <TextField
                      label={t('name')}
                      value={state.name ?? ''}
                      onChange={event => dispatch({ type: 'SET_NAME', payload: event.target.value })}
                      error={typeof state.name === 'string' && !state.name.length}
                      required
                      disabled={mutation.isPending}
                      fullWidth
                    />
                    {group?.id === 'Air' && (
                      <TextField
                        label={t('tailNumber')}
                        value={state.tailNumber ?? ''}
                        onChange={event => dispatch({ type: 'SET_TAIL_NUMBER', payload: event.target.value })}
                        disabled={mutation.isPending}
                        fullWidth
                      />
                    )}
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      label={t('callSign')}
                      value={state.callSign ?? ''}
                      onChange={event => dispatch({ type: 'SET_CALL_SIGN', payload: event.target.value })}
                      fullWidth
                      disabled={mutation.isPending}
                    />
                    <TextField
                      label={t('messagingHandle')}
                      value={state.messagingHandle ?? ''}
                      onChange={event => dispatch({ type: 'SET_MESSAGING_HANDLE', payload: event.target.value })}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">@msg.tracplus.com</InputAdornment>,
                      }}
                      fullWidth
                      error={(state.messagingHandle ?? '').trim().toLowerCase() === invalidMessagingHandle}
                      disabled={mutation.isPending}
                    />
                    <Autocomplete
                      freeSolo
                      value={state.watchlistGroup ?? ''}
                      options={watchlistGroups}
                      renderInput={params => <TextField {...params} label={t('watchlistGroup')} />}
                      onInputChange={(event, value) => dispatch({ type: 'SET_WATCHLIST_GROUP', payload: value })}
                      fullWidth
                      disabled={mutation.isPending}
                    />
                  </Stack>
                </Stack>
              )}

              <Stack spacing={3} direction="row" justifyContent="flex-end" height="4em">
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ minWidth: '10rem' }}
                  component={Link}
                  to="/manage/assets"
                  disabled={mutation.isPending}
                >
                  {t('cancel')}
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ minWidth: '10rem' }}
                  disabled={!valid || mutation.isPending}
                  onClick={onSave}
                >
                  {t('save')}
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ minWidth: '10rem' }}
                  disabled={!valid || mutation.isPending}
                  onClick={onSaveAndAddAnother}
                >
                  {t('saveAndAdd')}
                </Button>
              </Stack>
            </Stack>
          </DetailPanel>
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};
