import { useGetAssetBasic } from 'apis/rest/assets/hooks';
import { useGetDeviceBasic } from 'apis/rest/devices/hooks';
import { categories } from 'apis/trackstar/maps';
import LoadingPage from 'components/pages/loading';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import React from 'react';
import { useParams } from 'react-router-dom';
import AssetPage from './asset-page';
import MissingAssetPage from './missingAsset-view';

const AssetQuery = () => {
  const params = useParams();
  const assetId = Number.parseInt(params.id ?? '', 10);
  const getAsset = useGetAssetBasic(assetId).query;
  const hasDevice = getAsset.isSuccess && getAsset.data?.deviceId != null;
  const getDevice = useGetDeviceBasic(getAsset.data?.deviceId ?? 0, hasDevice).query;
  const { serialType } = useUiSettings();

  if (getAsset.isError || getDevice.isError) {
    // TODO: proper error handling here.
    return <MissingAssetPage />;
  }

  if (getAsset.isLoading) return <LoadingPage />;
  if (hasDevice && getDevice.isLoading) return <LoadingPage />;

  if (
    (getAsset.isSuccess && getAsset.data?.deviceId == null) ||
    (getAsset.isSuccess && getAsset.data?.deviceId != null && getDevice.isSuccess)
  ) {
    return (
      <AssetPage
        serialType={serialType}
        asset={getAsset.data}
        device={getDevice.data}
        hasDevice={getAsset.data?.deviceId != null}
        categories={categories}
      />
    );
  }

  return <MissingAssetPage />;
};

export default AssetQuery;
