import useResizeObserver from '@react-hook/resize-observer';
import React, { type RefObject, useLayoutEffect } from 'react';

export const useSize = <T extends HTMLElement>(target: RefObject<T>) => {
  const [size, setSize] = React.useState<DOMRect>(new DOMRect(0, 0, 70, 70));

  useLayoutEffect(() => {
    const newSize = target.current?.getBoundingClientRect();
    if (newSize) {
      setSize(newSize);
    }
  }, [target]);

  useResizeObserver(target, entry => {
    if (entry.contentRect.height !== size?.height || entry.contentRect.width !== size?.width) {
      setSize(entry.contentRect);
    }
  });
  return size;
};
