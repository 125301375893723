import { PathLayer, ScatterplotLayer } from '@deck.gl/layers';
import { MapboxOverlay, type MapboxOverlayProps } from '@deck.gl/mapbox';
import WebMercatorViewport from '@math.gl/web-mercator';
import { Box } from '@mui/material';
import type { GeometryCollection, LineString, Point, Position } from '@turf/helpers';
import useHighlightedReportLayers from 'components/maps/reactmapgl/layers/useHighlightedReportLayers';
import { hexToRGBArray } from 'helpers/color';
import { safeBounds } from 'helpers/geo';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { useSize } from 'hooks/useSize';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactMapGl, { AttributionControl, type MapLayerMouseEvent, type MapRef, useControl } from 'react-map-gl';
import { KdTree } from 'repositories/reports/kdTree';

interface TripMapProps {
  trip: Trip;
  asset: AssetBasic;
  selectedReportId: number | undefined;
  setSelectedReportId: (id: number | undefined) => void;
}

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

interface XYPoint {
  x: number;
  y: number;
}

const distance = <T extends XYPoint>(a: T, b: T): number => (a.x - b.x) ** 2 + (a.y - b.y) ** 2;

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export const TripMap = ({ trip, asset, selectedReportId, setSelectedReportId }: TripMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);

  const { width, height } = useSize(ref);

  const path = useMemo(() => JSON.parse(trip.path ?? '{"geometries": []}') as GeometryCollection, [trip]);

  const coordinates = useMemo(
    () =>
      (path.geometries as (Point | LineString)[])
        .map(geom =>
          Array.isArray(geom.coordinates[0]) ? (geom.coordinates as Position[]) : [geom.coordinates as Position],
        )
        .flatMap(coords => [
          { path: coords },
          { path: coords.map(c => [c[0] + 360, c[1]]) },
          { path: coords.map(c => [c[0] - 360, c[1]]) },
        ]),
    [path.geometries],
  );

  const triplicateReports = useMemo(
    () =>
      trip.reports.flatMap(r => [
        r,
        { ...r, coords: [r.coords[0] - 360, r.coords[1]] },
        { ...r, coords: [r.coords[0] + 360, r.coords[1]] },
      ]),
    [trip],
  );

  const viewport = useMemo(
    () => ({
      ...new WebMercatorViewport({ width, height }).fitBounds(safeBounds(path), {
        padding: Math.min(width, height, 20),
      }),
    }),
    [width, height, path],
  );

  useEffect(() => {
    mapRef.current?.getMap().fitBounds(safeBounds(path), { padding: Math.min(width, height, 20) });
  }, [width, height, path]);

  const kdTree = useMemo(
    () =>
      new KdTree(
        triplicateReports.map(r => ({ ...r, x: r.coords[0], y: r.coords[1] })),
        distance,
        ['x', 'y'],
      ),
    [triplicateReports],
  );

  const findClosestReport = useCallback(
    (event: MapLayerMouseEvent) => {
      if (!event.lngLat) return;
      const report = kdTree.nearest({ x: event.lngLat.lng, y: event.lngLat.lat }, 1).at(0)?.at(0);
      setSelectedReportId(report?.id);
    },
    [kdTree, setSelectedReportId],
  );

  const selectedReport = useMemo(() => trip.reports.find(r => r.id === selectedReportId), [trip, selectedReportId]);

  const layers = useMemo(
    () => [
      new PathLayer({
        id: `path-outline-trip-${trip.id}`,
        data: coordinates,
        getWidth: 4,
        getColor: [255, 255, 255, 255],
        capRounded: true,
        jointRounded: true,
        widthUnits: 'pixels',
      }),
      new PathLayer({
        id: `path-trip-${trip.id}`,
        data: coordinates,
        getWidth: 2,
        getColor: hexToRGBArray(asset.colour ?? '#000'),
        capRounded: true,
        jointRounded: true,
        widthUnits: 'pixels',
      }),
      new ScatterplotLayer({
        id: `scatter-trip-outline-${trip.id}`,
        data: triplicateReports,
        radiusUnits: 'pixels',
        getFillColor: [255, 255, 255, 255],
        getPosition: (d: TripSlimReport) => d.coords,
        getRadius: () => 3.5,
      }),
      new ScatterplotLayer({
        id: `scatter-trip-${trip.id}`,
        data: triplicateReports,
        radiusUnits: 'pixels',
        getFillColor: hexToRGBArray(asset.colour ?? '#000'),
        getPosition: (d: TripSlimReport) => d.coords,
        getRadius: () => 2.5,
      }),
    ],
    [asset, trip, triplicateReports, coordinates],
  );

  const highlightedReportLayers = useHighlightedReportLayers(
    selectedReport && {
      latitude: selectedReport.coords[1],
      longitude: selectedReport.coords[0],
      course: selectedReport.track,
    },
    11,
    asset.colour ?? undefined,
  );

  const allLayers = useMemo(() => [...layers, ...highlightedReportLayers], [layers, highlightedReportLayers]);

  const resetSelectedReport = useCallback(() => setSelectedReportId(undefined), [setSelectedReportId]);
  const mapTemplate = useMapTemplate();

  return (
    <Box
      ref={ref}
      sx={{ height: '100%', minHeight: 50, minWidth: 50, borderLeft: theme => theme.border.default }}
      onMouseLeave={resetSelectedReport}
    >
      <ReactMapGl
        ref={mapRef}
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        initialViewState={viewport}
        onLoad={event => {
          event.target.dragRotate.disable();
          event.target.touchZoomRotate.disableRotation();
        }}
        projection={{ name: 'mercator' }}
        onMouseMove={findClosestReport}
        attributionControl
      >
        <DeckGLOverlay layers={allLayers} />
      </ReactMapGl>
    </Box>
  );
};
