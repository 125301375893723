import { Box, Stack, Typography } from '@mui/material';
import type { UseQueryResult } from '@tanstack/react-query';
import type { EngineUsage } from 'apis/rest/engineUsage/types';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import type { HttpResponseError } from 'helpers/api';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import React, { useMemo } from 'react';
import { useTranslations } from 'use-intl';

interface AssetTripSummaryProps {
  trips: Trip[];
  asset: AssetWithDevice;
  displayEngineUsage: boolean;
  engineUsagesQuery: UseQueryResult<EngineUsage[], HttpResponseError>;
  assetHeight: number;
}

export const AssetTripsSummary = ({
  trips,
  asset,
  displayEngineUsage,
  engineUsagesQuery,
  assetHeight,
}: AssetTripSummaryProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.timeline');

  const { totalDuration, totalDistance } = useMemo(
    () => ({
      totalDuration: trips.reduce((sum, trip) => sum + (trip.duration ?? 0), 0),
      totalDistance: trips.reduce((sum, trip) => sum + (trip.distance ?? 0), 0),
    }),
    [trips],
  );
  const engineUsagesForAsset = useMemo(() => {
    return engineUsagesQuery.data?.filter(engineUsage => engineUsage.assetId === asset.id);
  }, [engineUsagesQuery.data, asset.id]);
  const totalEngineDuration = useMemo(() => {
    return engineUsagesForAsset?.reduce((sum, usage) => sum + (usage.duration ?? 0), 0);
  }, [engineUsagesForAsset]);
  const distance = useDistance();
  const duration = useDuration();

  return (
    <>
      <Box gridColumn="1" className="assetLabel" display="flex" alignItems="center">
        <Box py={3} minHeight={assetHeight} display="flex" flexDirection="column" justifyContent="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <AssetColourMarker assetId={asset.id} />
            <Typography variant="h3">
              <AssetLabel asset={asset} />
            </Typography>
          </Stack>
          <Typography variant="h6" whiteSpace="nowrap">
            {asset.make} {asset.model}
          </Typography>
        </Box>
      </Box>
      <Box gridColumn="2" display="flex" alignItems="center" pr={2}>
        <Stack spacing={3} py={3}>
          <Typography>
            {trips.length ? (
              <>
                {t('nTrips', { n: trips.length })} | {duration.fromMillis(totalDuration)} |{' '}
                {distance.create(totalDistance * 1000).format()}
              </>
            ) : (
              t('noTrips')
            )}
          </Typography>
          <Typography>
            {displayEngineUsage &&
              (engineUsagesForAsset ? (
                engineUsagesForAsset.length ? (
                  <>
                    {t('nEngineCycles', { n: engineUsagesForAsset.length })} |{' '}
                    {duration.fromMillis(totalEngineDuration)}
                  </>
                ) : (
                  t('noEngineCycles')
                )
              ) : (
                t('loadingEngineCycles')
              ))}
          </Typography>
        </Stack>
      </Box>
    </>
  );
};
