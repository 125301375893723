import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetIcaoTypeDesignators } from 'apis/aircraftTypes/hooks';
import type React from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslations } from 'use-intl';
import { validateIcaoTypeDesignator } from '../helpers';

interface IcaoTypeDesignatorInputProps {
  asset: Pick<AssetBasic, 'category'>;
  value: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  required?: boolean;
}

export const IcaoTypeDesignatorInput: React.FC<IcaoTypeDesignatorInputProps> = ({
  asset,
  value,
  onChange,
  disabled,
  required,
}) => {
  const t = useTranslations('pages.assets.common.icaoTypeDesignatorInput');
  const typeDesignatorsQuery = useGetIcaoTypeDesignators({ enabled: !disabled });

  const normaliseTypeDesignator = useCallback(
    (input: string | null) => {
      if (input === null) return null;
      if (input.toUpperCase() === t('other').toUpperCase()) return 'other';
      return input.toUpperCase();
    },
    [t],
  );

  const onValueChange = useCallback(
    (value: string | null) => onChange(normaliseTypeDesignator(value)),
    [normaliseTypeDesignator, onChange],
  );

  const errorReason = useMemo(() => {
    if (disabled) return undefined;
    if (!value) {
      return t('errors.missing', { category: asset.category });
    }
    if (!validateIcaoTypeDesignator(!disabled, value, { category: asset.category }, typeDesignatorsQuery.data)) {
      return t('errors.invalid');
    }
    return undefined;
  }, [disabled, value, asset.category, t, typeDesignatorsQuery.data]);

  return (
    <Autocomplete
      options={['other'].concat(typeDesignatorsQuery.data ?? [])}
      getOptionLabel={option => (option === 'other' ? t('other') : option)}
      value={value}
      onChange={(_, val) => onValueChange(val)}
      onInputChange={(_, val) => onValueChange(val)}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={t('label')}
          fullWidth
          error={!disabled && !!errorReason}
          helperText={errorReason ?? ''}
          required={required}
        />
      )}
      disabled={disabled}
      sx={{ flex: 1 }}
    />
  );
};
