import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

interface ScoreProps {
  label: ReactNode;
  value: ReactNode;
  loading?: boolean;
}

const Score = ({ label, value, loading = false }: ScoreProps) => (
  <Box>
    <Typography variant="subtitle2" color="common.text">
      {label}
    </Typography>
    <Typography fontSize="1.5rem" lineHeight="1.3" fontWeight="bold">
      {loading ? 'Loading...' : value}
    </Typography>
  </Box>
);

export default Score;
