import type { UseQueryResult } from '@tanstack/react-query';
import type { EngineUsage } from 'apis/rest/engineUsage/types';
import {
  type D3ZoomEvent,
  type ScaleTime,
  type ZoomBehavior,
  ZoomTransform,
  axisBottom,
  scaleTime,
  select,
  sum,
  zoom,
  zoomTransform,
} from 'd3';
import type { HttpResponseError } from 'helpers/api';
import { DateTime } from 'luxon';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import type { RootElement } from '../tripDetails/charts/common';

const MARGIN_BOTTOM = 30;

type EngineUsageQuery = UseQueryResult<EngineUsage[], HttpResponseError>;

interface TripDatum {
  trip: Trip;
  highlighted: boolean;
  assetIndex: number;
}
interface EngineDatum {
  engineUsage: EngineUsage;
  assetIndex: number;
}

class TimelineGraph {
  private tripData: TripDatum[] = [];
  private scaleX = scaleTime();
  private zoomedScaleX?: ScaleTime<number, number>;
  private highlightedTripId: string | undefined;
  private root: RootElement;
  public onTripClick: (id: string | undefined) => void;
  private selectedTrip: string | undefined;
  private onHoverTrip: (trip: Trip | undefined, element: SVGElement | undefined) => void;
  private onHoverEngineUsage: (engineUsage: EngineUsage | undefined, element: SVGElement | undefined) => void;
  private zoomBehavior: ZoomBehavior<SVGSVGElement, unknown>;

  private generateData(trips: Trip[], assets: AssetWithDevice[]) {
    return trips
      .map(trip => ({
        trip,
        highlighted: trip.id === this.highlightedTripId,
        assetIndex: assets.findIndex(a => a.id === trip.assetId),
      }))
      .filter(trip => trip.assetIndex >= 0);
  }

  // eslint-disable-next-line class-methods-use-this
  private generateEngineData(engineUsages: EngineUsageQuery, assets: AssetWithDevice[]): EngineDatum[] {
    const engineData =
      engineUsages.data?.filter(engineUsage => assets.map(asset => asset.id).includes(engineUsage.assetId)) ?? [];
    return engineData.flatMap(engineUsage => ({
      engineUsage,
      assetIndex: assets.findIndex(a => a.id === engineUsage.assetId),
    }));
  }

  constructor(
    svg: SVGSVGElement,
    onHoverTrip: (trip: Trip | undefined, element: SVGElement | undefined) => void,
    onHoverEngineUsage: (engineUsage: EngineUsage | undefined, element: SVGElement | undefined) => void,
    onTripClick: (tripId: string | undefined) => void,
  ) {
    this.root = select(svg);
    this.onHoverTrip = onHoverTrip;
    this.onHoverEngineUsage = onHoverEngineUsage;
    this.onTripClick = onTripClick;

    this.zoomBehavior = zoom<SVGSVGElement, unknown>().scaleExtent([1, Number.POSITIVE_INFINITY]);
    this.root.call(this.zoomBehavior);
  }

  public draw(
    width: number,
    totalHeight: number,
    timezone: string,
    trips: Trip[],
    assets: AssetWithDevice[],
    minMaxTimes: [number, number],
    assetHeights: number[],
    displayEngineUsage: boolean,
    engineUsageQuery: EngineUsageQuery,
  ) {
    const height = totalHeight - MARGIN_BOTTOM;

    const tripData = this.generateData(trips, assets);
    this.tripData = tripData;
    const engineData = displayEngineUsage ? this.generateEngineData(engineUsageQuery, assets) : [];

    this.scaleX.domain(minMaxTimes.map(t => new Date(t))).range([0, width]);

    this.root.selectChildren().remove();

    this.root.attr('height', totalHeight).attr('width', width).attr('viewBox', [0, 0, width, totalHeight]);

    const cumulativeHeights = assetHeights.map((v, i, a) => sum(a.slice(0, i))).map(v => Math.floor(v) + 0.5);

    const grids = this.root.append('g').attr('class', 'grids');
    this.root.append('g').attr('class', 'trips');
    this.root.append('g').attr('class', 'engineUsages');

    grids
      .append('g')
      .attr('class', 'grids-asset')
      .selectAll<SVGLineElement, number>('line')
      .data(cumulativeHeights.slice(1))
      .join('line')
      .attr('transform', e => `translate(0, ${e})`)
      .attr('x2', width)
      .attr('stroke', 'currentColor')
      .attr('stroke-opacity', '0.1');

    this.zoomBehavior.on('zoom', (event: D3ZoomEvent<SVGSVGElement, unknown>) => {
      this.zoomedScaleX = event.transform.rescaleX(this.scaleX);
      this.updateAxes(this.zoomedScaleX, height, timezone);
      this.refresh(
        this.zoomedScaleX,
        tripData,
        engineData,
        assets,
        assetHeights,
        cumulativeHeights,
        displayEngineUsage,
      );
    });

    this.updateAxes(this.zoomedScaleX ?? this.scaleX, height, timezone);
    this.refresh(
      this.zoomedScaleX ?? this.scaleX,
      tripData,
      engineData,
      assets,
      assetHeights,
      cumulativeHeights,
      displayEngineUsage,
    );
  }

  public reset() {
    this.onHoverTrip(undefined, undefined);
    this.onHoverEngineUsage(undefined, undefined);
  }

  private updateAxes(scaleX: ScaleTime<number, number>, height: number, timezone: string) {
    this.root.select('.grids').selectAll('.x-axis').remove();
    this.root.select('.grids').selectAll('.x-ticks').remove();

    const formatXAxisTick = (date: Date): string => {
      const dt = DateTime.fromJSDate(date).setZone(timezone);
      if (dt.get('second') !== 0) return '';
      if (dt.get('hour') !== 0) return dt.toFormat('HH:mm');
      return dt.toFormat('MMM d');
    };

    this.root
      .select('.grids')
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .call(axisBottom<Date>(scaleX).tickFormat(formatXAxisTick));

    this.root
      .select('.grids')
      .append('g')
      .attr('class', 'x-ticks')
      .call(axisBottom<Date>(scaleX).tickSize(height))
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('text').remove())
      .attr('stroke-opacity', 0.1);
  }

  private refresh(
    scaleX: ScaleTime<number, number>,
    tripData: TripDatum[],
    engineData: EngineDatum[],
    assets: AssetWithDevice[],
    assetHeights: number[],
    cumulativeHeights: number[],
    displayEngineUsage: boolean,
  ) {
    const radius = 2;
    const minWidth = 2;
    const height = displayEngineUsage ? 36 : 60;
    const tripsOffset = displayEngineUsage ? height + 3 : height / 2;
    const engineOffset = 3;

    this.root
      .select('.trips')
      .selectAll<SVGRectElement, TripDatum>('.trip')
      .data(tripData, d => d.trip.id)
      .join('rect')
      .classed('trip', true)
      .attr('fill', d => assets[d.assetIndex].colour ?? '#fff')
      .attr('opacity', d => {
        if (this.selectedTrip) {
          if (d.trip.id === this.selectedTrip) return 1;
          return 0.5;
        }
        return 1;
      })
      .attr('x', d => scaleX(d.trip.startTime))
      .attr('y', d => cumulativeHeights[d.assetIndex] + assetHeights[d.assetIndex] / 2 - tripsOffset)
      .attr('height', height)
      .attr('rx', radius)
      .attr('id', d => `trip-${d.trip.id}`)
      .attr('width', d =>
        Math.max(
          minWidth,
          d.trip.endTime
            ? scaleX(new Date(d.trip.endTime)) - scaleX(new Date(d.trip.startTime))
            : scaleX(new Date()) - scaleX(new Date(d.trip.startTime)),
        ),
      )
      .style('cursor', d => (d.trip.endTime ? 'pointer' : ''))
      .on('mouseenter', (event, datum) => {
        if (!datum.trip.endTime) {
          return;
        }
        this.highlightedTripId = datum.trip.id;
        this.onHoverTrip(datum.trip, event.target);
        this.root
          .select('.trips')
          .selectAll<SVGRectElement, TripDatum>('.trip')
          .transition()
          .attr('opacity', d => {
            if (d === datum) return 1;
            if (d.trip.assetId !== datum.trip.assetId) return 1;
            return 0.6;
          });
      })
      .on('mouseleave', (event, datum) => {
        this.highlightedTripId = undefined;
        this.onHoverTrip(undefined, undefined);
        this.root
          .select('.trips')
          .selectAll<SVGRectElement, TripDatum>('.trip')
          .transition()
          .attr('opacity', d => {
            if (d.trip.assetId !== datum.trip.assetId) return 1;
            if (this.selectedTrip && d.trip.id !== this.selectedTrip) return 0.6;
            return 1;
          });
      })
      .on('click', (_, d) => {
        if (!d.trip.endTime) return;
        if (this.onTripClick && this.root.node()) this.onTripClick(d.trip.id);
      });

    this.root
      .select('.engineUsages')
      .selectAll<SVGRectElement, EngineDatum>('.engineUsage')
      .data(engineData)
      .join('rect')
      .classed('engineUsage', true)
      .attr('fill', '#BED0E2')
      .attr('x', d => scaleX(d.engineUsage.startTime))
      .attr('y', d => cumulativeHeights[d.assetIndex] + assetHeights[d.assetIndex] / 2 + engineOffset)
      .attr('height', height)
      .attr('rx', radius)
      .attr('width', d =>
        Math.max(
          minWidth,
          d.engineUsage.endTime
            ? scaleX(new Date(d.engineUsage.endTime)) - scaleX(new Date(d.engineUsage.startTime))
            : scaleX(new Date()) - scaleX(new Date(d.engineUsage.startTime)),
        ),
      )
      .on('mouseenter', (event, d) => {
        this.onHoverEngineUsage(d.engineUsage, event.target);
        select(event.target).transition().attr('fill', '#6C8093');
      })
      .on('mouseleave', e => {
        this.onHoverEngineUsage(undefined, undefined);
        const selection = select(e.target);
        selection.transition().attr('fill', '#BED0E2');
      });
  }

  public setSelectedTrip(id: string | undefined) {
    this.root.selectAll('rect').classed('selectedTrip', false).attr('opacity', 0.6);
    this.root.select(`#trip-${id}`).classed('selectedTrip', true).attr('opacity', 1);
    this.selectedTrip = id;

    const datum = this.tripData.find(d => d.trip.id === id);
    if (!datum || !this.zoomBehavior) return;

    const endTime = datum.trip.endTime ?? datum.trip.startTime;
    const center = datum.trip.startTime + (endTime - datum.trip.startTime) / 2;
    this.root.transition().call(this.zoomBehavior.translateTo, this.scaleX(center), 0);
  }
}

interface AssetTimelineGraphProps {
  size: { width: number; height: number };
  trips: Trip[];
  assets: AssetWithDevice[];
  maxMinTimes: [number, number];
  assetHeights: number[];
  selectedId: string | undefined;
  timezone: string;
  displayEngineUsage: boolean;
  engineUsageQuery: EngineUsageQuery;
  onHoverTrip: (trip: Trip | undefined, element: SVGElement | undefined) => void;
  onHoverEngineUsage: (engineUsage: EngineUsage | undefined, element: SVGElement | undefined) => void;
  onTripClick: (id: string | undefined) => void;
}

const AssetTimelineGraph = ({
  size,
  assetHeights,
  trips,
  assets,
  maxMinTimes,
  selectedId,
  timezone,
  displayEngineUsage,
  engineUsageQuery,
  onHoverTrip,
  onHoverEngineUsage,
  onTripClick,
}: AssetTimelineGraphProps): JSX.Element => {
  const d3Container = useRef<SVGSVGElement>(null);

  const chart = useRef<TimelineGraph>();

  useEffect(() => {
    if (!d3Container.current) return;
    chart.current = new TimelineGraph(d3Container.current, onHoverTrip, onHoverEngineUsage, onTripClick);
  }, [onHoverTrip, onHoverEngineUsage, onTripClick]);

  useEffect(() => {
    if (!chart.current) {
      chart.current = undefined;
      return undefined;
    }

    chart.current.draw(
      size.width,
      size.height,
      timezone,
      trips,
      assets,
      maxMinTimes,
      assetHeights,
      displayEngineUsage,
      engineUsageQuery,
    );

    return () => {
      chart.current?.reset();
    };
  }, [size, timezone, trips, assets, maxMinTimes, assetHeights, displayEngineUsage, engineUsageQuery]);

  useEffect(() => {
    if (chart.current) {
      // this is gross, but waits for sizing to complete before selecting trip to ensure zoom behaviour works
      // when the component is initialised with a selected trip
      setTimeout(() => chart.current?.setSelectedTrip(selectedId));
    }
  }, [selectedId]);

  return (
    <svg
      ref={d3Container}
      style={{ position: 'relative', height: `${sum(Object.values(assetHeights)) + MARGIN_BOTTOM}px`, width: '100%' }}
    />
  );
};

export default AssetTimelineGraph;
