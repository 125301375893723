import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import { uniq } from 'lodash/fp';

const queryKey = ['aircraftTypes'];

export interface AircraftType {
  type: 'LandPlane' | 'Amphibian' | 'Helicopter' | 'Gyrocopter' | 'Drone';
  designator: string;
  manufacturer: string;
  model: string | number;
}

const queryFn = async () => (await import('./data.json')).default as AircraftType[];

type Options<TData, TSelect> = Omit<UseQueryOptions<TData, HttpResponseError, TSelect>, 'queryKey' | 'queryFn'>;

export const useGetAircraftTypes = <T = AircraftType[]>(options?: Options<AircraftType[], T>) => {
  const query = useQuery({
    queryKey,
    queryFn,
    ...options,
  });

  return { query, queryKey };
};

const selectIcaoTypeDesignators = (types: AircraftType[]) => uniq(types.map(t => t.designator)).toSorted();

export const useGetIcaoTypeDesignators = (options?: Omit<Options<AircraftType[], string[]>, 'select'>) => {
  return useGetAircraftTypes({
    ...options,
    select: selectIcaoTypeDesignators,
  }).query;
};
