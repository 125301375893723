import { enUS } from '@mui/x-date-pickers/locales';

const messages = {
  datePicker: enUS.components.MuiLocalizationProvider.defaultProps.localeText,
  app: {
    compatability: {
      title: 'Minimum Requirements',
      continueAnyway: 'If you choose to continue using TracPlus Cloud with this unsupported device, please be aware that the application may not function as expected.',
      continueAnywayButtonText: 'Continue anyway',
      downloadTheAppFromAppleAppStore: 'Download our app from the Apple App Store.',
      downloadTheAppFromGooglePlay: 'Download our app from Google Play Store.',
      unsupportedBrowser: 'Your browser is not supported.  Please use one of: Chrome, Firefox, Safari, Edge or Opera.',
      outdatedBrowser: 'Your browser is out of date.  Please update to the latest version before proceeding.',
      onMobile: 'You are using a mobile device.  We recommend that you download our mobile client instead.',
      onIos: 'You are using an iOS device.  Find our mobile app in the Apple App Store instead.',
      onAndroid: 'You are using an Android device.  Find our mobile app in the Google Play Store instead.',
      noWebGl: 'Your browser does not support WebGL.  Please ensure that you are using a device with 3D acceleration capability and a modern browser.',
      lowRes: 'Your screen is too narrow to properly display this application. Please ensure that you are using a device with a minimum screen width of 1200 pixels.'
    }
  },
  header: {
    modules: {
      alert: {
        multipleAssets: 'Multiple Assets',
        isInDistress: 'is in Distress',
        areInDistress: 'are in Distress',
        isOverdue: 'is Overdue',
        isOfConcern: 'is Of Concern',
        isOK: 'is OK',
        isFinished: 'active monitoring ended at',
        areOverdue: 'are Overdue',
        ofConcern: 'of Concern',
        areOfConcern: 'are of Concern',
        close: 'Close'
      },
      links: {
        knowledgeBase: 'Knowledge Base',
        troubleshooting: 'Troubleshooting'
      },
      userPocket: {
        accountSettingsButtonText: 'Account Settings',
        orgSettingsButtonText: 'Organisation Settings',
        logoutButtonText: 'Logout'
      },
      feedback: {
        feedback: 'Feedback',
        captionOne: 'Please feel free to contact our development team using the form below.  Your feedback is important and will help guide development.',
        submitButton: 'Submit',
        confirmText: 'Feedback Sent - Thank you!'
      },
      organisationSelector: {
        selectOrganisation: 'Select organisation',
      },
    }
  },
  navigation: {
    menu: 'Menu',
    map: 'Map',
    messaging: 'Messaging',
    sharing: 'Sharing',
    friends: 'Friends',
    reporting: 'Reporting',
    settings: 'Settings',
    comingSoon: 'Coming Soon',
    manage: 'Manage',
    staffPage: 'Staff',
    compatibility: 'Minimum Requirements - TracPlus Cloud'
  },
  pageTitles: {
    brand: 'TracPlus Cloud',
    map: 'Map',
    search: 'Map',
    messaging: 'Messaging',
    login: 'Log In',
    'reset-password': 'Reset Password',
    signup: 'Sign Up',
    'legal-terms': 'Terms and Conditions',
    'set-new-password': 'Set New Password',
    compatibility: 'Minimum Requirements',
    error: 'Error',
    friends: 'Friends and Groups',
    reporting: 'Reporting',
    sharing: 'Sharing',
    settings: 'Settings',
    'settings-general': 'General Settings',
    'settings-organisation': 'Organisation Settings',
    'settings-account': 'Account Settings',
    'assets-and-devices': 'Assets and Devices',
    assets: 'Assets',
    devices: 'Devices',
    'settings-people': 'People',
    'asset-new': 'Create Asset',
    'edit-asset': 'Configure Asset',
    'device-new': 'Create Device',
    'edit-device': 'Configure Device',
    staff: 'Staff Tools',
    'staff-organisations': 'Organisations',
    'staff-billing-iridium-usage': 'Iridium Usage',
    'staff-asset-search': 'Asset Search',
    'staff-distress-incidents': 'Distress Incidents',
    'missing-organisation': 'Missing Organisation',
    'staff-billing-plansAddons': 'Plans and Addons',
    'staff-billing-plans-new': 'Create Plan',
    'staff-billing-addons-new': 'Create Addon',
    'edit-plan': 'Edit Plan',
    'edit-addon': 'Edit Addon'
  },
  components: {
    LinkAbove: {
      returnTo: 'Return to {label}',
      reporting: 'Reporting',
      dailyFlightSummaries: 'Daily Flight Summaries',
      firefighting: 'Aerial Firefighting Summary',
      geofenceEvents: 'Geofence Events',
      manage: 'Manage',
      manageAssets: 'Assets',
      manageDevices: 'Devices',
      managePeople: 'Contact people',
      manageIceContactGroups: 'ICE contact groups',
      manageMessagingWhitelistContactGroups: 'Device messaging contact groups',
      manageAssetGroups: 'Asset groups',
      settings: 'Settings',
      staff: 'Support tools',
      trackingDataReport: 'Tracking Data Reports',
      peopleGroups: 'Contact Groups',
      eventNotifications: 'Event Notification Groups',
      marketSectors: 'Market Sectors',
      organisations: 'Organisations',
      geofences: 'Geofences',
      userActivity: 'Users Activity',
    },
    SelectSerialType: {
      label: 'Device serial type',
      serialType: {
        tpSerial: 'TracPlus serial',
        imei: 'IMEI',
        manufacturerSerial: 'Manufacturer serial',
      },
    },
    Nag: {
      missingDefaultIceContactGroup: 'This organisation does not have a default ICE contact group',
      invalidDefaultIceContactGroup: 'The default ICE contact group is missing details',
      createIceContactGroup: 'Please assign or create one now',
      editDefaultIceContactGroup: 'Please add them now',
      dismiss: 'Dismiss',
      usercodeWelcome: 'You have logged in with an organisation usercode. This gives you view-only access. <br></br>If you need edit access, please contact this <link>organisation\'s administrator</link> to have a personal account created for you.',
    },
    TableColumnsPicker: {
      columns: 'Columns',
      columnCount: '{n, plural, =0 {No columns} one {# column} other {# columns}}',
    },
    TrailColouring: {
      altitude: 'Altitude',
      transport: 'Transport',
      speed: 'Speed',
      latency: 'Latency',
      battery: 'Battery',
      none: 'Asset Colour (default)'
    },
    Snackbar: {
      dismiss: 'Dismiss',
    },
    OpenStreetMapCredit: {
      osmAttribution: 'Location names from {osmLink}',
    },
    MultiSelect: {
      noUnselected: 'No unselected items',
      noFiltered: 'No filtered items',
    },
    Download: {
      label: 'Download',
    },
    LiveUpdateDummy: {
      receivedMessage: 'Received message from {asset}: {message}',
      receivedMessageNoAsset: 'Received message: {message}',
      landingMessage: 'Received landing event from {asset}',
      takeoffMessage: 'Received takeoff event from {asset}',
      unnamedAsset: 'Unnamed asset',
    },
    TimeSince: {
      aFewSeconds: 'a few seconds ago',
      relativeTime: `{unit, select,
        seconds {{n, plural, one {a second} other {# seconds}}}
        minutes {{n, plural, one {a minute} other {# minutes}}}
        hours {{n, plural, one {an hour} other {# hours}}}
        days {{n, plural, one {a day} other {# days}}}
        months {{n, plural, one {a month} other {# months}}}
        years {{n, plural, one {a year} other {# years}}}
        other {ERROR}
      } ago`,
    },
  },
  pages: {
    login: {
      pageTitle: 'Log In',
      title: 'Sign In to TracPlus',
      titleGoogle: 'Sign in with Google',
      titleMicrosoft: 'Sign in with Microsoft',
      paragraph: 'Please enter your account details to proceed.',
      registerSentence: 'New to TracPlus?',
      submitButton: 'Log In',
      emailPlaceholder: 'Email or Usercode',
      passwordPlaceholder: 'Password',
      forgottenPassword: 'Forgot password?',
      rememberMe: 'Keep me signed in',
      loginFailed: 'Invalid username or password',
      contactSupport: 'Your account is not a member of any organisation. Please contact TracPlus support.',
      serverError: 'Server error',
      tooManyAttempts: 'Too many failed login attempts. Try again in a few minutes or reset your password.',
      differentProviderError: 'Your email is already used for an email/password account. Please log in with your email and password.',
      signupFailed: 'Account creation failed'
    },
    signup: {
      title: 'Sign up for TracPlus',
      paragraph: 'Create your account to explore all the tools you need to track and manage your team and assets.',
      namePlaceholder: 'Full Name',
      emailPlaceholder: 'Email',
      passwordPlaceholder: 'Password',
      confirmPasswordPlaceholder: 'Confirm your Password',
      submitButton: 'Sign Up',
      disclaimerText: "By clicking the 'Sign Up' button, you are creating a TracPlus account, and you agree to the TracPlus",
      disclaimerLink: 'Terms of Use',
      cancelButton: 'Cancel',
      passwordStrength: 'Password strength',
      veryWeak: 'Very Weak',
      weak: 'Weak',
      moderate: 'Moderate',
      strong: 'Strong',
      veryStrong: 'Very Strong',
      passwordRequired: 'Password is required.',
      passwordTooShort: 'Your password is too short.',
      confirmPasswordRequired: 'Confirm password is required.',
      passwordsDontMatch: "Passwords don't match.",
      emailRequired: 'Email is required.',
      invalidEmail: 'Invalid email address.',
      nameRequired: 'Full name is required.',
      inviteInvalid: 'Invite Invalid',
      inviteInvalidParagraph: 'The invitation has either been cancelled or has already been used. Ask for a new invite, or contact support if you believe this was in error.',
      inviteInvalidButton: 'Go Back',
      signupSeparator: 'Or sign up with'
    },
    resetPassword: {
      title: 'Reset your Password',
      paragraph: 'To reset your password, enter the email address you use to login to TracPlus. A link will be emailed to this address which will let you reset your password.',
      emailPlaceholder: 'Email',
      submitButton: 'Send Link',
      cancelButton: 'Cancel',
      errors: {
        inv_email: 'Failed to reset password, email address not found.'
      },
      emailRequired: 'Email is required.',
      invalidEmail: 'Invalid email address.',
      resetPasswordLinkSent: 'A reset password link has been sent.'
    },
    setNewPassword: {
      setNewPassword: 'Set a New Password',
      setNewPasswordMessage: 'Please set a new password for your account. Your password must contain at least {minimumPasswordLength} characters.',
      emailAddress: 'Email Address',
      invalidEmail: 'Invalid Email Address.',
      newPassword: 'New Password',
      passwordRequired: 'Password is required.',
      passwordTooShort: 'Your password is too short.',
      confirmPassword: 'Confirm Password',
      confirmPasswordRequired: 'Confirm password is required.',
      passwordsDontMatch: "Passwords don't match.",
      resetPassword: 'Reset Password',
      cancelButton: 'Cancel',
      resettingPassword: 'Resetting your password...',
      returnToLogIn: 'Return to Log In',
      unableToResetPassword: 'Unable to reset your password.',
      resetPasswordError: 'This password reset request has expired, please submit a new reset password request.'
    },
    connections: {
      title: 'Friends and Groups',
      description: 'Connect your organisation to others.',
      publicKeyDesc: 'To become friends with another organisation, send them your public key:',
      copyToClipboard: 'Copy to clipboard',
      copiedPublicKey: 'Public key copied to clipboard: {pubkey}',
      tabs: {
        friends: 'Friends',
        groups: 'Groups'
      },
      friends: {
        title: 'Friends',
        accept: 'Accept',
        reject: 'Reject',
        cancel: 'Cancel',
        delete: 'Remove Friend',
        addButton: 'Add Friend',
        confirmDeleteFriendTitle: 'Removing {friend} from your friends list.',
        confirmDeleteFriendMessage: 'You will no longer be able to create, edit or delete shares with {friend}. Are you sure?',
        addFriendDialogTitle: 'Add a Friend',
        befriendDescription: 'Connect your organisation to another to enable asset sharing and messaging between organisations.',
        noOrgsFoundToFriend: 'No organisations found',
        searchForOrganisation: 'Search for an Organisation',
        friendPublicKeyPrompt: "Your friend's public key",
        friendAdded: 'Friend added successfully.',
        friendRemoved: 'Friend removed successfully.',
        addFriendError: 'Adding friend failed, please ensure public key is correct.',
        removeFriendError: 'Removing friend failed.',
        inviteButton: 'Invite',
        getFriendsFailed: 'Failed to get friends, please reload the page.',
        getSharesFailed: 'Failed to get shares',
        group: 'Group',
        organisation: 'Organisation',
        columns: {
          name: 'Name',
          type: 'Type',
          sharesFrom: 'Shares From',
          sharesTo: 'Shares To',
          actions: 'Actions'
        },
        status: {
          PendingMe: 'Pending your approval',
          PendingThem: 'Pending their approval',
          Active: 'Active'
        }
      },
      groups: {
        title: 'Groups',
        accept: 'Accept',
        reject: 'Reject',
        cancel: 'Cancel',
        delete: 'Remove this group from your friends',
        addButton: 'Befriend Group',
        confirmDeleteGroupTitle: 'Unfriend {group}?',
        confirmDeleteGroupMessage: 'You will no longer be able to share tracking data with {group}. Are you sure?',
        addGroupDialogTitle: 'Befriend Group',
        befriendDescription: "Adding a group to your friends enables you to share tracking data with the group's members.",
        noOrgsFoundToFriend: 'No organisations found',
        searchForOrganisation: 'Search for an Organisation',
        groupPublicKeyPrompt: "The group's public key",
        groupAdded: 'Group added to your friends successfully.',
        groupRemoved: 'Group removed from your friends successfully.',
        addGroupError: 'Adding group to your friends failed.',
        removeGroupError: 'Removing group from your friends failed.',
        inviteButton: 'Invite',
        befriendGroup: 'Add this group to your friends',
        unfriendGroup: 'Remove this group from your friends',
        getGroupsFailed: 'Failed to get groups, please reload the page.',
        columns: {
          name: 'Name',
          publicKey: 'Public Key',
          actions: 'Actions'
        },
        status: {
          PendingMe: 'Pending your approval',
          PendingThem: 'Pending their approval',
          Active: 'Active'
        },
        permission: {
          canViewCurrent: 'View live trails',
          canViewHistory: 'View historical trails',
          canSendConfiguration: 'Configure device',
          canSendTextMessages: 'Send text messages',
          canViewForms: 'View forms',
          canEditCallSign: 'Edit asset callsign',
        },
      },
    },
    reporting: {
      title: 'Reporting',
      beta: 'Beta',
      nDaysRemain: '{n, plural, =0 {No days} one {# day} other {# days}} remaining',
      sections: {
        reports: 'Reports',
        previewReports: 'Preview the latest innovations in reporting',
      },
      approvalState: '{state, select, all {Approved} some {Partially approved} none {Not approved} noTrips {No trips} other {}}',
      trackingDataReports: {
        title: 'Tracking Data Reports',
        description: 'View and download reports of tracked asset activity',
        selectAssetsPlaceholder: 'Select assets',
        noAssetsSelected: 'No assets selected',
        noReportsFound: 'No reports found',
        unnamed: 'Unnamed Device',
        columns: 'Columns',
        tableTitle: 'Tracking Data Reports',
        exportTitle: 'Export Tracking Data Reports',
        exportDescription: 'Download reports of tracked asset activity',
        export: 'Export',
        loading: 'Loading',
        nReports: '{n} reports',
        headings: {
          name: 'Asset Name',
          model: 'Model',
          timestamp: 'Timestamp',
          coordinates: 'Coordinates',
          altitude: 'Altitude',
          track: 'Track',
          speed: 'Speed',
          event: 'Event',
          latency: 'Latency',
          elapsed: 'Elapsed',
          distance: 'Distance',
          gateway: 'Gateway',
          battery: 'Battery',
          DOP: 'DOP',
          metadata: 'Metadata',
        }
      },
      tripReports: {
        title: 'Trip Reports',
        description: 'View and download reports of trips completed by assets',
      },
      utilisation: {
        title: 'Activity Summary',
        description: 'Aggregated statistics for asset activity',
      },
      tripAnalysis: {
        title: 'Trip Analysis',
        description: 'Advanced trip reporting and analysis',
        descriptionLong: 'Advanced data processing for more detailed flight activity including geo-referenced take-offs and landings, altitude and speed analysis, engine cycle tracking, and fleet trip summaries.',
        promptSelectTrip: 'Select a trip from the timeline to view its details',
        trial: {
          blurb: 'This report is available as a trial for beta testing.',
          begin: 'Begin 30 day trial',
          expired: 'Your trial access to this report has ended.',
        },
        infoMessage: {
          dismiss: 'Dismiss',
          content: 'You’re experiencing an early release of ‘Trip Analysis’. This advanced tool is in continuous development, enhancing flight activity insights with each update.<break></break> Your feedback helps us refine and evolve. Please submit feedback via the Support button at the lower right.<break></break> Thank you for being part of this journey!',
        },
        download: {
          button: {
            summary: 'Summary (CSV)',
            trips: 'Trips (CSV)',
            engineCycles: 'Engine Cycles (CSV)',
          },
          filename: {
            summary: 'TracPlus Trip Summary Export',
            trips: 'TracPlus Trips Export',
            engineCycles: 'TracPlus Engine Cycles Export',
            dateRange: '{from} to {until}',
          },
          columns: {
            asset: 'Asset',
            make: 'Make',
            model: 'Model',
            from: 'From',
            to: 'To',
            startEvent: 'Start event',
            startTime: 'Start time',
            startCoordinates: 'Start coordinates',
            endEvent: 'End event',
            endTime: 'End time',
            endCoordinates: 'End coordinates',
            distance: 'Distance ({unit})',
            duration: 'Duration',
            airborneDuration: 'Airborne duration',
            movementDuration: 'Movement duration',
            tripCount: 'Trips',
            totalDistance: 'Total distance ({unit})',
            totalDuration: 'Total duration',
            totalAirborneDuration: 'Total airborne duration',
            totalMovementDuration: 'Total movement duration',
            engineUsageCount: 'Engine cycles',
            totalEngineDuration: 'Total engine duration',
            engineStartTime: 'Engine start time',
            engineEndTime: 'Engine end time',
            engineDuration: 'Engine duration',
          },
        },
        toggles: {
          table: 'Table',
          timeline: 'Timeline',
        },
        assetTable: {
          summary: {
            count: 'Total trips',
            duration: 'Total duration',
            distance: 'Total distance',
            airborneDuration: 'Total airborne duration',
            movementDuration: 'Total movement duration',
            engineCycles: 'Total engine cycles',
            engineDuration: 'Total engine duration',
          },
          from: 'From',
          to: 'To',
          duration: 'Duration',
          distance: 'Distance',
          airborneDuration: 'Airborne Duration',
          movementDuration: 'Movement Duration',
        },
        engineCycles: {
          nCycles: '{n} cycles',
          none: 'None detected',
          tooltip: 'Learn more about engine cycle detection',
          dialog: {
            title: 'Engine cycle detection',
            description: 'Engine cycles are only detected if the tracking device for this asset supports engine events and is installed correctly to record them.',
            continue: 'Continue'
          },
        },
        timeline: {
          nTrips: '{n} {n, plural, one {trip} other {trips}}',
          noTrips: 'No trips detected',
          nEngineCycles: '{n} {n, plural, one {trip} other {trips}}',
          noEngineCycles: 'No engine cycles detected',
          loadingEngineCycles: 'Loading engine cycles...',
        },
        popper: {
          trip: 'Trip',
          duration: 'Duration',
        },
      },
      tripDetail: {
        tabs: {
          drops: '{n, plural, =0 {No drops} one {1 drop} other {# drops}}',
        },
        drops: {
          columns: {
            startTime: 'Start time',
            endTime: 'End time',
            duration: 'Duration',
            type: 'Type',
            volume: 'Volume',
            endVolume: 'End volume',
            suppressant: 'Suppressant',
            split: 'Split',
          },
          values: {
            splitDrop: '{splitDrop, select, true {Yes} other {No}}',
          },
        },
      },
      dailyFlightSummary: {
        title: 'Daily Flight Summary',
        description: 'View, edit, and approve daily flight summaries',
        selectAssets: {
          label: 'Select Assets',
          loading: 'Loading assets...',
          all: 'All assets',
          allDescription: 'This will select all possible assets that can be chosen.',
          noAssetGroupName: 'Unnamed asset group',
        },
        getAssetListFailed: 'Failed to get asset list, please reload the page.',
        infoMessage: {
          dismiss: 'Dismiss',
          content: 'You’re experiencing an early release of ‘Daily Flight Summary’. This advanced tool is in continuous development, enhancing flight activity insights with each update.<break></break> Your feedback helps us refine and evolve. Thank you for being part of this journey!',
        },
        download: {
          button: {
            summary: 'Summary (CSV)',
          },
          filename: {
            summary: 'TracPlus Daily Flight Summary Export',
          },
          columns: {
            asset: 'Asset',
            make: 'Make',
            model: 'Model',
            from: 'From',
            to: 'To',
            takeoffEvent: 'Takeoff event',
            takeoffTime: 'Takeoff time',
            takeoffCoordinates: 'Takeoff coordinates',
            landingEvent: 'Landing event',
            landingTime: 'Landing time',
            landingCoordinates: 'Landing coordinates',
            distance: 'Distance ({unit})',
            flightDuration: 'Flight Duration',
            approvalStatus: 'Approval Status',
          },
        },
      },
      assetFlightSummary: {
        title: 'Flight Summary for {assetName} on {day}',
        description: 'Flights on this day',
      },
      missionReports: {
        title: 'Mission Reports',
        description: 'View and download generated mission reports',
      },
      firefighting: {
        title: 'Aerial Firefighting',
        downloadCSV: 'Download CSV',
        summaryTitle: 'Aerial Firefighting Summary',
        assetTitle: 'Aerial Firefighting Details for Asset',
        description: 'Detailed analysis of drops by asset and suppressant type',
        loading: 'Loading...',
        noAssetsToDisplay: 'No assets to display',
        toggleStatsFilterEnabled: 'Hide short trips from statistics',
        toggleAssetsNoDrops: 'Hide assets with no drops',
        unknownLocation: 'Unknown location',
        nDrops: '{n, plural, =0 {No drops} one {# drop} other {# drops}}',
        nFills: '{n, plural, =0 {No fills} one {# fill} other {# fills}}',
        nSplitDrops: '{n, plural, other {# split drops}}',
        suppressant: {
          Water: 'Water',
          FreshWater: 'Fresh Water',
          SaltWater: 'Salt Water',
          Unknown: 'Unknown',
          Retardant: 'Retardant',
          Foam: 'Foam',
          Gel: 'Gel',
        },
        allSuppressant: 'All {name}',
        toggleSuppressantLabel: 'Display {name}',
        assetDetails: 'Details',
        dropsByAsset: 'Drops by asset',
        chartBy: 'Chart by',
        totalVolume: 'Total volume',
        totalFlightTime: 'Total flight time',
        volume: 'Volume',
        dropCount: 'Drop count',
        stats: {
          averageDropsPerHour: 'Average drops per hour',
          averageVolumePerHour: 'Average volume per hour',
          averageDropsPerFlightHour: 'Average drops per flight hour',
          averageVolumePerFlightHour: 'Average volume per flight hour',
          nDropsPerHour: '{n, number, ::.00} drops/h',
          tooltips: {
            averageDropsPerHour: 'The average number of drops per hour between the first fill and last drop of each trip.',
            averageVolumePerHour: 'The average volume of drops per hour between the first fill and last drop of each trip.',
            averageDropsPerFlightHour: 'The average number of drops per hour of the total flight time.',
            averageVolumePerFlightHour: 'The average volume of drops per hour of the total flight time.',
          },
        },
        selectAssets: {
          label: 'Select Assets',
        },
        columns: {
          time: 'Time and location',
          dropVolume: 'Drop volume',
          suppressant: 'Suppressant',
          isAllowedInStats: 'Allowed in statistics',
        },
        yes: 'Yes',
        no: 'No',
        notInTripDescription: 'This drop could not be associated with a trip - this affects its inclusion in the hourly statistics for this asset',
        detailSummary: {
          fillLocation: 'Fill location',
          fillDate: 'Fill date',
          dropLocation: 'Drop location',
          dropDate: 'Drop date',
          totalFlightTime: 'Total flight time',
          totalDropVolume: 'Total drop volume',
          averageDropRate: 'Average drop rate',
          flightTimeDescription: 'From first fill to end of last drop',
          afterLastDrop: 'after last drop',
          beforeNextFill: 'before next fill',
          containerVolume: 'Container volume',
          duration: 'Duration',
          rate: 'Rate',
          speed: 'Speed',
          distance: 'Distance',
          directDistance: 'Direct distance',
          directDistanceDescription: 'From first fill to first drop',
          directFlightDuration: 'Flight duration',
          type: {
            Drop: 'Drop',
            SplitDrop: 'Split drop',
            Fill: 'Fill',
            Pump: 'Pump',
            AbortedFill: 'Aborted fill',
          },
          suppressant: {
            Water: 'of water',
            FreshWater: 'of fresh water',
            SaltWater: 'of salt water',
            Unknown: 'of unknown',
            Retardant: 'of retardant',
            Foam: 'of foam',
            Gel: 'of gel',
          },
          suppressantNoVolume: {
            Water: 'Water',
            FreshWater: 'Fresh water',
            SaltWater: 'Salt water',
            Unknown: 'Unknown',
            Retardant: 'Retardant',
            Foam: 'Foam',
            Gel: 'Gel',
          },
          statsFilter: {
            allow: 'Allow this drop to be used for statistics',
            disallow: 'Disallow this drop from being used for statistics',
          },
        },
        csv: {
          summary: {
            dropCount: 'Drop count',
            totalVolume: 'Total volume ({unit})',
            totalFlightTimeHrs: 'Total flight hours',
            averageDropsPerHour: 'Average drops per hour',
            averageVolumePerHour: 'Average volume per hour ({unit})',
            averageDropsPerFlightHour: 'Average drops per flight hour',
            averageVolumePerFlightHour: 'Average volume per flight hour ({unit})',
          },
          asset: {
            fillCoords: 'Fill coordinates',
            dropCoords: 'Drop coordinates',
            directDistance: 'Direct distance ({unit})',
            flightDuration: 'Flight duration (s)',
            suppressant: 'Suppressant',
            volume: 'Volume ({unit})',
            duration: 'Duration (s)',
            rate: 'Rate ({unit})',
            speed: 'Speed ({unit})',
            distance: 'Distance ({unit})',
          },
        },
      },
      geofenceEvents: {
        title: 'Geofence Events',
        more: '{n} more...',
        description: 'View and download geofence events',
        dataError: 'An unexpected error prevented data from being loaded',
        noAssetsSelected: 'No assets selected',
        downloadCSV: 'Download CSV',
        download: {
          filename: 'TracPlus Geofence Events Export',
          dateRange: '{from} to {until}',
        },
        columns: {
          asset: 'Asset',
          make: 'Make',
          model: 'Model',
          event: 'Event',
          time: 'Time',
          location: 'Location',
          geofence: 'Geofence',
          distance: 'Distance',
        },
        noGeofencesSelected: 'No geofences selected',
        noDirectionsSelected: 'No crossing types selected',
        noLocationsSelected: 'No boundaries selected',
        noData: 'No geofence events to display',
        loading: 'Loading...',
        selectAssets: {
          label: 'Select assets',
          loading: 'Loading assets...',
          all: 'All assets',
          allDescription: 'This will select all possible assets that can be chosen.',
        },
      },
      summary: {
        applyFilters: 'Apply filters',
        cancel: 'Cancel',
        metrics: '{count, plural, one {Metric} other {Metrics}}',
        nAssets: '{n, plural, one {1 asset} other {# assets}}',
        notAvailable: 'Not available',
        number: '{value, number, ::.}',
        numberWithUnit: '{value, number, ::.} {unitLabel}',
        loading: 'Loading...',
        dataError: 'An unexpected error prevented data from being loaded',
        noData: 'There is no data to display',
        noColumnGrouping: 'No column grouping',
        groupBy: 'Group by',
        dimension: {
          assetId: {
            name: 'Asset',
            filterLabel: 'Assets ({selected, number} selected)',
          },
          assetModel: {
            name: 'Asset model',
            filterLabel: 'Asset models ({selected, number} selected)',
          },
          assetGroupId: {
            name: 'Asset group',
            filterLabel: 'Asset groups ({selected, number} selected)',
          },
          category: {
            name: 'Asset category',
            filterLabel: 'Asset categories ({selected, number} selected)',
          },
          ownerId: {
            name: 'Asset owner',
            filterLabel: 'Asset owners ({selected, number} selected)',
          },
          billable: {
            name: 'Billable',
            columnHeading: '{value, select, true {Billable} false {Non-billable} other {Not set}}',
          },
        },
        metric: {
          tripCount: {
            name: 'Trip count',
            description: 'The number of trips',
          },
          distance: {
            name: 'Trip Distance',
            description: 'The combined distance of trips',
          },
          duration: {
            name: 'Trip Duration',
            description: 'The combined duration of trips',
          },
          activityCount: {
            name: 'Active days',
            description: 'The number of days that trips occurred on',
          },
          airborneTime: {
            name: 'Airborne Duration',
            description: 'The combined duration spent airborne',
          },
          movementTime: {
            name: 'Movement Duration',
            description: 'The combined duration spent moving',
          },
        }
      },
      tooltips: {
        amsl: 'Height above mean sea level',
        agl: 'Height above ground level'
      },
      downloadPDF: 'Download PDF',
      downloadCSV: 'Download CSV',
      download: 'Download',
      start: 'Start',
      end: 'End',
      asset: 'Asset',
      make: 'Make',
      model: 'Model',
      approvalStatus: 'Approval Status',
      duration: 'Duration',
      time: 'Time',
      byTime: 'By Time',
      timeline: 'Timeline',
      elevation: 'Elevation',
      speed: 'Speed',
      track: 'Track',
      transport: 'Transport',
      altitude: 'Altitude',
      distance: 'Distance',
      byDistance: 'By Distance',
      takeoff: 'Takeoff',
      landing: 'Landing',
      flightDuration: 'Flight Duration',
      missionId: 'Mission ID',
      from: 'From',
      to: 'To',
      departure: 'Departure',
      arrival: 'Arrival',
      getAssetListFailed: 'Failed to get asset list, please reload the page.',
      getEventReportsFailed: 'Failed to get trip reports for selected date range, please reload the page.',
      selectAssets: {
        label: 'Select assets',
        loading: 'Loading assets...',
        all: 'All assets',
        allDescription: 'This will select all possible assets that can be chosen.',
      },
      date: 'Date',
      dateRange: '{from} to {until}',
      getReports: 'Get reports',
      displayTimesInUTC: 'Display times in UTC',
      displayEngineUsage: 'Display engine cycles',
      displayGeonames: 'Display known location names',
      reports: 'reports',
      loadingTrips: 'Loading trips...',
      loadingTripReports: 'Loading trip reports...',
      nTripReports: '{count} {count, plural, one {trip report} other {trip reports}}',
      total: 'Total',
      exportFilename: 'TracPlus Trip Reports Export',
      latency: 'Latency',
      startEvent: 'Start event',
      startTime: 'Start time',
      coordinates: 'Coordinates',
      startCoordinates: 'Start coordinates',
      takeoffEvent: 'Takeoff event',
      takeoffTime: 'Takeoff time',
      takeoffCoordinates: 'Takeoff coordinates',
      landingEvent: 'Landing event',
      event: 'Event',
      inferredEvents: 'Inferred Events',
      landingTime: 'Landing time',
      landingCoordinates: 'Landing coordinates',
      endEvent: 'End event',
      endTime: 'End time',
      endCoordinates: 'End coordinates',
      supplementary: 'Supplementary data',
      supplementaryComingSoon: 'Supplementary data will be able to be added to trips or groups of trips. Examples include contract reference, fuel fill details, flight descriptions, pilot and crew details, etc. This will be available within TracPlus Cloud, but also our Apps. This feature is being worked on currently.',
      previous: 'Previous',
      next: 'Next',
      trips: {
        start: 'Trip Start',
        end: 'Trip End',
        failedToLoadData: 'Could not find trip data please try again',
        enteredStart: 'Entered start',
        enteredEnd: 'Entered end',
        detectedStart: 'Detected start',
        detectedEnd: 'Detected end',
        totals: 'Totals',
        distance: 'Distance',
        details: 'Trip details',
        duration: 'Travel Duration',
        nTripReports: '{count} {count, plural, one {trip} other {trips}}',
        loadingTripReports: 'Loading...',
        update: 'Update Trip',
        loadEarlier: 'Load earlier reports',
        loadLater: 'Load later reports',
        getAssetListFailed: 'Failed to get asset list, please reload the page.',
        fixIncorrect: 'Amend trip',
        editDialogText: 'Choose where you expected the trip to start and end - we will use this information to help improve future trip detection. You can have multiple start and end times.',
        clearEdits: 'Clear changes',
        delete: 'Delete trip',
        submit: 'Save changes',
        cancel: 'Cancel',
      },
    },
    manage: {
      title: 'Manage',
      sections: {
        tracking: 'Tracking',
        contacts: 'Contacts',
        groups: 'Groups',
        locations: 'Locations',
        labsDescription: 'Preview the latest innovations from TracPlus',
      },
      assets: {
        title: 'Assets',
        description: 'Manage tracked assets',
      },
      devices: {
        title: 'Devices',
        description: 'Manage tracking devices',
      },
      beacon: {
        title: 'Beacon',
        description: 'Manage Beacon access',
      },
      contactGroups: {
        common: {
          contactGroupEdit: {
            basicDetails: {
              name: 'Group name',
              save: 'Save',
              saving: 'Saving...',
              cancel: 'Cancel',
              validation: {
                nameMissing: 'A group name must be provided',
                nameExists: 'A group already exists with this name',
              },
            },
            remove: {
              remove: 'Remove',
              description: 'Remove this contact group',
            },
          },
          contactGroupsTable: {
            default: 'Default',
            created: 'Just added',
            columns: {
              groupName: 'Group name',
              people: 'People',
              assignedAssets: 'Assigned to assets',
            },
            noPeople: 'No people assigned',
            tooltips: {
              view: 'View this group',
              edit: 'Edit this group',
              delete: 'Delete this group',
            },
            unknownAsset: 'Unknown asset',
          },
          contactGroupsAssetAssignmentTable: {
            columns: {
              selectAll: 'Select all',
              asset: 'Asset',
              device: 'Device',
              contactGroup: 'Contact group',
            },
            noGroupAssigned: 'No group assigned',
            noGroupAssignedWithDefault: 'Using default group ({name})',
            noPeople: 'No people assigned',
            selectedAssets: 'Selected assets',
            noSelectedAssets: 'Select some assets to assign',
            assign: 'Assign contact group',
          },
          contactGroupPeopleTable: {
            expandRow: 'Expand row',
            collapseRow: 'Collapse row',
            columns: {
              nameAndRole: 'Name and role',
              voice: 'Voice',
              sms: 'SMS',
              email: 'Email',
              language: 'Language',
            },
            noContactValues: 'No {type, select, voice {voice phone numbers} sms {SMS phone numbers} email {email addresses} other {}} configured',
            tooltips: {
              edit: "Edit this person's contact details",
              missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
            },
          },
          contactGroupAssetsTable: {
            columns: {
              asset: 'Asset',
              device: 'Device',
            },
            unknownAsset: 'Unknown asset',
          },
        },
      },
      people: {
        title: 'Contact People',
        description: 'Your organisation\'s contact people and their details',
        more: 'more',
        noPeopleAlert: 'Your organisation has no contact people',
        columns: {
          name: 'Name',
          role: 'Role',
          sms: 'SMS',
          voice: 'Voice',
          email: 'Email',
          language: 'Language',
          actions: 'Actions'
        },
        created: 'Just added',
        viewPerson: 'View Person',
        editPerson: 'Edit Person',
        deletePerson: 'Delete Person',
        addPerson: 'Add a contact person',
        snackbar: {
          createdSuccessfully: '{name} created successfully',
        },
      },
      person: {
        editPerson: 'Edit Person',
        phoneNumber: 'Phone number',
        emailAddress: 'Email address',
        basicDetails: {
          title: 'Basic Details',
          name: 'Name',
          role: 'Role (optional)',
          roleHelp: 'This person\'s position, duty, or relation',
          prefLanguage: 'Preferred language',
          primaryVoiceNum: 'Primary voice phone number',
          cancel: 'Cancel',
          save: 'Save',
          saving: 'Saving...',
          removePerson: 'Remove this contact person',
          remove: 'Remove',
          language: {
            en: 'English',
            es: 'Spanish',
            pt: 'Portuguese',
          },
          validation: {
            nameExists: 'A person already exists in your organisation with this name',
            nameMissing: 'A name must be provided',
          },
          snackbar: {
            updatedSuccessfully: '{name} updated successfully',
            errorUpdating: 'An unexpected error prevented this person\'s details from being changed',
            errorUpdating409: 'This person\'s details have been changed since you last loaded them. Please check your changes and try again.',
            deletedSuccessfully: '{name} removed successfully',
          },
        },
        phone: {
          title: '{type, select, phone {Voice Contacts} sms {SMS Contacts} other {}}',
          description: 'Phone numbers that will be used to contact this person by {type, select, phone {voice call} sms {SMS. } other {}}',
          smsDisclaimer: 'Entering your phone number here opts you in to receive SMS messages from TracPlus. Message frequency depends on usage. Costs may be incurred for receiving and sending messages to devices. Text STOP at any time to stop receiving messages. TracPlus\'s <privacyPolicy>privacy policy</privacyPolicy> and <termsAndConditions>terms and conditions</termsAndConditions> apply.',
          contactType: 'Phone number',
          add: 'Add',
          contactRemoval: 'Remove this {type, select, phone {voice} sms {SMS} other {}} phone number',
          exists: 'A contact already exists for this {type, select, phone {voice phone number} sms {SMS phone number} other {}}',
          invalid: 'This phone number is invalid',
          missing: 'A phone number must be provided',
          noContacts: 'No {type, select, phone {voice} sms {SMS} other {}} phone numbers added',
          default: 'Default',
          tooltips: {
            edit: 'Edit this phone number',
            delete: 'Remove this phone number',
          },
          snackbar: {
            addContact: {
              success: '{type, select, phone {Voice} sms {SMS} other {}} phone number {value} added successfully',
              error: 'Error adding {type, select, phone {voice} sms {SMS} other {}} phone number',
            },
          },
        },
        email: {
          title: 'Email Contacts',
          description: 'Email addresses that will be used to contact this person',
          contactType: 'Email address',
          add: 'Add',
          contactRemoval: 'Remove this email address',
          exists: 'A contact already exists for this email address',
          invalid: 'This email address is invalid',
          noContacts: 'No email addresses added',
          default: 'Default',
          tooltips: {
            edit: 'Edit this email address',
            delete: 'Remove this email address',
          },
          snackbar: {
            addContact: {
              success: 'Email address {value} added successfully',
              error: 'Error adding email address',
            },
          },
        },
        groups: {
          title: 'Groups',
          description: 'All groups that include this person',
          loading: 'Loading groups...',
          noGroups: '{person} is not a member of any groups',
          default: 'Default',
          columns: {
            group: 'Group',
            groupType: 'Type',
          },
          types: {
            ice: 'ICE',
            messagingWhitelist: 'Device Messaging',
          },
        },
        missing: {
          title: 'Person not found',
          description: 'The person you were looking for does not exist.',
          link: 'Return to all people',
        },
      },
      ice: {
        title: 'ICE Contact Groups',
        description: 'Groups of people to be notified in case of an emergency',
        tabs: {
          label: 'Tabbed navigation for ICE Contact Groups',
          groups: 'Contact Groups',
          assignAssets: 'Bulk Asset Assignment',
        },
        all: {
          save: 'Save',
          createGroup: 'Create a contact group',
          noGroupsAlert: 'Your organisation should have at least one ICE contact group. Please create one now.',
          defaultUnhealthy: 'The primary and secondary people for the default group are missing a voice or SMS phone number',
        },
        create: {
          title: 'Create new ICE contact group',
          snackbar: {
            success: 'New ICE contact group, {name} created successfully',
          },
        },
        edit: {
          title: 'Edit ICE contact group: {name}',
          basicDetails: {
            title: 'Basic Details',
            snackbar: {
              success: 'Contact group name changed successfully',
              error: 'An unexpected error prevented the group name from being changed',
            }
          },
          default: {
            set: 'Set as default',
            loading: 'Loading...',
            canSet: 'This group can be set as your organisation\'s default ICE contact group.',
            cannotSet: 'This group cannot be set as your organisation\'s default ICE contact group.',
            cannotSetExplanation: 'A primary and secondary contact person must be assigned that each have a voice phone number and an SMS phone number.',
            dialog: {
              title: 'Set default ICE contact group',
              message: 'Are you sure you want to set <strong>{name}</strong> as your organisation\'s default ICE contact group?',
              explanation: 'The default ICE contact group is used for any assets that don\'t have one assigned.',
              unexpectedError: 'An unexpected error prevented the group from being set as default',
              snackbar: {
                success: '{name} successfully set as default ICE contact group',
              },
            },
          },
          remove: {
            isDefaultDescription: 'This is your organisation\'s default ICE contact group.',
            isDefaultBeforeRemoveDescription: 'Before removing this group, you must set another group as the default.',
          },
          people: {
            title: 'People',
            description: 'All people who are assigned to this contact group',
            noPeople: 'No people assigned',
            primary: 'Primary',
            secondary: 'Secondary',
            addButton: 'Add people to this group',
            assignButton: 'Manage people in this group',
            dialog: {
              title: 'Assign people to ICE contact group: {group}',
            },
          },
          assets: {
            title: 'Assets',
            description: 'All assets which are assigned to this contact group',
            noAssets: 'No assets assigned',
            forDefaultGroup: 'Because this is the default ICE contact group, it will also be used by any assets which don\'t have an assigned group.',
            columns: {
              asset: 'Asset',
              device: 'Device',
            },
            assignButton: 'Assign assets to this group',
            dialog: {
              title: 'Assign assets to {group}',
            },
          },
          missing: {
            title: 'ICE contact group not found',
            description: 'The ICE contact group you were looking for does not exist',
            link: 'Return to all ICE contact groups',
          },
        },
      },
      messagingWhitelists: {
        title: 'Device Messaging Contact Groups',
        description: 'Groups of people that are allowed to send messages to devices',
        tabs: {
          label: 'Tabbed navigation for Device Messaging Contact Groups',
          groups: 'Contact Groups',
          assignAssets: 'Bulk Asset Assignment',
        },
        all: {
          save: 'Save',
          createGroup: 'Create a contact group',
          noGroupsAlert: 'Your organisation does not have any device messaging contact groups.',
        },
        create: {
          title: 'Create new device messaging contact group',
          snackbar: {
            success: 'New device messaging contact group, {name} created successfully',
          },
        },
        edit: {
          title: 'Edit contact group: {name}',
          basicDetails: {
            title: 'Basic Details',
            remove: 'Remove',
            removeDescription: 'Remove this contact group.',
            isDefaultDescription: 'This contact group is your organisation\'s default device messaging contact group.',
            isDefaultBeforeRemoveDescription: 'Before removing this group, you must set another group as the default.',
            snackbar: {
              success: 'Contact group name changed successfully',
              error: 'An unexpected error prevented the group name from being changed',
            }
          },
          default: {
            set: 'Set as default',
            loading: 'Loading...',
            canSet: 'This group can be set as your organisation\'s default device messaging contact group.',
            dialog: {
              title: 'Set default device messaging contact group',
              message: 'Are you sure you want to set <strong>{name}</strong> as your organisation\'s default device messaging contact group?',
              explanation: 'The default device messaging contact group is used for any assets that don\'t have one assigned.',
              unexpectedError: 'An unexpected error prevented the group from being set as default',
              snackbar: {
                success: '{name} successfully set as default device messaging contact group',
              },
            },
          },
          remove: {
            isDefaultDescription: 'This is your organisation\'s default device messaging contact group.',
            isDefaultBeforeRemoveDescription: 'Before removing this group, you must set another group as the default.',
            snackbar: {
              success: 'Device messaging contact groups, {name} removed successfully',
            },
          },
          people: {
            title: 'People',
            description: 'All people who are assigned to this contact group',
            noPeople: 'No people assigned',
            primary: 'Primary',
            secondary: 'Secondary',
            expandRow: 'Expand row',
            collapseRow: 'Collapse row',
            columns: {
              nameAndRole: 'Name and role',
              voice: 'Voice',
              sms: 'SMS',
              email: 'Email',
              language: 'Language',
            },
            noContactValues: 'No {type, select, voice {voice phone numbers} sms {SMS phone numbers} email {email addresses} other {}} configured',
            tooltips: {
              edit: 'Edit this person\'s contact details',
              missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
            },
            addButton: 'Add people to this group',
            assignButton: 'Manage people in this group',
            dialog: {
              title: 'Assign people to device messaging contact group: {group}',
            },
          },
          assets: {
            title: 'Assets',
            description: 'All assets which are assigned to this contact group',
            noAssets: 'No assets assigned',
            forDefaultGroup: 'Because this group is the default device messaging contact group, it will also be used by any assets which don\'t have one assigned.',
            columns: {
              asset: 'Asset',
              device: 'Device',
            },
            assignButton: 'Assign assets to this group',
            dialog: {
              title: 'Assign assets to {group}',
            },
          },
          missing: {
            title: 'Device messaging contact group not found',
            description: 'The device messaging contact group you were looking for does not exist',
            link: 'Return to all device messaging contact groups',
          },
        },
        assignAssets: {
          columns: {
            selectAll: 'Select all',
            asset: 'Asset',
            device: 'Device',
            contactGroup: 'Contact group',
          },
          noGroupAssigned: 'No group assigned.',
          selectedAssets: 'Selected assets',
          noSelectedAssets: 'Select some assets to assign',
          assign: 'Assign contact group',
        },
      },
      assetGroups: {
        title: 'Asset Groups',
        description: 'Groups of assets that can be managed as a set',
        snackbar: {
          success: 'Asset group {name} created successfully',
        },
        edit: {
          title: 'Edit asset group: {name}',
          missing: {
            title: 'Asset group not found',
            description: 'The asset group you were looking for does not exist',
            link: 'Return to all asset groups',
          },
          basicDetails: {
            title: 'Basic Details',
            name: 'Asset Group Name',
            cancel: 'Cancel',
            save: 'Save',
            saving: 'Saving...',
            nameExists: 'An asset group with that name already exists',
            nameMissing: 'A name must be provided',
          },
          assets: {
            title: 'Assets',
            description: 'All assets that are assigned to this group',
            unknownAsset: 'Unknown asset',
            unknownDevice: 'Unknown/No device',
            columns: {
              asset: 'Asset',
              device: 'Device',
            }
          },
          assetDialog: {
            unselectedLabel: 'Other Assets',
            selectedLabel: 'Selected Assets',
            save: 'Save',
            saving: 'Saving...',
            cancel: 'Cancel',
          },
          assignButton: 'Add Asset to this group',
          modifyButton: 'Manage Assets in this group',
          noAssets: 'No assets assigned',
          snackbar: {
            rename: 'Asset group renamed successfully to {name}',
            assetsUpdated: 'Assets updated successfully',
          },
          manage: {
            title: 'Assign assets to {group}',
          },
        },
        tableView: {
          columns: {
            name: 'Asset Group Name',
            assets: 'Assets',
          },
          tooltips: {
            edit: 'Edit this asset group',
            delete: 'Delete this asset group',
            view: 'View this asset group',
          },
        },
        createGroup: {
          createButtonText: 'Create an asset group',
          createDialogTitle: 'Add a new asset group',
          textFieldLabel: 'Asset Group Name',
          cancelButtonText: 'Cancel',
          savingButtonText: 'Saving...',
          saveButtonText: 'Save',
          validation: {
            nameMissing: 'A name must be provided',
            nameExists: 'Name already exists for another group',
          }
        },
        deleteGroup: {
          deleteDialogTitle: 'Delete asset group',
          deleteDialogText: 'Are you sure you want to delete asset group {groupName}?',
          deleteButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          deletingButtonText: 'Deleting...',
          deleteSnackText: 'Asset group {groupName} deleted successfully',
        }
      },
      eventNotifications: {
        title: 'Event Notifications',
        description: 'Set up notifications for groups of events to be sent to contact groups.',
        create: {
          text: 'Create a new notification group',
          title: 'Add new event notification group',
          cancelText: 'Cancel',
          saveText: 'Save',
          savingText: 'Saving...',
          success: 'Event notification group {name} created',
          error: 'Failed to create event notification group due to an error',
          validation: 'Name is required'
        },
        delete: {
          dialogTitle: 'Delete event notification group',
          dialogText: 'Are you sure you want to delete the notification group {name}?',
          confirmText: 'Delete',
          cancelText: 'Cancel',
          loadingText: 'Deleting...',
          successText: 'Event notification group {name} has been deleted',
          errorText: 'Event notification group {name} failed to delete due to an error'
        },
        tableView: {
          columns: {
            name: 'Notification Group Name',
            disabled: 'Disabled',
            assetGroup: 'Asset Groups',
            events: 'Notification Events'
          },
          tooltips: {
            edit: 'Edit notification group',
            delete: 'Delete notification group',
            view: 'Show notification group'
          },
          status: {
            noRelevantAssets: 'The event notifications feature is not enabled for any assets in the assigned groups',
            someRelevantAssets: 'The event notifications feature is not enabled for some assets in the assigned groups',
          },
        },
        edit: {
          title: 'Event Notification Group: {name}',
          titleEdit: 'Edit Event Notification Group: {name}',
          warnings: {
            neverNotificationAssets: 'Notification features are not enabled for your organisation. Events will not be monitored.',
            noNotificationAssets: 'No assets in the chosen groups have notification features enabled. Events will not be monitored.',
            someNotificationAssets: 'Some assets in the chosen groups do not have notification features enabled. Only the relevant assets will be monitored for events.',
            neverGeofenceAssets: 'Geofence features are not enabled for your organisation but geofence events exist. The geofence events will not be monitored.',
            noGeofenceAssets: 'No assets in the chosen groups have geofence features enabled. The geofence events will not be monitored.',
            someGeofenceAssets: 'Some assets in the chosen groups do not have geofence features enabled. Only the relevant assets will be monitored for geofence events.',
            noContact: 'User {name} does not have the required contact methods setup to receive notifications',
            more: 'More...',
          },
          detailsSection: {
            name: 'Details',
            groupName: {
              label: 'Notification Group Name',
              validations: {
                nameMissing: 'A name must be provided',
                nameExists: 'Name already exists for another group',
              },
            },
            buttons: {
              cancel: 'Cancel',
              save: 'Save',
              delete: 'Delete'
            },
            deleteLabel: 'Delete this event notification group',
            snackbar: {
              success: 'Event notification group updated',
              updateError: 'Error updating event notification group'
            },
          },
          assetsSection: {
            name: 'Asset Groups',
            description: 'Groups of assets these notification rules will apply to',
            columns: {
              name: 'Asset Group Name',
              assets: 'Assets',
              noAssetsEnabled: 'No assets enabled',
              nAssetsDisabled: '{n, plural, one {# disabled asset} other {# disabled assets}}',
            },
            tooltips: {
              assetsDisabled: 'The notifications feature is not enabled for these assets',
            },
            missingAssets: 'No assets assigned to group',
            editText: 'Change the assets groups to monitor for events',
            editButton: 'Manage Asset Groups'
          },
          assetGroupsDialog: {
            cancelButton: 'Cancel',
            saveButton: 'Save',
            availableHeader: 'Available Asset Groups',
            selectedHeader: 'Selected Asset Groups',
            title: 'Manage Asset Groups for {name}',
            countLabel: '{count} Asset(s)'
          },
          rulesSection: {
            name: 'Notification Events',
            description: 'Details for which events to monitor and who to notify.',
            addText: '',
            addButton: 'Add New Event',
            eventGeofence: 'Geofence',
            columns: {
              name: 'Name',
              eventCode: 'Event',
              contactType: 'Contact Type',
              activeTime: 'Active Time',
              peopleGroups: 'Contact Groups',
              noGeofenceAssets: 'Disabled',
              someGeofenceAssets: 'Some assets',
            },
            rulesDialog: {
              cancelButton: 'Cancel',
              saveButton: 'Save',
              closeButton: 'Close',
              savingButton: 'Saving...',
              availableHeader: 'Available Groups',
              selectedHeader: 'Selected Groups',
              titleCreate: 'Create notification event',
              titleEdit: 'Edit notification event',
              titleView: 'View notification event',
              countLabel: '{count} People',
              sections: {
                schedule: 'Schedule',
                scheduleDescription: 'Only notify if the event occurs within a chosen time range',
                contactGroups: 'Contact Groups',
                contactGroupsDescription: 'Groups of people to be notified when the event occurs'
              },
              information: {
                additionalEvents: 'For subscriptions to additional events, please contact TracPlus support',
                lockedEvent: 'This event was modified by the support team and is locked. Please contact TracPlus support for any changes',
                geofenceBreak: {
                  RestrictedArea: 'Events will be created when an asset enters the geofence',
                  AreaOfOperation: 'Events will be created when an asset leaves the geofence',
                  Generic: 'Events will be created when an asset crosses the geofence border',
                }
              },
              labels: {
                name: 'Name *',
                event: 'Event Code *',
                eventGroup: 'Event *',
                timezone: 'Timezone *',
                startTime: 'Start Time *',
                endTime: 'End Time *',
                geofence: 'Geofence *'
              },
              validationMessages: {
                name: 'A name is requried',
                event: 'An event code is required',
                eventGroup: 'An event is required',
                geofence: 'A geofence needs to be selected',
                contactType: 'At least 1 contact method must be selected',
                daysOfWeek: 'At least 1 day must be selected',
                timezone: 'Timezone is required',
                startTime: 'Start Time is required',
                endTime: 'End Time is required',
                endTimeOrder: 'End time must be after start time',
              },
              options: {
                transport: {
                  email: 'Email',
                  sms: 'SMS',
                  notification: 'Web Notification',
                  mobile: 'Mobile Notification'
                },
                dayOfWeek: {
                  mon: 'Monday',
                  tue: 'Tuesday',
                  wed: 'Wednesday',
                  thu: 'Thursday',
                  fri: 'Friday',
                  sat: 'Saturday',
                  sun: 'Sunday'
                },
                sourceType: {
                  event: 'EVENT CODE (STAFF ONLY)',
                  group: 'EVENT',
                },
                timeRestriction: {
                  anytime: 'ANYTIME',
                  restricted: 'RESTRICTED'
                }
              },
            },
            tooltips: {
              view: 'View the rules for this event',
              edit: 'Edit the rules for this event',
              delete: 'Delete this event from the notification group'
            },
            snackbar: {
              updateError: 'An error occured while updating event',
              createError: 'An error occured while creating event'
            },
            delete: {
              dialogTitle: 'Delete notification event',
              dialogText: 'Are you sure you want to delete the event {name}?',
              confirmText: 'Delete',
              cancelText: 'Cancel',
              loadingText: 'Deleting...',
              successText: 'Notification event {name} has been deleted'
            },
            typeDialog: {
              title: 'Which type of event would you like to configure notifications for?',
              event: {
                title: 'Activity',
                subtext: 'Notify on asset report events such as takeoff and landing'
              },
              geofence: {
                title: 'Geofence',
                subtext: 'Notify when assets enter or leave geographic areas'
              },
              closeLabel: 'Close'
            }
          },
          missing: {
            title: 'Event Notification group not found',
            description: 'The event notification group you were looking for does not exist',
            link: 'Return to event notification groups',
          },
        }
      },
      peopleGroups: {
        groupTypes: {
          notification: 'Notification',
        },
        title: 'Contact Groups',
        description: 'Groups of people in your organisation',
        all: {
          save: 'Save',
          createGroup: 'Create a contact group',
          noGroupsAlert: 'Your organisation does not have any {groupType} contact groups.',
        },
        create: {
          title: 'Create new {groupType} group',
          snackbar: {
            success: 'New {groupType} group, {name} created successfully',
          }
        },
        edit: {
          title: 'Edit contact group: {name}',
          basicDetails: {
            title: 'Basic Details',
            remove: 'Remove',
            removeDescription: 'Remove this contact group.',
            isDefaultDescription: 'This contact group is your organisation\'s default {groupType} group.',
            isDefaultBeforeRemoveDescription: 'Before removing this group, you must set another group as the default.',
            snackbar: {
              success: 'Contact group name changed successfully',
              error: 'An unexpected error prevented the group name from being changed',
            },
          },
          remove: {
            isDefaultDescription: 'This is your organisation\'s default contact group.',
            isDefaultBeforeRemoveDescription: 'Before removing this group, you must set another group as the default.',
            snackbar: {
              success: '{groupType} group, {name} removed successfully',
            },
          },
          people: {
            title: 'People',
            description: 'All people who are assigned to this group',
            noPeople: 'No people assigned',
            primary: 'Primary',
            secondary: 'Secondary',
            expandRow: 'Expand row',
            collapseRow: 'Collapse row',
            columns: {
              nameAndRole: 'Name and role',
              voice: 'Voice',
              sms: 'SMS',
              email: 'Email',
              language: 'Language',
            },
            noContactValues: 'No {type, select, voice {voice phone numbers} sms {SMS phone numbers} email {email addresses} other {}} configured',
            tooltips: {
              edit: 'Edit this person\'s contact details',
              missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
            },
            addButton: 'Add people to this group',
            assignButton: 'Manage people in this group',
            dialog: {
              title: 'Assign people to contact group: {group}',
            },
          },
          missing: {
            title: 'Contact group not found',
            description: 'The contact group you were looking for does not exist',
            link: 'Return to people groups',
          },
          notificationgroups: {
            title: 'Event Notifications',
            description: 'All event notifications referencing this contact group',
            namesHeader: 'Name',
            rulesHeader: 'Rules',
            notAssigned: 'Not assigned to any event notification groups',
          }
        },
      },
      geofencing: {
        title: 'Geofences',
        createTitle: 'Create new geofence',
        editTitle: 'Geofence: {name}',
        description: 'Create and manage geographic areas to receive notification when crossed',
        removeThisGeofence: 'Remove this geofence',
        notificationGroups: 'Notification Groups',
        remove: 'Remove',
        errorsAndWarnings: {
          noData: 'No geofences created, please use the file import or add button on map to create new geofences.',
          noMatchFilter: 'No geofences match the applied filters',
          noGeofencesFound: 'No geofences exist for your organisation',
          noNotificationGroups: 'No notification groups found',
          eventNotificationsNotEnabled: 'Event notifications are not enabled for this organisation',
          missingTitle: 'Geofence not found',
          description: 'The geofence you are looking for does not exist',
          returnButton: 'Return to geofence list',
        },
        import: {
          header: 'Import from file',
          dropFile: 'Drop file',
          dropFilesHere: 'Drop KML or KMZ file here',
          clickToBrowse: 'Click to browse files',
          importText: 'Import',
          replaceText: 'Replace all existing shapes for this geofence',
        },
        dialogs: {
          cancelText: 'Cancel',
          assetSelection: {
            title: 'Geofence assets',
          },
          delete: {
            title: 'Delete Geofence',
            text: 'Are you sure you want to delete this geofence?',
            confirmText: 'Delete',
            confirmTextPending: 'Deleting...',
          },
          discard: {
            title: 'Discard Changes',
            text: 'Are you sure you want to discard these changes?',
            confirmText: 'Discard',
          },
          name: {
            title: 'Set Geofence Name',
            label: 'Name',
            confirmText: 'Save',
            confirmTextPending: 'Saving...',
            error: 'Name is required',
          },
        },
        snackbar: {
          updateSuccess: 'Geofence {name} updated',
          updateError: 'Failed to update geofence {name} due to an error',
          deleteSuccess: 'Geofence {name} deleted',
          deleteError: 'Failed to delete geofence {name} due to an error',
          createSuccess: 'Geofence {name} created',
          createError: 'Failed to create geofence due to an error',
          createWarningNoData: 'No features found to save',
          fileImportSuccess: 'File {name} imported',
          fileImportError: 'Failed to import {name}',
        },
        map: {
          tooltips: {
            new: 'Draw new geofence on map',
            save: 'Save changes',
            discard: 'Discard changes'
          },
          controls: {
            importShapes: 'Import shapes',
            addShape: 'Add shape',
            removeShape: 'Remove shape',
            discardChanges: 'Discard Changes',
          },
          drawingInstructions: 'Click to place corners of shape. Double-click to complete shape.',
          customAttribution: 'Maps provided by {provider}',
        },
        table: {
          header: 'Name',
          subHeader: 'Assets',
          overflow: '...and {count} more',
          warning: 'No assets assigned to geofence',
          buttons: {
            manageAssets: 'Manage Assets',
            rename: 'Rename',
            edit: 'Edit Geofence',
            delete: 'Delete',
          },
          breakType: {
            description: 'Type of geofence determines the trigger to create a notification',
            enter: 'Enter',
            exit: 'Exit',
            both: 'Generic',
          },
        },
        list: {
          createGeofence: 'Create a new geofence',
          createGeofenceFromFile: 'Create geofence from file',
          fitMapToAll: 'Fit map',
          editGeofence: 'Edit geofence',
          viewDetails: 'View details',
          goToGroup: 'Go to group',
          events: 'Events',
          exit: 'exit',
          enter: 'enter',
          both: 'enter, exit',
          invalid: 'invalid',
          category: {
            geofenceCategory: 'Geofence Crossing Type',
            enter: 'On Enter only',
            exit: 'On Exit only',
            both: 'On Enter and Exit',
            anyCategory: 'Any Crossing Type',
          },
        },
        create: {
          addAltitude: 'Add altitude limits',
          removeAltitude: 'Remove altitude limits',
          maxAltitudeType: 'Maximum Altitude Reference',
          minAltitudeType: 'Minimum Altitude Reference',
          allGeofences: 'All geofences',
          MSL: 'Above mean sea level',
          AGL: 'Above ground level',
        },
        fields: {
          name: 'Name',
          description: 'Description',
          cancel: 'Cancel',
          save: 'Save',
          search: 'Search Geofences',
          minAltitude: 'Minimum Altitude',
          maxAltitude: 'Maximum Altitude',
        },
        validationMessages: {
          name: 'A name is required',
          description: 'A description is required',
          altitude: 'Incomplete altitude specification',
          features: 'A geofence shape is required',
        },
      },
      markers: {
        title: 'Markers',
        description: 'Create and manage points of interest',
        instruction: 'Click on the map or enter coordinates',
        dropFile: 'Drop TPP3 Marker file here',
        dropFilesHere: 'Drop TPP3 Marker file here',
        actions: {
          create: 'Create New Marker(s)',
          cancel: 'Cancel',
          save: 'Save',
        },
        fields: {
          latitude: 'Latitude',
          longitude: 'Longitude',
        },
        table: {
          name: 'Name',
          actions: {
            show: 'Show',
            hide: 'Hide',
            focus: 'Focus',
            unfocus: 'Unfocus',
            edit: 'Edit',
            delete: 'Delete',
          },
          validationMessages: {
            outOfRange: 'Value out of range',
          },
        },
        dialogs: {
          create: {
            title: 'Create new marker',
          },
          edit: {
            title: 'Edit: {name}',
          },
        },
      },
      usersHistory: {
        title: 'User Activity',
        description: 'View history of users activity',
        email: 'Email',
        lastLogin: 'Last Login',
        time: 'Time',
        type: 'Type',
        never: 'Never',
        name: 'Name',
        ipAddress: 'IP Address',
        downloadCSV: 'Download CSV',
        staffOnlyWarning: 'This feature is only visible due to your staff role. Please contact support to enable this feature.',
      },
      admin: {
        title: 'Admin',
      }
    },
    assets: {
      title: 'Assets',
      description: 'All tracked assets and people that belong to or are shared with your organisation.',
      editButton: 'Edit',
      deleteButton: 'Delete',
      newButton: 'New Asset',
      showArchived: 'Show archived assets',
      assetsTable: {
        name: 'Name',
        tailNumber: 'Tail Number',
        category: 'Category',
        makeModelVariant: 'Make/Model/Variant',
        installation: 'Assigned Device',
        deviceSerialNumber: 'Device Serial Number',
        tpSerial: 'TracPlus Serial',
        imei: 'IMEI Number',
        manufacturerSerial: 'Manufacturer Serial',
        callSign: 'Callsign',
        messagingHandle: 'Messaging Handle',
        watchlistGroup: 'Watchlist Group',
        owner: 'Owner',
        iceContactGroup: 'ICE Contact Group',
        messagingWhitelist: 'Device Messaging Contact Group',
        noGroupAssigned: 'No group assigned',
        noGroupAssignedWithDefault: 'Default group',
        defaultGroupTooltip: '{name} is the default group',
        actions: 'Actions',
        editAsset: 'Edit Asset',
        viewAsset: 'View Asset',
        deleteAsset: 'Delete Asset',
        search: 'Search',
      },
      okButton: 'ok',
      cancelButton: 'cancel',
      loadAssetsListFailed: 'Failed to get asset list, please reload the page to try again.',
      addNew: 'Add new asset',
      create: {
        title: 'Add new asset',
        type: 'Type',
        manufacturer: 'Manufacturer',
        model: 'Model',
        variant: 'Variant',
        name: 'Name',
        tailNumber: 'Tail number',
        messagingHandle: 'Messaging handle',
        watchlistGroup: 'Watchlist group',
        callSign: 'Callsign',
        cancel: 'Cancel',
        save: 'Save',
        saveAndAdd: 'Save and add another',
        prompt: {
          group: 'Which type of asset would you like to track?',
          type: `Which type of {group, select,
            air {aircraft}
            land {vehicle}
            sea {vessel}
            object {object}
            other {{group}}
          } is this?`,
          identify: `How would you like to identify this {category, select,
            unknown {object}
            other {{category}}
          } within our system?`,
        },
        group: {
          name: `{group, select,
            air {Aircraft}
            land {Land Vehicle}
            sea {Maritime Vessel}
            person {Person}
            object {Other}
            other {{group}}
          }`,
          description: `{group, select,
            air {Plane, helicopter or any other kind of aircraft}
            land {Any type of land-based vehicle}
            sea {Any type of watercraft}
            person {A person who individually carries a tracking device}
            other {Any other type of object}
          }`,
        },
        category: `{category, select,
          aircraft {Aeroplane}
          helicopter {Helicopter}
          gyrocopter {Gyrocopter}
          drone {Drone}
          airship {Airship}
          balloon {Balloon}
          rocket {Rocket}
          truck {Truck}
          fireTruck {Fire Truck}
          car {Car}
          policeCar {Police Car}
          ambulance {Ambulance}
          bicycle {Bicycle}
          tractor {Tractor}
          bus {Bus}
          minibus {Minibus}
          motorcycle {Motorcycle}
          motorHome {Motor Home}
          powerboat {Powerboat}
          yacht {Yacht}
          containerShip {Container Ship}
          tug {Tug}
          container {Container}
          unknown {Unknown}
          other {{category}}
        }`,
        aircraft: {
          model: `{category, select,
            aircraft {Aeroplane}
            helicopter {Helicopter}
            gyrocopter {Gyrocopter}
            drone {Drone}
            other {{category}}
          } type`,
          enterManually: 'Unable to find your model? Enter it manually',
          selectFromList: 'Select from list of known models',
        },
        snackbar: {
          success: 'New asset, {name} added successfully',
          error: 'Unknown error prevented asset from being added. Please try again.',
          errorMessagingHandle: 'The given messaging handle is already taken. Please choose another.',
        },
      },
      common: {
        icaoTypeDesignatorInput: {
          label: 'ICAO Type Designator',
          other: 'Other',
          errors: {
            missing: 'ICAO Type designator is required for {category, select, Aircraft {aircraft} Helicopter {helicopters} other {}}',
            invalid: 'ICAO Type designator is invalid or unknown. Select a ICAO type designator from the list or select \'Other\' if you are unsure',
          },
        },
      },
    },
    devices: {
      title: 'Devices',
      description: 'All tracking devices that are assigned to assets belonging to or shared with your organisation.',
      editButton: 'Edit',
      deleteButton: 'Delete',
      newButton: 'New Device',
      tpSerial: 'TracPlus Serial',
      imei: 'IMEI Number',
      manufacturerSerial: 'Manufacturer Serial',
      makeModel: 'Make/Model',
      firmwareVersion: {
        label: 'Firmware Version',
        help: 'The firmware version is only displayed for Rock7 devices',
        outdated: 'Firmware Outdated',
        unknown: 'Unknown',
      },
      status: 'Status',
      lastActive: 'Last Active',
      assignedAsset: 'Assigned Asset',
      unknownAsset: 'Unknown asset',
      actions: 'Actions',
      editDevice: 'Edit Device',
      activated: 'Active',
      deactivated: 'Inactive',
      suspended: 'Suspended',
      unknown: 'Unknown',
      error: 'Error',
      archived: 'Archived',
      viewDevice: 'View Device',
      deviceSerialNumber: 'Device Serial Number',
      owner: 'Owner',
    },
    beacon: {
      title: 'Beacon',
      description: 'TracPlus Beacon allows you to monitor the safety and location of your team. Manage which users are able to track their location with Beacon in the TracPlus app for iOS and Android. To monitor more team members, contact TracPlus support to increase your seats.',
      assignedLabel: '{assigned} of {total} Beacon seats assigned',
      unassignedLabel: 'Unassigned users',
      trackingInProgress: 'Tracking in progress',
      lastTrackedAt: 'Last tracked on {at, date, medium}',
      setNumSeats: 'Set number of seats (STAFF ONLY)',
      cancel: 'Cancel',
      save: 'Save',
      search: {
        label: 'Search users by name or email address',
        placeholder: 'Search',
      },
      tooltips: {
        assignUser: 'Assign user to a seat',
        unassignUser: 'Unassign user from their seat',
        viewOnMap: 'Click to view Beacon asset on the map',
      },
      alerts: {
        noFilteredUsers: 'No people match this search',
        noUsers: 'No people remain to assign to a seat',
        noAssignedUsers: 'No people are assigned to seats',
        noSeats: 'This organisation has no seats',
      },
      dialogs: {
        setNumberOfSeats: {
          title: 'Set number of Beacon seats',
          label: 'Number of Beacon seats',
        },
        saveSeats: {
          description: 'Any unassigned users that are currently tracking with Beacon will cease tracking after the TracPlus app on their mobile device has established a network connection and synced any remaining location reports.',
          confirmMessage: 'Are you sure you want to change assigned users?',
          listUnassigned: 'The following users will be unassigned:',
        },
      },
    },
    settings: {
      legacySettings: {
        title: 'Settings',
        organisationSettingsButton: {
          title: 'Organisation',
          description: 'Organisation details and user accounts'
        },
      },
      userSettings: {
        title: 'User Settings',
        movedTitle: 'Display settings have moved',
        movedDescription: 'Access them from the status bar on any page',
        generalSettingsButton: {
          title: 'General',
          description: 'View and update your user interface settings'
        },
        accountSettingsButton: {
          title: 'Account',
          description: 'View and update your user account settings'
        },
      },
      organisationSettings: {
        title: 'Organisation Settings',
        generalSettingsButton: {
          title: 'General',
          description: 'View and update settings for this organisation'
        },
        usersSettingsButton: {
          title: 'Users',
          description: 'Manage user accounts for this organisation'
        },
        peopleSettingsButton: {
          title: 'People',
          description: 'Manage contact details for people in this organisation'
        },
        ICESettingsButton: {
          title: 'ICE Contact Groups',
          description: 'Manage groups of people to be notified in case of emergency'
        },
        messagingWhitelistsSettingsButton: {
          title: 'Device Messaging Contact Groups',
          description: 'Manage groups of people that are allowed to message devices'
        },
      },
    },
    assetsAndDevices: {
      title: 'Manage',
      description: 'Manage assets and devices.',
      assetsTabTitle: 'Assets & People',
      devicesTabTitle: 'Devices'
    },
    deviceView: {
      loading: 'Loading...',
      general: {
        title: 'Device',
        description: 'General details for your device',
        tpSerial: 'TracPlus Serial',
        imei: 'IMEI',
        manufacturerSerial: 'Manufacturer Serial',
        name: 'Name',
        make: 'Make',
        model: 'Model',
        status: 'Status',
        lastActive: 'Last Active',
        satNumber: 'Satellite phone number',
        smsNumber: 'Satellite SMS number',
        unknownStatus: 'Unknown',
        firmwareOutdated: {
          title: 'Firmware Outdated',
          description: 'The latest version is {latest}',
          help: 'Learn how to update firmware',
        },
      },
      configuration: {
        title: 'Configuration',
        description: 'Configuration details for your device',
        lastUpdated: 'Last Updated',
        unknown: 'Unknown',
      },
      sendConfiguration: {
        buttonTitle: 'Send configuration',
        snackbar: {
          success: `{profile, select,
            Aviation {Aviation}
            AviationFastTrac {Aviation FastTrac}
            Maritime {Maritime}
            MaritimeFastTrac {Maritime FastTrac}
            Land {Land}
            LandFastTrac {Land FastTrac}
            FactoryReset {Factory Reset}
            other {{profile}}
          } configuration profile sent to device successfully`,
        },
        profile: {
          Aviation: 'Aviation',
          AviationFastTrac: 'Aviation FastTrac',
          Maritime: 'Maritime',
          MaritimeFastTrac: 'Maritime FastTrac',
          Land: 'Land',
          LandFastTrac: 'Land FastTrac',
          FactoryReset: 'Factory Reset',
        },
        dialog: {
          title: 'Send configuration profile',
          message: `Are you sure you'd like to send the default <strong>{profile, select,
            Aviation {Aviation}
            AviationFastTrac {Aviation FastTrac}
            Maritime {Maritime}
            MaritimeFastTrac {Maritime FastTrac}
            Land {Land}
            LandFastTrac {Land FastTrac}
            FactoryReset {Factory Reset}
            other {{profile}}
          }</strong> configuration profile to this device?`,
          error: 'Unexpected error prevented configuration from being sent',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },
      rates: {
        title: 'Tracking Rates',
        description: 'Update tracking rates for your device.',
        cancel: 'Cancel',
        updateRates: 'Update Rates',
        updateRatesSuccess: 'Successfully sent rate change request. Allow a few minutes for the request to arrive to the device.',
        updateRatesFailed: 'Failed to send rate change. Please try again.',
        requestConfigFailed: 'Failed to request updated configuration. Please try again.',
        CP_CHG_SAT_RT: {
          title: 'Satellite Rate',
          description: 'The rate at which positions are transmitted when using the satellite network'
        },
        CP_CHG_CEL_RT: {
          title: 'Cellular Rate',
          description: 'The rate at which positions are transmitted when using the cellular network'
        },
        CP_CHG_DS_RT: {
          title: 'Distress Rate',
          description: 'The rate at which positions are transmitted when the device is in distress, no matter the network'
        },
        CP_CHG_STD_RT: {
          title: 'Standard Rate',
          description: 'The rate at which positions are transmitted'
        },
        units: {
          rate: 'Rate',
          seconds: `{value, plural,
            one {# second}
            other {# seconds}
          }`,
          minutes: `{value, plural,
            one {# minute}
            other {# minutes}
          }`,
          hours: `{value, plural,
            one {# hour}
            other {# hours}
          }`,
        }
      },
      asset: {
        title: 'Asset',
        description: 'Asset that this device is assigned to',
        viewAsset: 'View Asset',
        editAsset: 'Edit Asset',
        name: 'Name',
        make: 'Make',
        model: 'Model',
        owner: 'Owner',
        messagingHandle: 'Messaging Handle',
        variant: 'Variant',
        callSign: 'Callsign',
        tailNumber: 'Tail Number',
        errorAssignedDevice: '{statusCode, select, 404 {The asset assigned to this device is not shared with your organisation.} other {The asset assigned to this device could not be retrieved.}}',
      },
      missingDevice: 'The device you were looking for does not exist',
      returnToDeviceList: 'Return to device list',
      unnamed: 'Unnamed Device'
    },
    createDevice: {
      createDevice: 'Create Device',
      devicesList: 'Devices List',
      generalDetailsStep: 'Device General Details',
      deviceDescription: 'Enter your device details',
      serialNum: 'Device Serial Number',
      make: 'Device Make',
      model: 'Device Model',
      imei: 'IMEI Number',
      man: 'MAN Serial Number',
      tp: 'TP Serial Number',
      status: 'Status',
      create: 'Create',
      cancel: 'Cancel'
    },
    assetView: {
      titleNoName: 'Asset Settings',
      'asset-settings': 'Asset Settings',
      archived: 'archived',
      ownedBy: 'Owned by {ownerName} (view only).',
      title: 'Asset Details',
      assetDetailsSaved: 'Asset details saved',
      assetDetailsSaveFailed: 'Failed to save asset, please try again',
      assetImageSaved: 'Asset image saved',
      assetImageRemoveFailed: 'Failed to remove asset image, please try again',
      assetImageRemoved: 'Asset image removed',
      assetImageSaveFailed: 'Failed to save asset image, please try again',
      assetDetailsUniqueMessagingHandle: 'Messaging handle is already taken. Please choose a different handle.',
      assetPropertySaved: 'Asset {property} updated',
      description: 'Review your asset details and click the save button to confirm any changes',
      imageTitle: 'Asset Image',
      imageDescription: 'Upload an image of your asset',
      uploadImage: 'Upload Image',
      replaceImage: 'Replace Image',
      removeImage: 'Remove Image',
      cropImageDialogTitle: 'Crop Image',
      generalTitle: 'Asset Details',
      generalDescription: 'General details for your asset',
      assetLabelMessage: `Assets are labelled by their {assetLabelKey, select,
        callSign {callsign}
        tailNumber {tail number}
        other {name}
      } throughout the application {assetLabelKey, select,
        callSign {with fallback to name if a callsign is not set}
        tailNumber {with fallback to name if a tail number is not set}
        other {}
      }.<break></break> Your preferred label can be changed in <link>General Settings</link>.`,
      name: 'Name',
      category: 'Category',
      make: 'Make',
      model: 'Model',
      variant: 'Variant',
      messagingHandle: 'Messaging Handle',
      watchlistGroup: 'Watchlist Group',
      tailNumber: 'Tail number',
      callSign: 'Callsign',
      deviceTitle: 'Device',
      deviceDescription: 'Device assigned to your asset',
      serial: 'Serial',
      tpSerial: 'TracPlus Serial',
      imei: 'IMEI',
      manufacturerSerial: 'Manufacturer Serial',
      makeModel: 'Make/Model',
      status: 'Status',
      actions: 'Actions',
      unassignedDevice: 'Add unassigned device',
      noUnassignedDevices: 'All your devices are currently assigned',
      assignDevice: 'Assign Device',
      unassignDevice: 'Unassign Device',
      displayTitle: 'Map Display',
      displayDescription: 'How your asset appears on the Map view',
      trailColor: 'Trail Color',
      noColor: 'No color set',
      trailColorDescription: 'Trails will appear in this color on the map.',
      trailColorNote: 'This setting applies to your entire organisation.',
      failedToSave: 'Failed to Save',
      saving: 'Saving',
      saved: 'Saved!',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Remove',
      confirmAssignDeviceTitle: 'Assign Device to Asset?',
      confirmAssignDeviceText: 'Are you sure you want to Assign {device} to {assetName}?',
      confirmModifySharedAssignment: 'This Asset is being shared with the Organisation(s) below; they will likely need to be informed of this change.',
      confirmUnassignDeviceTitle: 'Unassign Device from Asset?',
      confirmUnassignDeviceText: 'Are you sure you want to remove {device} from {assetName}?',
      okButton: 'ok',
      cancelButton: 'cancel',
      noDeviceAssigned: 'No device assigned',
      noUnassignedDevicesAvailable: 'No unassigned devices available',
      deviceAlreadyAssigned: 'Device Already Unassigned',
      assigned: 'Assigned',
      unassigned: 'Unassigned',
      never: 'Never',
      missingAsset: 'The asset you were looking for does not exist',
      image: 'Image',
      systemAsset: 'This is a system asset which acts as a safeguard to allow {make} {model} {serial} to be seen on the map. For full-featured tracking, this tracking device should be assigned to a real asset.',
      amsSettings: {
        title: 'Active Monitoring Settings',
        description: 'Configure active monitoring settings for this asset.',
        ofConcernTimeout: 'Of Concern Timeout',
        overdueTimeout: 'Overdue Timeout',
        stationaryThreshold: 'Stationary Threshold',
        stationaryEnabled: 'Enable Stationary Detection',
        minutes: 'minutes',
        reports: 'reports',
        overdue: 'Overdue',
        assetDeregistered: 'This asset is not registered for AMS. This asset will not be monitored.',
        assetUnsupported: 'This asset does not support AMS',
        ofConcern: 'Of Concern',
        overdueInvalid: 'This value must be greater than 0 and less than 100',
        ofConcernInvalid: 'This value must be greater than 0 and less than 100',
        stationaryInvalid: 'This value must be greater than 0',
        minutesWithoutReporting: '{time, plural, one {# minute} other {# minutes}} <br></br> without reporting',
        stationaryReports: `{reports, plural,
            one {# stationary report}
            other {# stationary reports}
            }`,
        normalTracking: 'Normal Tracking',
        or: 'or',
        saveButton: 'Save',
        cancelButton: 'Cancel',
        deregisterButton: 'Deregister',
        registerButton: 'Register',
        updateAmsError: 'Failed to update Active Monitoring settings. Please try again.',
        amsUpdated: 'Active Monitoring settings saved',
        amsUnsetError: 'Failed to remove Active Monitoring settings. Please try again.',
        amsUnset: 'Active Monitoring settings removed',
        confirmDeregisterTitle: 'Are you sure you want to deregister this device from AMS?',
        confirmDeregisterDescription: 'Any AMS sessions attempted to be started by the device will be unsuccessful.'
      },
      incidentSuppression: {
        title: 'Incident Escalation Settings',
        description: 'Configure escalation settings for this asset.',
        isMonitored: 'This asset is currently monitored.',
        isMonitoredDescription: 'Any incidents raised by this device will be escalated to your ICE contacts and/or the TracPlus support team.',
        suspend: 'Suspend Monitoring',
        isNotMonitored: 'This asset\'s incident escalation has been suspended until ',
        enableMonitoring: 'Enable monitoring now',
        suspendFor: 'Suspend For',
        timeHours: `{hours, plural,
            one {# hour}
            other {# hours}
            }`,
        cancel: 'Cancel',
        incidentEscalationResumed: 'Incident escalation successfully resumed.',
        incidentEscalationResumeFailed: 'Resuming incident escalation failed. Please try again.',
        incidentEscalationSuspended: 'Incident escalation successfully suspended.',
        incidentEscalationSuspendFailed: 'Suspending incident escalation failed. Please try again.',
        confirmSuspendTitle: 'Are you sure you want to suspend escalation?',
        confirmSuspendDescription: 'Any incidents raised by this device will not be escalated to your ICE contacts or the TracPlus support team until:',
        suppressDisabledAlert: 'You cannot suppress incidents for an asset with AMS registered.',
        noDeviceError: 'A device must be assigned to configure escalation settings',
      },
      rows: 'rows',
      of: 'of',
      firstPage: 'First Page',
      nextPage: 'Next Page',
      previousPage: 'Previous Page',
      lastPage: 'Last Page',
      assign: 'Assign',
      assignDeviceDialogTitle: 'Assign a Device',
      assignDeviceDialogContent: 'Select a device to assign to your asset.',
      changeDevice: 'Change',
      changeDeviceDialogTitle: 'Change Device Assignment',
      changeDeviceDialogContent: 'Select the new device to assign to your asset.',
      changeDeviceDialogTransferShares: 'Automatically transfer shares',
      changeDeviceDialogTransferSharesHelp: 'When enabled, all shares associated with the asset\'s current device will be transferred to the new device.',
      removeDeviceFromAssetFailed: 'Failed to remove this device from this asset.',
      removeDeviceFromAssetSucceeded: 'Device was successfully removed from this asset.',
      removeDevice: 'Remove',
      removeDeviceDialogTitle: 'Remove Device Assignment',
      removeDeviceDialogText: 'Please confirm you wish to remove this device from this asset.',
      removeDeviceConfirmationButton: 'Remove Device',
      device: 'Device',
      changeDeviceButton: 'Change Device',
      selectDeviceButton: 'Select Device',
      assignDeviceToAssetFailed: 'Failed to assign a device to this asset.',
      getDevicesFailed: 'Failed to retrieve a list of devices.',
      assignDeviceToAssetSaved: 'Device was successfully assigned to this asset.',
      confirmChangeDeviceDialogTitle: 'Please confirm?',
      confirmChangeDeviceDialogDevice: 'Device',
      confirmChangeDeviceDialogWillBeAssignedTo: 'will be assigned to',
      confirmChangeDeviceDialogWillBeUnassignedFrom: 'will be unassigned from',
      confirmChangeDeviceDialogAndAssignedTo: 'and assigned to',
      unknownStatus: 'Unknown',
      iceContactGroup: {
        title: 'ICE Contact Group',
        description: 'The contact group for people who will be contacted in case of emergency for this asset',
        view: 'View this ICE Contact Group',
        edit: 'Edit this ICE Contact Group',
        loading: 'Loading contact group...',
        noGroupAssigned: 'No ICE contact group assigned and there is no default ICE contact group for your organisation.',
        noGroupAssignedWithDefault: 'No ICE contact group assigned. The default contact group, {name} will be used.',
        noGroups: 'Your organisation has no ICE contact groups.',
        noPeople: 'This contact group has no people assigned. People from the default contact group will be used.',
        assign: 'Assign ICE Contact Group',
        create: {
          title: 'Create new ICE contact group',
          snackbar: {
            success: 'New ICE contact group, {name} created successfully',
          },
        },
      },
      messagingWhitelistContactGroup: {
        title: 'Device Messaging Contact Group',
        description: 'The contact group for people that are allowed to message the device that tracks this asset',
        view: 'View this Device Messaging Contact Group',
        edit: 'Edit this Device Messaging Contact Group',
        loading: 'Loading device messaging contact group...',
        noGroupAssigned: 'No device messaging contact group assigned and there is no default device messaging contact group for your organisation.',
        noGroupAssignedWithDefault: 'No device messaging contact group assigned. The default device messaging contact group, {name} will be used.',
        noGroups: 'Your organisation has no device messaging contact groups.',
        noPeople: 'This device messaging contact group has no people assigned. People from the default device messaging contact group will be used.',
        assign: 'Assign Device Messaging Contact Group',
        create: {
          title: 'Create new device messaging contact group',
          snackbar: {
            success: 'New device messaging contact group, {name} created successfully',
          },
        },
      },
      archiveDescription: 'This hides the asset from the map view and asset list. No data is lost.',
      archivedDescription: 'This asset is archived. Unarchive it to see it on the asset list.',
      deviceAssignDisabled: 'You cannot assign a device to this asset, as this asset is archived.',
      archive: 'Archive',
      archival: 'Archival',
      archivalUpdated: 'Archival status of {name} updated',
      unarchive: 'Unarchive',
      editCallSign: {
        edit: 'Edit',
        snackbar: {
          success: 'Asset callsign updated successfully',
        },
        dialog: {
          title: 'Edit callsign for {asset}',
          description: '{owner} has granted you permission to edit the callsign for this asset.',
          callsign: 'Callsign',
          cancel: 'Cancel',
          save: 'Save',
          saving: 'Saving...',
          error: 'An unexpected error prevented this callsign from being saved. Please try again.',
        },
      },
      sharingTo: {
        title: 'Sharing',
        description: 'Details of how this asset is shared to your organisation',
        loading: 'Loading shares...',
        owner: 'This asset is shared by {owner}',
        explanation: 'Below are the date ranges and permissions for each share.',
        dateRange: 'Date range',
        viewTrails: 'View trails',
        canViewForms: 'View forms',
        canSendTextMessages: 'Message',
        canSendConfiguration: 'Configure',
        canEditCallSign: 'Edit callsign',
        allTrails: 'Live and historic',
        liveTrails: 'Live only',
        historicTrails: 'Historic only',
        noTrails: 'None',
        sharedViaGroup: 'Shared via the {group} group',
        notes: 'Notes',
      },
    },
    createAsset: {
      createAsset: 'Create Asset',
      landSeaAirStep: 'Land, Sea or Air',
      landSeaAirDescription: 'Where will your asset be operating?',
      categoryStep: 'Select Category',
      categoryDescription: 'What is your asset?',
      assetStep: 'Identify Asset',
      assetDescription: 'Enter your assets details',
      name: 'Name',
      nameHelper: 'Eg: Aircraft Registration',
      make: 'Make',
      makeHelper: 'Eg: Boeing',
      model: 'Model',
      modelHelper: 'Eg: 747',
      variant: 'Variant',
      variantHelper: 'Eg: A (optional)',
      color: 'Color',
      air: 'Air',
      land: 'Land',
      sea: 'Sea',
      other: 'Other',
      drone: 'Drone',
      heartbeat: 'Heartbeat',
      person: 'Person',
      worker: 'Worker',
      'executive man': 'Executive',
      'executive woman': 'Executive',
      bicycle: 'Bicycle',
      truck: 'Truck',
      bus: 'Bus',
      ambulance: 'Ambulance',
      car: 'Car',
      'fire truck': 'Fire Truck',
      'police car': 'Police Car',
      minibus: 'Minibus',
      'mobile home': 'Mobile Home',
      motorcycle: 'Motorcycle',
      tractor: 'Tractor',
      yacht: 'Yacht',
      tug: 'Tug',
      'container ship': 'Container Ship',
      powerboat: 'Powerboat',
      container: 'Container',
      aircraft: 'Aircraft',
      airship: 'Airship',
      helicopter: 'Helicopter',
      balloon: 'Balloon',
      rocket: 'Rocket',
      unknown: 'Unknown',
      system: 'System'
    },
    generalSettings: {
      title: 'General Settings',
      userInterfaceLabel: 'User Interface',
      userInterfaceDescription: 'Manage the appearance of the user interface throughout the application',
      darkModeLabel: 'Colour mode',
      darkMode: 'Dark mode',
      lightMode: 'Light mode',
      systemMode: 'System',
      defaultSerialType: 'Serial number for devices',
      tpSerial: 'TracPlus serial',
      imei: 'IMEI',
      manufacturerSerial: 'Manufacturer serial',
      rowsPerPage: 'Rows per page in tables',
      rows: 'rows',
      assetLabel: 'Asset label',
      fallbackToName: 'Falls back to name if not set',
      name: 'Name',
      callSign: 'Callsign',
      tailNumber: 'Tail number',
      assetDetailToDisplay: 'Asset Detail to Display',
      displaySettings: 'Display Settings',
      unitsDescription: 'Manage the units of measurement that are used throughout the application',
      unitsLabel: 'Units',
      distanceLabel: 'Distance',
      speedLabel: 'Speed (default)',
      speedLabelAir: 'Speed (aircraft)',
      speedLabelLand: 'Speed (land vehicle)',
      speedLabelSea: 'Speed (maritime vessel)',
      speedLabelPerson: 'Speed (person)',
      altitudeLabel: 'Altitude',
      bearingLabel: 'Track',
      coordinateLabel: 'Coordinate format',
      areaLabel: 'Area',
      volume: 'Volume',
      duration: 'Duration',
      resetButton: 'Reset to default',
      eventNotifications: {
        landingLabel: 'Map notifications for landing events',
        takeoffLabel: 'Map notifications for takeoff events',
        enabled: 'Enabled',
        disabled: 'Disabled',
      },
      warnings: {
        usercodeBrowserStorageOnly: 'You are logged in with a usercode. These settings will only be stored in this web browser and not saved to the account.',
      },
      unitDescriptions: {
        coordinatesDD: 'Decimal',
        coordinatesDMS: 'DMS',
        coordinatesDDM: 'DM',
      },
      tooltips: {
        kilometres: 'Kilometres',
        statuteMiles: 'Statute miles',
        nauticalMiles: 'Nautical miles',
        kmh: 'Kilometers per hour',
        mph: 'Miles per hour',
        knots: 'Knots',
        metres: 'Metres',
        feet: 'Feet',
        degreesTrue: 'Degrees true',
        degreesMagnetic: 'Degrees magnetic',
        coordinatesDD: 'Decimal degrees',
        coordinatesDMS: 'Degrees, minutes, and seconds',
        coordinatesDDM: 'Degrees and decimal minutes',
        squareKilometres: 'Square kilometres',
        acres: 'Acres',
        hectares: 'Hectares',
        squareMiles: 'Square miles',
        squareNauticalMiles: 'Square nautical miles',
        litres: 'Litres',
        gallons: 'US gallons',
        hoursMinutes: 'Hours and minutes',
        decimalTime: 'Decimal hours',
      },
    },
    accountSettings: {
      title: 'Account Settings',
      nameLabel: 'Name',
      roleLabel: 'Role',
      emailLabel: 'Email',
      passwordLabel: 'Password',
      tableActions: 'Actions',
      orgTitle: 'Your Organisations',
      orgDescription: 'Organisations you are a member of.',
      orgTableName: 'Organisations Name',
      orgTableRole: 'Your Role',
      leave: 'Leave',
      leaveOrg: 'Leave {orgName}',
      leaveOrgConfirm: 'Are you sure you want to leave {orgName}?',
      cancel: 'Cancel',
      userTitle: 'User Profile',
      userDescription: 'Your account details.',
      userButton: 'Save',
      editButton: 'Edit Organisation',
      organisations: 'Organisations',
      newPassword: 'Change your password',
      newPasswordSuccess: 'Password reset email sent to:',
      newPasswordError: 'Please check your user has a valid email address',
      savedChanges: 'Saved user profile',
      failedToSaveUser: 'Failed to save user, please reload the page and try again.',
      wrongPassword: 'The password you entered was incorrect, please reload the page and try again.',
      emailInUse: 'Failed to change email, this email is already in use.',
      nameIsRequired: 'Name is required',
      emailRequired: 'Email address is required',
      invalidEmail: 'Invalid email address',
      reauthenticateHeader: 'Please verify your identity'
    },
    organisationSettings: {
      missing: {
        title: 'Organisation not found',
        description: 'You do not have access to settings for this organisation.',
        link: 'Return to settings',
      },
      title: 'Organisation Settings for {orgName}',
      nameLabel: 'Name',
      emailLabel: 'Email',
      tableName: 'Name',
      tableDetails: 'Details',
      tableDetailsSubtext: 'Details about your Organisation',
      orgNameUpdateFailed: "Couldn't update Organisation name, please reload the page and try again",
      orgNameUpdated: 'Organisation name updated successfully',
      tableLanguage: 'Language',
      tableRoles: 'Role',
      tableActions: 'Actions',
      deleteButton: 'Delete',
      inviteButton: 'Send Invitation',
      cancelInviteButton: 'Cancel Invitation',
      inviteSent: 'User invitation email sent',
      inviteSendFailed: 'Failed to send invite, please try again',
      userExistedHelperText: 'There is an existing user with this email address',
      userPendingHelperText: 'This user already has a pending invitation',
      newUserTitle: 'Invite User',
      newUserDescription: 'Invite a new user to your Organisation.',
      organisationContactsTitle: 'Organisation Contacts',
      organisationContactsDescription: 'Configure the contact details for each department.',
      primaryOrgContactTitle: 'Primary contact',
      billingOrgContactTitle: 'Billing contact',
      billingOrgContactNone: 'No billing contact configured',
      Administrator: 'Administrator',
      ViewOnly: 'View Only',
      'View Only': 'View Only',
      organisationAccessTitle: 'Organisation Access Settings',
      organisationUsersTitle: 'Organisation Users',
      inviteUserTitle: 'Invite User',
      contactsTitle: 'Contacts',
      users: 'Users',
      error: 'Error',
      billingTitle: 'Billing',
      userRemoved: 'User successfully removed.',
      userRemovedError: 'Error removing user, please try again.',
      roleUpdated: 'Role update successful.',
      roleUpdateError: 'Could not update role, please try again.',
      userListDescription: 'Administrate user accounts in your Organisation.',
      emergencyContacts: {
        title: 'Contacts',
        description: 'These contacts can be assigned as emergency and device messaging contacts for assets on the asset page.',
        movedTitle: 'Your organisation\'s contacts are now found in the Manage section',
        movedMessage: 'There you can view and manage contact people, ICE contact groups, and device messaging contact groups.',
        movedLink: 'Manage Contacts',
      },
      access: {
        title: 'Organisation Access Settings',
        description: 'The Access of this Organisation is {access}',
        label: 'Access',
        details: 'Details of each Access level:',
        publicDescription: 'This Organisation can be seen by all users globally and be befriended by another Organisation without confirmation required.',
        privateDescription: 'This Organisation can be seen by all users globally, but befriending requires confirmation to be approved.',
        hiddenDescription: 'This Organisation cannot be seen by users outside of it.',
        accessLevels: {
          public: 'Public',
          private: 'Private',
          hidden: 'Hidden'
        }
      },
      timezone: {
        title: 'Timezone',
        description: 'Configure the time zone based on your Organisation time zone',
        label: 'Timezone',
        placeholder: 'Choose a Timezone'
      },
      amsSettings: {
        title: 'Active Monitoring Settings',
        description: 'Configure active monitoring settings for all assets in your organisation. These can be overridden for individual assets.',
        ofConcernTimeout: 'Of Concern Timeout (minutes)',
        overdueTimeout: 'Overdue Timeout (minutes)',
        stationaryCount: 'Stationary Count (reports)',
        disableOrgAms: 'Enable active monitoring for all assets in this organisation'
      },
      removeUserFromOrganisation: 'Remove user from organisation',
      removeUserDialogText: 'Remove {userName} from {organisationName}?',
      cancelInviteToOrganisation: 'Cancel users invitation to organisation',
      cancelInviteDialogText: 'Cancel the invitation for {name} with email {email} to {organisationName}?',
      okButton: 'ok',
      cancelButton: 'cancel',
      search: 'Search',
      noUsersFound: 'No user records found',
      member: 'Member',
      pending: 'Pending',
      rows: 'rows',
      of: 'of',
      firstPage: 'First Page',
      nextPage: 'Next Page',
      previousPage: 'Previous Page',
      lastPage: 'Last Page',
      orgName: 'Name',
      billPayer: 'Bill Payer',
      orgDescription: 'Description',
      marketSector: {
        primaryLabel: 'Market Sector',
        secondaryLabel: 'Additional Market Sectors',
        add: 'Add New',
        dialog: {
          title: 'Add additional market sector',
          saveButton: 'Save',
          cancelButton: 'Cancel',
        },
        snackbar: {
          exists: 'Market sector is already assigned to this organisation',
          error: 'Could not update organisation market sector, please reload the page and try again or contact TracPlus support',
          success: 'Market sector updated successfully'
        },
      }
    },
    iceSettings: {
      title: 'In case of emergency'
    },
    map: {
      template: {
        name: {
          'mapbox-dark': 'Mapbox Dark',
          'mapbox-outdoors': 'Mapbox Outdoors',
          'mapbox-satellite': 'Mapbox Satellite',
          'mapbox-satellite-streets': 'Mapbox Satellite Streets',
          'mapbox-streets': 'Mapbox Streets',
          'nasa-gibs-snpp': 'Satellite Daily',
          'reactmapgl-sv-vfr': 'SkyVector VFR Map',
          'reactmapgl-sv-ifr-hi': 'SkyVector IFR Hi Map',
          'reactmapgl-sv-ifr-lo': 'SkyVector IFR Lo Map',
          'open-seamap': 'OpenSeaMap',
          linz: 'LINZ (NZ only)',
        },
        attribution: {
          linz: 'Sourced from the <a href="https://data.linz.govt.nz/" target="_blank">LINZ Data Service</a> and licensed for reuse under the <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a> licence',
          openSeaMap: '&copy; <a href="https://www.openseamap.org/index.php">OpenSeaMap</a> &copy; <a href="https://www.openstreetmap.org/about/">OpenStreetMap</a> contributors',
          nasa: 'Imagery provided by services from NASA\'s Global Imagery Browse Services (GIBS), part of NASA\'s Earth Observing System Data and Information System (EOSDIS)',
          skyVector: 'Maps provided by <a href="https://skyvector.com" target="_blank">SkyVector</a>',
        },
        provider: {
          mapbox: 'Mapbox',
          skyVector: 'SkyVector',
          linz: 'LINZ',
          openSeaMap: 'OpenSeaMap',
          nasa: 'NASA GIBS',
        },
      },
      liveUpdateDummy: {
        liveUpdateFailed: 'Failed to get latest device position and event reports. Please reload the page'
      },
      mapLayoutSpeeddial: {
        title: 'Split Map Options',
        singleMap: 'Single map',
        twoMapsHorizontal: 'Two maps - Horizontal',
        twoMapsVertical: 'Two maps - Vertical',
        threeMapsA: 'Three maps',
        threeMapsB: 'Three maps',
        fourMaps: 'Four maps'
      },
      settingsDialog: {
        title: 'Map Settings',
        mapLayers: 'Map Layers',
        mapLayerGroups: {
          nauticalCharts: 'Nautical Charts',
        },
        animations: 'Animations',
        labels: 'Labels',
        assetDetails: 'Asset Details',
        iconAppearance: 'Icon Appearance',
        mapControls: 'Map Controls',
        highlightDrops: 'Firefighting mode',
        inputTitle: 'Map Options',
        assetLabels: 'Asset Labels',
        reportDots: 'Report Dots',
        reportDotsZoom: 'Hide Report Dots at Zoom',
        currentZoom: 'Current Zoom',
        kmlLabels: 'KML Labels',
        kml: {
          displayOverlay: 'Display Overlay on Hover',
          displayAdditionalData: 'Display Additional Data in Overlay'
        },
        animateTrails: 'Animate Trails',
        highContrastControls: 'High contrast map controls',
        scale: 'Distance Scale',
        assetClustering: 'Asset Clustering',
        duplicateMapObjects: 'Duplicate Map Objects',
        animateSelectedTrailOnly: 'Animate Selected Trail Only',
        currentTripTrails: 'Only Show Trails for Active Trips',
        colorByState: 'Color asset by state',
        mapType: 'Map Type',
        showActiveAssetsOnly: 'Hide Inactive Assets',
        hoursOfInactivity: 'Hours of inactivity:',
        trailOptions: 'Trails Options',
        noTrails: 'No Trails - Asset Icons Only',
        selectedTrails: 'Selected Asset Trail and Asset Icon Only',
        selectedTrailsIcons: 'Selected Asset Trail & All Asset Icons',
        allTrailsIcons: 'All Asset Trails & Icons',
        firefightingOptions: {
          title: 'Firefighting Options',
          showDropTrails: 'Show Drop Trails',
          showDropIcons: 'Show Drop Icons',
          help: 'Firefighting options information',
        },
        customLayers: 'Custom Layers',
        otherLayers: {
          title: 'Other Layers',
          sunLayer: 'Sun Overlay',
        },
        dropFile: 'Drop file',
        dropFilesHere: 'Drop KML, KMZ or \nTPP3 Markers.xml file here',
        loadedFile: 'Loaded file',
        removeKML: 'Remove KML',
        assetActivity: 'Asset Activity',
        selectedTrailColouring: 'Selected Trail Colouring',
        assetDetail: {
          bearingAtSpeed: 'Track and Speed',
          altitudeAtSpeed: 'Altitude and Speed',
          position: 'Position',
          make: 'Make',
          model: 'Model',
          variant: 'Variant',
          owner: 'Owner',
          category: 'Category',
        },
        geofences: {
          title: 'Geofences',
          help: 'Learn about geofences',
          enabled: 'Show geofences',
        },
        markers: {
          title: 'Markers',
          help: 'Learn about markers',
          enabled: 'Show markers',
        },
        '3d': {
          title: '3D Map',
          help: 'Learn about our 3D map',
          enabled: 'Show map in 3D',
          curtainEnabled: 'Show curtain below trails',
        },
        containmentLines: {
          title: 'Containment Lines',
          help: 'Learn about containment lines',
          picker: {
            none: 'None',
            selectedAsset: 'Selected Asset only',
            allAssets: 'All Assets',
          },
        },
        labs: {
          description: 'Preview the latest innovations in mapping',
          weather: {
            title: 'Weather',
            help: 'Learn about weather mapping',
            windTrails: 'Wind trails',
            windVelocityHeatmap: 'Wind velocity heatmap',
          },
          adsb: {
            title: 'ADS-B',
            help: 'Learn about ADS-B mapping',
            enabled: 'Show ADS-B data',
          },
          '3d': {
            title: '3D Map',
            help: 'Learn about our 3D map',
            enabled: 'Show map in 3D',
            curtainEnabled: 'Show curtain below trails',
          },
          markers: {
            title: 'Markers',
            help: 'Learn about markers',
            enabled: 'Show markers',
          },
          containmentLines: {
            title: 'Containment Lines',
            help: 'Learn about containment lines',
            picker: {
              none: 'None',
              selectedAsset: 'Selected Asset only',
              allAssets: 'All Assets',
            },
          },
        },
        annotations: {
          title: 'Annotations',
          help: 'Learn about annotations',
          noData: 'Use the map tools to add new annotations which can be managed from here'
        }
      },
      mapControls: {
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        mapSettings: 'Map Settings',
        measurementTool: 'Measurement Tool',
        distanceRings: 'Distance Rings',
        velocityLeaders: 'Velocity Leaders',
        createMarker: 'Create Marker',
        addSearchPattern: 'Add Search Pattern',
        flightFollow: 'Follow Asset',
        positionDetails: 'Position Details',
        resetMap: 'Reset Map',
        closeMap: 'Close map',
        following: 'Following',
        mapTools: 'Map Tools',
        createPoint: 'Add Point Annotation',
        createPath: 'Add Path Annotation',
        setHome: 'Set Home',
        home: 'Home',
        homeLocationNotFound: 'Home location not found',
        homeLocationSet: 'Home location set',
        homeLocationSetError: 'Error setting home location',
      },
      measurementTool: {
        instruction: 'Click to set path points, double click to confirm',
      },
      measurementPanel: {
        toMarker: 'to marker',
        toCursor: 'to cursor',
        distance: 'Distance',
        speed: 'Speed',
        ETE: 'ETE',
        ETA: 'ETA'
      },
      mapInterval: {
        title: 'Basemap Interval',
        start: 'Imagery Start',
        end: 'Imagery End'
      },
      reportDetails: {
        eventType: 'Event Type',
        inferredEventTypes: 'Inferred Event Types',
        network: 'Network',
        timeOfFix: 'Time of Fix',
        transport: 'Transport',
        date: 'Date',
        latitude: 'Latitude',
        longitude: 'Longitude',
        altitude: 'Altitude',
        elevation: 'Elevation',
        speed: 'Speed',
        course: 'Track',
        cellular: 'Cellular',
        coordinates: 'Coordinates',
        satellite: 'Satellite',
        standard: 'Standard',
        latency: 'Latency'
      },
      error: {
        message: 'An unexpected error occurred while rendering the map',
        reset: 'Reset',
      },
      markers: {
        overflow: '...and {n} more',
        marker: 'Marker',
        icon: 'Icon',
        type: 'Type',
        notes: 'Notes',
        coordinates: 'Coordinates',
        assetToMarker: '{asset} to {marker}',
        distance: 'Distance',
        bearing: 'Track',
        ete: 'ETE',
        eta: 'ETA',
        description: 'Description',
      },
      geofences: {
        geofence: 'Geofence',
        category: 'Category',
        categoryValue: '{category, select, Generic {Generic} RestrictedArea {Restricted area} AreaOfOperation {Area of operation} other {Unknown}}',
        description: 'Description',
        minAltitude: 'Minimum Altitude',
        maxAltitude: 'Maximum Altitude',
      },
      searchPatterns: {
        type: {
          parallelTrack: 'Parallel Track',
          creepingLine: 'Creeping Line',
          expandingBox: 'Expanding Box',
          sectorSearch: 'Sector Search',
          rangeBearingLine: 'Range Bearing Line',
        },
        overlay: {
          title: 'Editing: {name}',
          delete: 'Delete',
          close: 'Close',
          fields: {
            name: 'Name',
            notes: 'Notes',
            latitude: 'Start Latitude',
            longitude: 'Start Longitude',
            orientation: 'Orientation',
            firstTurnDirection: 'First Turn Direction',
            turnDirection: 'Turn Direction',
            trackSpacingMetres: 'Track Spacing',
            legCount: 'Leg Count',
            legLengthMetres: 'Leg Length',
            orientationArc: 'Orientation ±',
            legLengthRangeMetres: 'Leg Length ±',
            locked: 'Locked',
          },
          fieldValues: {
            direction: {
              left: 'Left',
              right: 'Right',
            },
          },
          fieldErrors: {
            name: {
              tooLong: 'Name is too long',
              tooShort: 'Must provide a name',
            },
            notes: {
              tooLong: 'Notes are too long',
            },
            trackSpacingMetres: {
              tooSmall: 'Track spacing is too small',
              tooLarge: 'Track spacing is too large',
            },
            legCount: {
              tooFew: '{type, select, expandingBox {Must have at least 3 legs} other {Must have at least 2 legs}}',
              tooMany: '{type, select, expandingBox {Must have at most 99 legs} other {Must have at most 100 legs}}',
              notOdd: 'Must have an odd number of legs',
            },
            legLengthMetres: {
              tooSmall: 'Leg length is too small',
              tooLarge: 'Leg length is too large',
            },
            orientationArc: {
              tooSmall: 'Invalid orientation arc',
              tooLarge: 'Invalid orientation arc',
            },
            legLengthRangeMetres: {
              tooSmall: 'Leg length range is too small',
              tooLarge: 'Leg length range is too large',
            },
          },
        },
      },
      annotations: {
        drawOverlay: {
          title: 'Draw Path',
          label: 'Draw a path you want to create and select the last point to complete path and save',
          button: {
            cancel: 'Cancel',
            save: 'Save',
          }
        },
        hoverOverlay: {
          title: 'Map Annotation',
          headers: {
            description: 'Description',
            coordinates: 'Coordinates',
            type: 'Type',
            distance: 'Distance',
            bearing: 'Track',
            ete: 'ETE',
            eta: 'ETA',
          },
          assetToMarker: '{asset} to {marker}',
          type: {
            path: 'Path',
            point: 'Point'
          },
        }
      }
    },
    messaging: {
      title: 'Conversations',
      findConversation: 'Find Conversation',
      newConversation: 'New conversation',
      inputPlaceholder: 'New Message',
      sendButton: 'Send',
      newConversationBar: {
        label: 'To: search users & assets',
        noUserFound: 'No user found with name {userName}.',
        clear: 'Clear',
        error: 'Unexpected error occurred while loading recipients',
      },
      sent: 'Message status sent',
      pending: 'Message status pending',
      delivering: 'Message status pending',
      unknown: 'Message status pending',
      failed: 'Failed to send message, please try again',
      success: 'Message sent successfully',
      assetConversationWarning: 'Sending messages to satellite devices may incur a cost.',
      unmessageableAssetConversationWarning: 'This asset does not have a device capable of messaging installed.',
      getConversationsFailed: 'Failed to fetch conversations. Please reload the page',
      getMessagesFailed: 'Failed to fetch messages for the device'
    },
    organisationsList: {
      title: 'Organisations List',
      description: 'Manage all organisations',
      organisationName: 'Organisation Name',
      usercode: 'Usercode',
      search: 'Search',
      actions: 'Actions',
      leave: 'Leave',
      join: 'Join',
      leaveOrg: 'Leave {orgName}',
      joinOrg: 'Join {orgName}',
      leaveOrgConfirm: 'Are you sure you want to leave {orgName}?',
      joinOrgConfirm: 'Are you sure you want to join {orgName} as an admin?',
      cancel: 'Cancel',
      access: 'Access Level',
      primaryContactName: 'Primary Contact',
      billingContactName: 'Billing Contact',
      userCount: 'Users',
      enabled: 'Enabled',
      userCountTooltip: 'Including pending invitations, excluding TP Staff.',
      iceContactCount: 'ICE Contacts',
      companyName: 'Company Name: ',
      name: 'Contact Name: ',
      phone: 'Phone: ',
      phoneAlt: 'Phone (alternative): ',
      email: 'Email: ',
      sms: 'SMS: ',
      city: 'City: ',
      country: 'Country: ',
      createOrg: 'Create Organisation',
      ShowOrgContactDetails: 'Show Organisation Contact Details',
      saveOrgContactFailed: 'Failed to save organisation contact details',
      saveOrgContactSuccess: 'Organisation contact details updated',
      createDialog: {
        public: 'Public',
        private: 'Private',
        hidden: 'Hidden',
        publicDescription: 'This Organisation can be seen by all users globally and be befriended by another Organisation without confirmation required.',
        privateDescription: 'This Organisation can be seen by all users globally, but befriending requires confirmation to be approved.',
        hiddenDescription: 'This Organisation cannot be seen by users outside of it.',
        createOrg: 'Create a New Organsation',
        orgName: 'Organisation Name',
        OrgDescription: 'Organisation Description',
        primaryContact: 'Primary Contact',
        contactName: 'Contact Name',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        city: 'City',
        state: 'State',
        postcode: 'Postcode',
        country: 'Country',
        cancel: 'Cancel',
        create: 'Create',
        label: 'Access'
      },
      close: 'Close',
      orgDescription: 'Description',
      primaryContactDetail: 'Primary Contact',
      billingContactDetail: 'Billing Contact',
      iceContactDetail: 'ICE Contacts',
      error: 'Failed to fetch the organisations data...',
      noDescriptionSet: 'No description set.',
      noICEContactsSet: 'No ICE contacts set.',
      rows: 'rows',
      of: 'of',
      firstPage: 'First Page',
      nextPage: 'Next Page',
      previousPage: 'Previous Page',
      lastPage: 'Last Page',
      hidden: 'hidden',
      public: 'public',
      private: 'private'
    },
    sharing: {
      title: 'Sharing',
      description: 'Share assets with other organisations',
      more: '{n} more...',
      tabs: {
        sharedFrom: 'Shared from my organisation',
        sharedTo: 'Shared with my organisation',
        sharedSelf: 'Self shares (STAFF ONLY)',
      },
      groupByLabel: 'Group by',
      groupBy: {
        recipient: 'Group by recipient',
        device: 'Group by asset',
      },
      groupByOptions: {
        recipient: 'Recipient',
        device: 'Asset',
      },
      actions: {
        create: 'Create new share',
        edit: 'Edit share',
        delete: 'Delete share',
      },
      loading: 'Loading shares...',
      noSharesFrom: 'There are no assets shared from your organisation.',
      noSharesTo: 'There are no assets shared to your organisation.',
      noSharesSelf: 'There are no self shares for this organisation.',
      noSharesForFilters: 'No shares match the applied filters.',
      noSelfShares: 'No self shares',
      sharedAsset: 'Shared asset',
      sharedToRecipient: 'Assets shared with',
      sharedBy: 'Assets shared by',
      via: 'via',
      columns: {
        asset: 'Asset',
        device: 'Device',
        recipient: 'Recipient',
        time: 'Time',
        trails: 'View trails',
        forms: 'View forms',
        message: 'Message',
        configure: 'Configure',
        callsign: 'Edit callsign',
        notes: 'Notes',
      },
      trails: {
        all: 'Live and historic',
        live: 'Live only',
        historic: 'Historic only',
        none: 'None',
      },
      permissionLabels: {
        canViewForms: 'View forms',
        canSendTextMessages: 'Send text messages',
        canSendConfiguration: 'Cconfigure device',
        canEditCallSign: 'Edit callsign',
      },
      timeRange: {
        all: 'All time',
        from: 'From: {date}',
        to: 'To: {date}',
      },
      asset: {
        label: 'Asset',
        unknown: 'Unknown asset',
        unnamed: 'Unnamed asset',
        unknownWithDevice: 'Unknown asset ({make} {model} {serial})',
      },
      device: {
        label: 'Device',
      },
      selectAssets: {
        label: 'Select assets',
        loading: 'Loading assets',
        all: 'All assets',
        allDescription: 'This will select all possible assets that can be chosen.',
        noAssetGroupName: 'Unnamed asset group',
        noAssetsEnabled: 'No assets enabled',
        nAssetsDisabled: '{n, plural, one {# disabled asset} other {# disabled assets}}',
      },
      tooltips: {
        assetsDisabled: 'The {featureName} feature is not enabled for these assets:',
      },
      selectParticipants: {
        label: 'Select groups or organisations',
        loading: 'Loading groups and organisations',
      },
      filters: {
        labels: {
          filterAssets: 'Filter assets',
          filterRecipients: 'Filter groups and organisations',
          filterPeriod: 'Filter time period',
        },
        allDevices: 'All assets',
        nDevices: '<c>{n}</c> {n, plural, one {asset} other {assets}}',
        allRecipients: 'All groups and organisations',
        nRecipients: '<c>{n}</c> {n, plural, one {group or organisation} other {groups or organisations}}',
        nShares: '{n, plural, one {# share} other {# shares}}',
        selectedPeriod: `{period, select,
          past {<c>Past</c> time period}
          present {<c>Present</c> time period}
          future {<c>Future</c> time period}
          other {Any time period}
        }`,
        actions: {
          clear: 'Clear',
          cancel: 'Cancel',
          apply: 'Apply',
        },
        periodLabel: {
          all: 'Any',
          past: 'Past',
          present: 'Present',
          future: 'Future',
        },
        periodDescription: {
          all: 'Shares for all time periods',
          past: 'Shares that have ended',
          present: 'Shares that are active now',
          future: 'Shares that are yet to begin',
        },
      },
      dateRangePicker: {
        label: 'Date range',
        options: {
          all: 'All time',
          openEnded: 'Open ended',
          specificRange: 'Date range',
        },
        start: 'Start date',
        end: 'End date',
        effectiveFrom: 'Effective from {start}',
        effectiveFromTo: 'Effective from {start} to {end}',
      },
      permissionsPicker: {
        trails: {
          label: 'View trails',
          options: {
            all: 'Live and historic trails',
            live: 'Live trails only',
            historic: 'Historic trails only',
            none: 'No trails',
          },
        },
        permissions: {
          label: 'Permissions',
          options: {
            canSendConfiguration: 'Configure device',
            canSendTextMessages: 'Send text messages',
            canViewForms: 'View forms',
            canEditCallSign: 'Edit callsign',
          },
          descriptions: {
            canSendConfiguration: 'Allow administrators of recipient organisations to configure the shared device',
            canSendTextMessages: 'Allow users in recipient organisations to send text messages to the shared device',
            canViewForms: 'Allow users in recipient organisations to view data submitted in form events',
            canEditCallSign: 'Allow administrators of recipient organisations to edit the callsign for the shared asset',
          },
        },
        required: 'Required'
      },
      note: {
        label: 'Note',
        placeholder: 'What is this asset shared for?',
      },
      create: {
        title: 'Share assets',
        titleSelf: 'Create self share',
        notifications: {
          success: 'Share created successfully',
          error: 'An unexpected error prevented this share from being created. Please try again.',
        },
        selectRecipient: {
          label: 'Share to organisation or group',
        },
        selectAssets: {
          label: 'Share assets',
        },
        actions: {
          cancel: 'Cancel',
          save: 'Save',
          saving: 'Saving...',
        },
      },
      edit: {
        title: 'Edit share',
        titleSelf: 'Edit self share',
        sharedWith: 'Shared with',
        notifications: {
          success: 'Share edited successfully',
          error: 'An unexpected error prevented this share from being edited. Please try again.',
        },
        actions: {
          clone: 'Clone',
          cancel: 'Cancel',
          save: 'Save',
          saving: 'Saving...',
        },
      },
      delete: {
        title: 'Delete share',
        titleSelf: 'Delete self share',
        prompt: 'Are you sure you\'d like to delete this share?',
        warning: 'If deleted, {recipient} will no longer be able to view this asset\'s historic activity for the date range.<br></br> Consider editing the date range to only share activity that you intend to.',
        sharedWith: 'Shared with',
        notifications: {
          success: 'Share deleted successfully',
          error: 'An unexpected error prevented this share from being deleted. Please try again.',
        },
        noPermissions: 'No additional permissions',
        actions: {
          cancel: 'Cancel',
          delete: 'Delete',
          deleting: 'Deleting...',
        },
      },
    },
  },
  dialogs: {
    contactGroups: {
      addGroup: {
        name: 'Group name',
        save: 'Save',
        saving: 'Saving...',
        cancel: 'Cancel',
        validation: {
          nameExists: 'A group already exists with this name',
          nameMissing: '', // not relevant but keeps happy types
        },
        alert: {
          error: 'An unexpected error prevented this contact group from being created. Please try again.',
        },
      },
      assignAssets: {
        selectedAssets: 'Selected {count, plural, one {asset} other {assets}}',
        loadingAssets: 'Loading {count, plural, one {asset} other {assets}}...',
        contactGroupLabel: 'Contact group',
        loadingPeople: 'Loading people...',
        noPeopleInContactGroup: 'This contact group has no people assigned to it',
        save: 'Save',
        saving: 'Saving...',
        cancel: 'Cancel',
        columns: {
          name: 'Name',
          role: 'Role',
          sms: 'SMS',
          voice: 'Voice',
          email: 'Email',
          language: 'Language',
        },
        snackbar: {
          success: '{count, plural, one {{asset}} other {# assets}} assigned to {group} contact group successfully',
        },
        error: 'An unexpected error prevented these assets from being assigned. Please try again.',
      },
      assignPeople: {
        peopleLoading: 'Loading people...',
        selectedPeople: 'Selected people',
        otherPeople: 'People who can be added',
        searchPeople: 'Search people by name',
        searchPeoplePlaceholder: 'Search',
        dragToReorder: 'Drag to reorder',
        minimumPeople: 'Each group should have at least two people assigned',
        noUnselectedPeople: 'No people remain to be added to this group',
        noFilteredPeople: 'No people match this search',
        tooltips: {
          addPerson: 'Add person to group',
          removePerson: 'Remove person from group',
          movePersonUp: 'Move up',
          movePersonDown: 'Move down',
          editPersonContacts: 'Edit default contact details for this person',
        },
        addPerson: 'Add a new contact person',
        alert: {
          addPersonSuccess: 'The newly added person, {name} has been automatically selected',
        },
        skip: 'Skip',
        cancel: 'Cancel',
        save: 'Save',
        saving: 'Saving...',
      },
      assignAssetsSingleGroup: {
        assetsLoading: 'Loading assets...',
        selectedAssets: 'Selected assets',
        otherAssets: 'Other assets',
        searchAssets: 'Search assets',
        searchAssetsPlaceholder: 'Search',
        noUnselectedAssets: 'No assets remain to be added to this group',
        noFilteredAssets: 'No assets match this search',
        assignmentWarning: `Any assets that are assigned will be removed from their existing {type, select,
          ice {ICE contact group}
          messagingWhitelist {device messaging contact group}
          other {group}
        }. Unassigned assets will use your organisation's default {type, select,
          ice {ICE contact group}
          messagingWhitelist {device messaging contact group}
          other {group}
        }.`,
        tooltips: {
          addAsset: 'Add asset to group',
          removeAsset: 'Remove asset from group',
        },
        snackbar: {
          success: 'Assets assigned to {group} successfully',
        },
        error: 'An unexpected error prevented these assets from being added.',
        error409: 'Other changes to this group\'s assigned assets were made before your changes were saved. The latest changes are now displayed. Please review and retry your changes if needed.',
        cancel: 'Cancel',
        save: 'Save',
        saving: 'Saving...',
      },
      setDefault: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      delete: {
        errorDeleting: 'An unexpected error prevented this contact group from being removed.',
        confirm: 'Confirm removal',
        cancel: 'Cancel',
      },
    },
    ice: {
      assignPeople: {
        primary: 'Primary',
        secondary: 'Secondary',
        error: 'An unexpected error prevented these people from being added.',
        error409: 'Other changes to this group\'s assigned people were made before your changes were saved. The latest changes are now displayed. Please review and retry your changes if needed.',
        failsDefaultGroupRequirements: 'The assigned primary and secondary contact people must both have a voice phone number and an SMS phone number.',
        tooltips: {
          missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
        },
        snackbar: {
          success: 'People assigned to contact group successfully',
        },
      },
      assignAssets: {
        title: 'Assign {count, plural, one {asset} other {assets}} to ICE contact group',
      },
      delete: {
        title: 'Remove ICE contact group',
        message: 'Are you sure you want to remove contact group, <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {The one asset} =2 {Both assets} other {All {count, number} assets}} which this contact group is assigned to will use the default ICE contact group.',
        noAssets: 'This contact group is not assigned to any assets.',
        defaultGroup: 'This group cannot be removed because it is your organisation\'s default ICE contact group. Before removing this group, you must set another group as the default.',
        snackbar: {
          success: 'ICE contact group, {name} removed successfully',
        },
      },
    },
    messagingWhitelist: {
      assignPeople: {
        error: 'An unexpected error prevented these people from being added.',
        error409: 'Other changes to this group\'s assigned people were made before your changes were saved. The latest changes are now displayed. Please review and retry your changes if needed.',
        tooltips: {
          missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
        },
        snackbar: {
          success: 'People assigned to contact group successfully',
        },
      },
      assignAssets: {
        title: 'Assign {count, plural, one {asset} other {assets}} to device messaging contact group',
      },
      delete: {
        title: 'Remove device messaging contact group',
        message: 'Are you sure you want to remove contact group, <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {The one asset} =2 {Both assets} other {All {count, number} assets}} which this contact group is assigned to will use the default device messaging contact group.',
        noAssets: 'This contact group is not assigned to any assets.',
        defaultGroup: 'This group cannot be removed because it is your organisation\'s default device messaging contact group. Before removing this group, you must set another group as the default.',
        snackbar: {
          success: 'Device messaging contact group, {name} removed successfully',
        },
      },
    },
    people: {
      addPerson: {
        title: 'Add a new contact person',
        alert: {
          error: 'An unexpected error prevented this person from being added. Please try again.',
        },
        skip: 'Skip',
        cancel: 'Cancel',
        save: 'Save',
        saving: 'Saving...',
      },
      addContacts: {
        title: 'Set default contact details for {name}',
        requirements: 'Every contact person should have an SMS and voice phone number',
        requirementsImportant: 'This contact person must have a default SMS and phone number because they are the current primary or secondary contact for the default ICE contact group.',
        phone: 'Voice phone number',
        sms: 'SMS phone number',
        email: 'Email address',
        copyFromSms: 'Copy from SMS',
        cancel: 'Cancel',
        save: 'Save',
        saving: 'Saving...',
        saved: 'Saved',
        alert: {
          error: 'An unexpected error prevented contacts from being added. Please try again.'
        },
      },
      deletePerson: {
        title: 'Remove person',
        ariaLabel: 'Remove person',
        message: 'Are you sure you want to permanently remove <strong>{name}</strong>?',
        loadingGroups: 'Loading groups for this person',
        noWarnings: 'This contact person can be safely removed.',
        memberOfGroups: 'Removing {name} will impact the below contact groups.',
        iceContactGroup: 'ICE',
        messagingWhitelistContactGroup: 'Device Messaging Contact Group',
        cancel: 'Cancel',
        confirm: 'Confirm removal',
        columns: {
          groupType: 'Group type',
          group: 'Group name',
          warnings: 'Warnings',
        },
        errorDeleting: 'An unexpected error prevented this person from being removed.',
        snackbar: {
          deletedSuccessfully: '{name} removed successfully',
        },
        warnings: {
          personCount: 'Removal will leave {n, plural, =0 {no people} one {only one person} =2 {only two people} other {# people}} in this group.',
          noPrimary: 'Removal will leave this group without a primary contact person.',
          default: 'This person is the {order, plural, one {primary} other {secondary}} contact person for your organisation\'s default ICE contact group.',
          defaultAction: 'Before {name} can be removed, they must be removed from the group\'s primary or secondary contact people.',
        },
        managePeople: 'Manage people on this group',
        default: 'Default',
      },
      editPersonContact: {
        title: 'Edit {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}}',
        ariaLabel: 'Edit {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}}',
        cancel: 'Cancel',
        save: 'Save',
        contactType: '{type, select, phone {Phone number} sms {Phone number} email {Email address} other {}}',
        setDefault: 'Set as the default {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}} for this person.',
        errorEditing: 'An unexpected error prevented this {type, select, phone {phone number} sms {phone number} email {email address} other {}} from being changed.',
        errorEditing409: 'This {type, select, phone {phone number} sms {phone number} email {email address} other {}} has been changed since you last loaded it. <br></br>The latest version is now displayed. Please review and retry your changes if needed.',
        snackbar: {
          editedSuccessfully: '{type, select, phone {Voice phone number} sms {SMS phone number} email {Email address} other {}} {value} changed successfully',
        },
      },
      deletePersonContact: {
        title: 'Remove {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}}',
        ariaLabel: 'Remove {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}}',
        message: 'Are you sure you want to remove <strong>{value}</strong>?',
        cancel: 'Cancel',
        confirm: 'Confirm removal',
        errorDeleting: 'An unexpected error prevented this {type, select, phone {voice phone number} sms {SMS phone number} email {email address} other {}} from being removed.',
        snackbar: {
          deletedSuccessfully: '{type, select, phone {Voice phone number} sms {SMS phone number} email {Email address} other {}} {value} removed successfully',
        },
        warning: 'Removal will leave {person} without {type, select, phone {a voice phone number} sms {an SMS phone number} email {an email address} other {}}.',
      }
    },
    peopleGroups: {
      groupTypes: {
        notification: 'Notification',
        contact: 'Contact',
      },
      assignPeople: {
        error: 'An unexpected error prevented these people from being added.',
        error409: 'Other changes to this group\'s assigned people were made before your changes were saved. The latest changes are now displayed. Please review and retry your changes if needed.',
        tooltips: {
          missingRequiredContacts: 'This person is missing a default voice or SMS phone number',
        },
        snackbar: {
          success: 'People assigned to contact group successfully',
        },
      },
      delete: {
        title: 'Remove {groupType} group',
        message: 'Are you sure you want to remove contact group, <strong>{name}</strong>?',
        assetsWarning: '{count, plural, one {The one asset} =2 {Both assets} other {All {count, number} assets}} which this contact group is assigned to will use the default device messaging contact group.',
        noAssets: 'This contact group is not assigned to any assets.',
        defaultGroup: 'This group cannot be removed because it is your organisation\'s default group. Before removing this group, you must set another group as the default.',
        manageRule: 'Manage contact groups for {name}',
        lastGroupWarning: 'Removal will not leave any contact groups in this notification rule.',
        deleteAlert: 'This contact group cannot be removed yet because it is assigned to notification rules. See details of the rules below and change assigned contact groups as needed.',
        snackbar: {
          success: '{groupType} group, {name} removed successfully',
        },
      },
      columns: {
        name: 'Notification rule name',
        warning: 'Warnings',
      },
      tooltips: {
        edit: 'Edit',
      },
    },
    markers: {
      common: {
        validationMessages: {
          outOfRange: 'Value out of range',
        },
        fields: {
          name: {
            title: 'Name',
            error: 'A name must be provided',
          },
          icon: {
            title: 'Icon',
            options: {
              generic: 'Generic',
              airport: 'Airport',
              fire: 'Fire',
              fuel: 'Fuel',
              water: 'Water',
            },
          },
          colour: {
            title: 'Colour',
          },
          latitude: {
            title: 'Latitude',
            error: 'A coordinate must be provided',
          },
          longitude: {
            title: 'Longitude',
            error: 'A coordinate must be provided',
          },
          notes: {
            title: 'Notes',
          },
        },
        actions: {
          save: 'Save',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },
      create: {
        title: 'Create new marker',
      },
      delete: {
        title: 'Delete: {name}',
        description: 'Are you sure you want to delete this marker?',
      },
    },
    annotations: {
      point: {
        title: {
          create: 'Add new point',
          edit: 'Edit point'
        },
        types: {
          circle: "Circle",
          pin: "Pin"
        }
      },
      path: {
        title: {
          create: 'Add new path',
          edit: 'Edit path'
        },
      },
      errors: {
        name: 'Name is required',
        nameLength: 'Name is too long',
        descriptionLength: 'Description is too long',
        latitude: 'A Valid latitude is required',
        longitude: 'A Valid longitude is required',
        width: 'Width must be positive'
      },
      fields: {
        name: 'Name',
        description: 'Description',
        latitude: 'Latitude',
        longitude: 'Longitude',
        icon: 'Icon',
        width: 'Line Width'
      },
      actions: {
        cancel: 'Cancel',
        save: 'Save',
      }
    },
    inferredEvents: {
      help: {
        title: 'Inferred Events',
        description: 'Inferred events are generated based speed and elevation. They are most useful for assets where the device is not generating events.',
        continue: 'Continue',
      },
    },
    searchPatterns: {
      create: {
        title: 'Add Search Pattern',
      },
      delete: {
        title: 'Delete Search Pattern',
        description: 'Are you sure you want to delete {name}?',
      },
      type: 'Type',
      defaultName: `{type, select,
        parallelTrack {New Parallel Track}
        creepingLine {New Creeping Line}
        expandingBox {New Expanding Box}
        sectorSearch {New Sector Search}
        rangeBearingLine {New Range-Bearing Line}
        other {New Search Pattern}
      }`,
      parallelTrack: 'Parallel Track',
      creepingLine: 'Creeping Line',
      expandingBox: 'Expanding Box',
      sectorSearch: 'Sector Search',
      rangeBearingLine: 'Range-Bearing Line',
      cancel: 'Cancel',
      add: 'Add',
      confirm: 'Confirm',
    },
  },
  omnibox: {
    modules: {
      search: {
        inputPlaceholder: 'Search...'
      },
      datePicker: {
        viewHistoricalData: 'View Historical Data',
        historicSnackBar: 'Viewing historic data. Live data will not display while in historic mode',
        resetToLive: 'Reset to Live'
      },
      queries: {
        assets: 'Assets',
        devices: 'Devices',
        legs: 'Legs',
        missions: 'Missions'
      },
      options: {
        title: 'Grouping & Sorting',
        groupBy: 'Group By',
        sortBy: 'Sort By',
        none: 'None',
        latestActivity: 'Latest Activity',
        make: 'Make',
        model: 'Model',
        status: 'Status',
        owner: 'Owner',
        category: 'Category',
        type: 'Type',
        watchlist: 'Watchlist Group',
        operator: 'Operator',
        activity: 'Activity',
        assetGroup: 'Asset Group',
        name: `{assetLabelKey, select,
          callSign {Callsign}
          tailNumber {Tail Number}
          other {Name}
        }`
      },
      results: {
        lastActive: 'Last Active',
        firmwareOutdated: 'Firmware Outdated',
        trackingSuspended: 'Tracking Suspended',
        batteryPercentage: 'Battery charge %',
        unassigned: 'Unassigned',
        underway: 'Underway',
        noId: 'No ID',
        noResults: 'No Results',
        unknownQuery: 'Unknown Query',
        never: 'Never',
        neverHistoric: 'Not Active',
        other: 'Other',
        drone: 'Drone',
        heartbeat: 'Heartbeat',
        person: 'Person',
        worker: 'Worker',
        'executive man': 'Executive',
        'executive woman': 'Executive',
        bicycle: 'Bicycle',
        truck: 'Truck',
        bus: 'Bus',
        ambulance: 'Ambulance',
        car: 'Car',
        'fire truck': 'Fire Truck',
        'police car': 'Police Car',
        minibus: 'Minibus',
        'mobile home': 'Mobile Home',
        motorcycle: 'Motorcycle',
        tractor: 'Tractor',
        yacht: 'Yacht',
        tug: 'Tug',
        'container ship': 'Container Ship',
        powerboat: 'Powerboat',
        container: 'Container',
        aircraft: 'Aircraft',
        airship: 'Airship',
        helicopter: 'Helicopter',
        balloon: 'Balloon',
        rocket: 'Rocket',
        unknown: 'Unknown',
        system: 'System',
        standard: 'Standard',
        ACTIVE: 'Activated',
        INACTIVE: 'Deactivated',
        SUSPENDED: 'Suspended',
        ERROR: 'Error',
        UNKNOWN: 'Unknown'
      },
      groupQueryResults: {
        hideOnMap: 'Hide on map',
        showOnMap: 'Show on map',
        never: 'Never',
        other: 'Other',
        drone: 'Drone',
        heartbeat: 'Heartbeat',
        person: 'Person',
        worker: 'Worker',
        'executive man': 'Executive',
        'executive woman': 'Executive',
        bicycle: 'Bicycle',
        truck: 'Truck',
        bus: 'Bus',
        ambulance: 'Ambulance',
        car: 'Car',
        'fire truck': 'Fire Truck',
        'police car': 'Police Car',
        minibus: 'Minibus',
        'mobile home': 'Mobile Home',
        motorcycle: 'Motorcycle',
        tractor: 'Tractor',
        yacht: 'Yacht',
        tug: 'Tug',
        'container ship': 'Container Ship',
        powerboat: 'Powerboat',
        container: 'Container',
        aircraft: 'Aircraft',
        airship: 'Airship',
        helicopter: 'Helicopter',
        balloon: 'Balloon',
        rocket: 'Rocket',
        unknown: 'Unknown',
        system: 'System',
        loading: 'Loading...',
        timeBuckets: {
          fifteenMinutes: 'Active now',
          hour: 'Active in the last hour',
          day: 'Active in the last day',
          week: 'Active in the last week',
          month: 'Active in the last month',
          older: 'Active more than a month ago',
          never: 'Never',
          neverHistoric: 'Not active'
        }
      }
    },
    settings: {
      expandPanel: 'Expand Panel',
      collapsePanel: 'Collapse Panel',
      hideAll: 'Hide All',
      showAll: 'Show All',
      groupAndSort: 'Group and Sort'
    }
  },
  contextbox: {
    asset: {
      details: {
        lastActive: 'Last Active',
        device: 'Device',
        devices: 'Devices',
        noDevices: 'No Devices',
        editAsset: 'Edit Asset',
        viewAsset: 'View Asset',
        name: 'Name',
        callSign: 'Callsign',
        tailNumber: 'Tail Number',
        makeModelVariantTooltip: 'Make, Model, Variant',
        messagingHandleTooltip: 'Messaging Handle',
        noSerialSet: '{serialType} not set',
        tpSerial: 'TracPlus Serial',
        imei: 'IMEI',
        satNumber: 'Satellite phone',
        smsNumber: 'Satellite SMS',
        manufacturerSerial: 'Manufacturer Serial',
        owner: 'Owner',
        trackingRates: 'Tracking Rates',
        cellular: 'Cellular',
        satellite: 'Satellite',
        fixRate: 'Fix rate',
        inactiveRate: 'Inactive rate',
        trackingSuspended: 'Tracking suspended',
        rock7SuspendedHelp: 'Learn how to resume tracking',
        firmwareOutdated: 'Firmware is outdated',
        firmwareOutdatedHelp: 'Learn how to update firmware',
        gprsNever: 'GPRS Strategy is set to Never',
        gprsNeverHelp: 'Learn how to update GPRS Strategy',
        gprsNeverDialog: {
          title: 'How to update GPRS Strategy',
          description: 'When GPRS Strategy is set to Never, the device will never use cellular tracking. GPRS Strategy can be changed using TP Connect.',
          close: 'Close',
        },
      },
      latestActivity: {
        title: 'Latest Activity',
        departure: 'Departure',
        takeoff: 'Takeoff',
        landing: 'Landing',
        arrival: 'Arrival',
        elapsed: 'Elapsed',
        showAllReports: 'Show all reports'
      },
      legs: {
        title: 'Legs',
        elapsed: 'Elapsed',
        unknown: 'Unknown'
      },
      activity: {
        title: 'Activity',
        report: 'Report',
        reports: 'Reports',
        filteredEvent: 'Filtered Event',
        filteredEvents: 'Filtered Events',
        filterEvents: 'Filter Events',
        filteredForm: 'Filtered Form',
        filteredForms: 'Filtered Forms',
        noActivity: 'There is no recorded activity for this Asset yet.',
        totalDistance: 'Total Distance',
        totalTime: 'Total Time',
        inferredEvent: 'Inferred Event',
        actionButtons: {
          jumpToLatestTooltip: 'Jump to Latest Activity',
          viewInTripAnalysisTooltip: 'View in Trip Analysis',
          filterTooltip: 'Filter Events',
          beta: 'Beta',
          historicSnackBar: 'Viewing historic data. Live data will not display while in historic mode.',
          analyzeReportData: 'Analyze Report Data'
        },
        filter: {
          eventsFilter: 'Events Filter',
          selectAll: 'Select All',
          clearAll: 'Clear All',
          close: 'Close'
        },
      },
      messages: {
        title: 'Latest Messages'
      },
      distress: {
        title: 'The asset is in Distress',
        viewDistressDetails: 'View Details',
        cancelTitle: 'Cancel Distress Mode',
        cancelText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        handleTitle: 'Handle Distress Mode',
        handleText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        startTime: 'Incident Start Time',
        currentStatus: 'Most Recent Report',
        asset: 'Asset',
        time: 'Report Time',
        altitude: 'Altitude',
        latitude: 'Latitude',
        longitude: 'Longitude',
        speed: 'Speed',
        courseBearing: 'Track',
        notAvailable: 'Not Available',
        distressIncidentDetails: 'Distress Incident Details',
        close: 'Close'
      },
      ams: {
        title: 'Active Monitoring Service'
      },
      charts: {
        altitudeElavationHeader: 'Altitude/Elevation',
        speedHeadingHeader: 'Speed/Heading',
        altitudeTitle: 'Altitude',
        elevationTitle: 'Elevation',
        speedTitle: 'Speed',
        headingTitle: 'Track'
      },
      jobs: {
        title: 'Jobs',
        start: 'Start',
        finish: 'Finish'
      }
    },
    device: {
      details: {
        lastActive: 'Last Active',
        status: 'Status',
        assignedTo: 'Assigned to',
        deviceUnassigned: 'Device Unassigned',
        unassigned: 'Unassigned',
        serialNumbers: 'Serial Numbers',
        tpSerial: 'TracPlus',
        imei: 'IMEI',
        manufacturerSerial: 'Manufacturer'
      }
    },
    leg: {
      details: {
        from: 'From',
        to: 'To',
        departed: 'Departed',
        tookOff: 'Took Off',
        landed: 'Landed',
        arrived: 'Arrived',
        elapsed: 'Elapsed',
        asset: 'Asset',
        'make-model': 'Make/Model',
        pilot: 'Pilot'
      }
    },
    mission: {
      details: {
        route: 'Route',
        start: 'Start',
        end: 'End',
        elapsed: 'Elapsed',
        asset: 'Asset',
        'make-model': 'Make/Model',
        pilot: 'Pilot'
      }
    },
    settings: {
      expandPanel: 'Expand Panel',
      collapsePanel: 'Collapse Panel'
    }
  },
  shared: {
    units: {
      altitude: 'Altitude',
      speed: 'Speed',
      time: 'Time',
      distance: 'Distance',
      elevation: 'Elevation'
    },
    calendar: {
      today: 'Today',
      day: 'Day',
      week: 'Week',
      month: 'Month'
    },
    dataLoader: {
      noData: 'No Data'
    },
    multiStageForm: {
      optional: 'Optional',
      finished: "All steps completed - you're finished",
      reset: 'Reset',
      back: 'Back',
      next: 'Next',
      finish: 'Finish',
      skip: 'Skip'
    },
    underConstruction: {
      message: 'This page is currently under construction, and may not be functional.'
    },
    staffIndicator: {
      staffmember: 'TracPlus Staff'
    },
    inferredEvents: {
      INFERRED_MOVEMENT_START: 'Movement Start',
      INFERRED_MOVEMENT_END: 'Movement End',
      INFERRED_TAKEOFF: 'Takeoff',
      INFERRED_LANDING: 'Landing',
    },
    inferredEventsWithPrefix: {
      INFERRED_MOVEMENT_START: 'Inferred Movement Start',
      INFERRED_MOVEMENT_END: 'Inferred Movement End',
      INFERRED_TAKEOFF: 'Inferred Takeoff',
      INFERRED_LANDING: 'Inferred Landing',
    },
  },
  statusbar: {
    regional: {
      title: 'Regional',
      description: 'Manage the language and timezone preferences',
      tooltip: 'Regional Settings',
      languageLabel: 'Language',
      timezoneLabel: 'Timezone',
      timezonePlaceholder: 'Choose a Timezone'
    },
    display: {
      title: 'Display Settings',
      tooltip: 'Display Settings'
    },
    systemStatus: {
      tooltip: 'System Status',
      title: 'System Status'
    },
    settings: {
      displaySettings: 'Display Settings',
      units: 'Units',
      general: 'General',
      locale: 'Locale'
    },
    displayMode: {
      tooltip: 'Toggle Display Mode',
      label: 'Display Mode'
    }
  },
  colorPicker: {
    useCustomColor: 'Choose a custom color',
    useSuggestedColor: 'Use a Suggested Color'
  },
  passwordStrength: {
    passwordStrength: 'Password strength',
    veryWeak: 'Very Weak',
    weak: 'Weak',
    moderate: 'Moderate',
    strong: 'Strong',
    veryStrong: 'Very Strong'
  },
  tags: {
    unknown: '',
    ams: {
      checkedin: 'Checked In',
      ofconcern: 'Of Concern',
      overdue: 'Overdue'
    },
    distress: {
      indistress: 'In Distress',
      notindistress: ''
    },
    airborne: {
      airborne: 'Airborne',
      landed: 'Landed'
    },
    engine: {
      engineoff: 'Shutdown',
      engineon: 'Running',
      warmingup: 'Warming Up'
    }
  },
  analysisbox: {
    timeline: {
      searchAssets: 'Search Assets',
      allAssets: 'All Assets',
      showTimeline: 'Show Timeline',
      hideTimeline: 'Hide Timeline',
    },
    standard: 'Standard',
    timestamp: 'Timestamp',
    coordinates: 'Coordinates',
    altitude: 'Altitude',
    bearing: 'Track',
    speed: 'Speed',
    event: 'Event',
    inferredEvents: 'Inferred Events',
    text: 'Text',
    dop: 'DOP',
    latency: 'Latency',
    elapsed: 'Elapsed',
    distance: 'Distance',
    gateway: 'Gateway',
    metadata: 'Metadata',
    battery: 'Battery',
    export: 'Export',
    collapsePanel: 'Collapse Panel',
    expandPanel: 'Expand Panel',
    hideReports: 'Hide Reports',
    showReports: 'Show Reports',
    eventFilter: {
      filterEvents: 'Filter Events',
      filterEvent: 'Filter Event',
      summaryText: 'Event Types'
    },
    fieldFilter: {
      selectColumns: 'Select Columns',
      summaryText: 'Columns'
    }
  },
  billing: {
    allowanceTypes: {
      ptt: 'PTT',
      australian_voice: 'Australian Voice',
      voice: 'Voice',
      '3rd_party': '3rd Party',
      cell_ip: 'Cellular IP',
      iridium_sms: 'Iridium SMS',
      mailbox_check: 'Mailbox Check',
      sbd: 'Iridium SBD',
      sbd_registration: 'Iridium SBD Registration',
      sms: 'SMS Messages',
      inmarsat: 'Inmarsat',
      dial_up_data: 'Dial Up Data',
      direct_internet_data: 'Direct Internet Data'
    },
    planDetails: {
      failedToLoadAddons: 'Failed to load available addons. Please refresh to try again.',
      failedToLoadTags: 'Failed to load tags. Please try again.',
      name: 'Name',
      monthlyCost: 'Monthly Cost',
      activationFee: 'Activation Fee',
      deactivationFee: 'Deactivation Fee',
      tags: 'Tags',
      includedAddons: 'Included Addons',
      assignToAnyDeviceModel: 'Assign to any device model',
      deviceModelRestrictions: 'Device Model Restrictions',
      planDetails: 'Plan Details',
      unlimited: 'Unlimited',
      allowancesAndOverages: 'Air Time Allowances and Overages',
      allowance: 'Air Time Allowance',
      overage: 'Overage',
      includedPlanAddons: 'Included Plan Addons',
      includedPlanAddonsDesc: 'Addons that are included in the price of this plan.',
      deviceModelAssignmentRestrictions: 'Device Model Assignment Restrictions',
      deviceModelAssignmentRestrictionsDesc: 'Restrict the device models this plan can be assigned to. Note, leaving the checkbox unchecked and no device models selected means the plan cannot be assigned to any device model'
    },
    addonDetails: {
      name: 'Name',
      monthlyCost: 'Monthly Cost',
      type: 'Type',
      addonDetails: 'Addon Details',
      device: 'Device',
      organisation: 'Organisation'
    },
    assignments: {
      title: 'Billing',
      currentPlan: 'Current Plan:',
      name: 'Name:',
      monthlyCost: 'Monthly Cost:',
      startDate: 'Start Date:',
      endDate: 'End Date:',
      discount: 'Discount:',
      viewDetails: 'View Details',
      noPlanAssigned: 'No plan currently assigned',
      loading: 'Loading...',
      planHistory: 'Plan History:',
      currentPlanDetails: 'Current Plan Details',
      historicPlanDetails: 'Historic Plan Details',
      currentAddons: 'Current Addons:',
      currentOrgAddons: 'Current Organisation Addons:',
      addonHistory: 'Addon History:',
      quantity: 'Quantity:',
      currentAddonDetails: 'Current Addon Details',
      historicAddonDetails: 'Historic Addon Details',
      assignNewPlan: 'Assign New Plan',
      assignNewAddon: 'Assign New Addon',
      failedToLoadPlanAssignments: 'Failed to load plan assignments. Please refresh and try again',
      failedToLoadAddonAssignments: 'Failed to load addon assignments. Please refresh and try again',
      failedToLoadPlanDetails: 'Failed to load plan details. Please refresh and try again',
      failedToLoadAddonDetails: 'Failed to load addon details. Please refresh and try again',
      failedToLoadAllPlans: 'Failed to load all plans. Plan assignment will not be available.',
      failedToLoadAllAddons: 'Failed to load all addons. Addon assignment will not be available.',
      errorAssigningDevicePlan: 'Error assigning new device plan. Please refresh and try again.',
      planAssignmentCreated: 'Plan assignment successfully created.',
      errorAssigningAddon: 'Error assigning new addon to device. Please refresh and try again.',
      addonAssignmentCreated: 'Addon assignment successfully created.',
      planCancelled: 'Plan successfully cancelled',
      failedToCancelPlan: 'Failed to cancel plan',
      addonCancelled: 'Addon successfully cancelled',
      failedToCancelAddon: 'Failed to cancel addon',
      planUpdated: 'Plan successfully updated',
      failedToUpdatePlan: 'Failed to update plan',
      addonUpdated: 'Addon successfully updated',
      failedToUpdateAddon: 'Failed to update addon',
      setNewStartDate: 'Set New Start Date',
      setNewEndDate: 'Set New End Date',
      areYouSure: 'Are you sure?',
      no: 'No',
      yes: 'Yes',
      cancel: 'Cancel',
      timeNote1: 'Please note this is in UTC and the start/end date',
      timeNote2: 'of the adjacent assignment will also need to',
      timeNote3: 'be adjusted, if applicable.',
      addon: 'Addon',
      'discount%': 'Discount %',
      discount$: 'Discount $',
      calculatedMonthlyCost: 'Calculated Monthly Cost: ',
      save: 'Save',
      plan: 'Plan',
      retired: ' (Retired) ',
      showRetired: 'Show Retired',
      incursActivationFee: 'Incurs Activation Fee {activationFee}',
      incursDeactivationFee: 'Incurs Deactivation Fee {deactivationFee}',
      cancelPlan: 'Cancel Plan',
      cancelAddon: 'Cancel Addon',
      modifyStartDate: 'Modify Start Date',
      modifyEndDate: 'Modify End Date',
      visibleAssignment: 'Visible to customers',
      invisibleAssignment: 'Not visible to customers',
      makeAssignmentVisible: 'Make visible to customers',
      makeAssignmentInvisible: 'Make invisible to customers',
      assignNewPool: 'Assign New Air Time Pool',
      allowancePool: 'Air Time Pool',
      cancelAllowancePool: 'Cancel Air Time Pool',
      failedToLoadPoolAssignments: 'Error loading air time pool assignments. Please refresh and try again',
      errorAssigningPool: 'Error assigning new pool to this device. Please refresh and try again',
      poolCreated: 'Air time pool assignment successfully created',
      planDescription: 'Plan details and history for this device',
      addons: 'Addons',
      addonDescription: 'Addon details and history for this device',
      noAssignedAddons: 'No addons currently assigned',
      pooling: 'Air Time Pooling',
      poolingDescription: 'Air time pooling details and history for this device',
      currentPools: 'Current Air Time Pools:',
      noAssignedPools: 'No air time pools assigned',
      poolHistory: 'Air Time Pool History:',
      poolName: 'Pool Name:',
      allowance: 'Air Time Allowance:'
    },
    pools: {
      failedToLoadPlans: 'Failed to load plans. Please refresh and try again',
      failedToLoadAllowancePools: 'Failed to load air time pools. Please refresh and try again',
      errorUpdatingPool: 'Error updating air time pool. Please refresh and try again',
      errorCreatingPool: 'Error creating new air time pool. Please refresh and try again',
      errorCancellingPool: 'Error cancelling air time pool. Please refresh and try again',
      poolCreated: 'Air time pool successfully created',
      poolUpdated: 'Air time pool successfully updated',
      poolDeleted: 'Air time pool successfully deleted',
      poolCancelled: 'Air time pool successfully cancelled',
      createPool: 'Create new air time pool',
      name: 'Name',
      plan: 'Plan',
      allowance: 'Air Time Allowance',
      editPool: 'Edit air time pool',
      deletePool: 'Delete air time pool',
      allowancePools: 'Air Time Pools',
      actions: 'Actions',
      save: 'Save',
      cancel: 'Cancel',
      showRetired: 'Show Retired',
      newPool: 'New Air Time Pool',
      modifyPool: 'Modify Air Time Pool',
      retired: ' (Retired) '
    }
  },
  limitedList: {
    more: 'and {n} more',
  },
  labs: {
    dialog: {
      continue: 'Continue',
    },
    features: {
      warnings: {
        noAssetsEnabled: 'No assets enabled',
        nAssetsDisabled: '{n, plural, one {# disabled asset} other {# disabled assets}}',
      },
      tooltips: {
        assetsDisabled: 'The {featureName} feature is not enabled for these assets:',
      },
      unknown: {
        title: 'Unknown',
        description: 'Unknown'
      },
      map: {
        weather: {
          title: 'Live weather',
          description: 'Display live wind speed and direction on the map',
          imageAlt: '',
        },
        adsb: {
          title: 'ADS-B mapping',
          description: 'View positions for all aircraft in the airspace with live positioning sourced from ADS-B',
          imageAlt: '',
        },
        '3d': {
          title: '3D Map',
          description: 'View the terrain and show the actual height of the aircraft above it.',
          imageAlt: '',
        },
        markers: {
          title: 'Markers',
          description: 'View marked points of interest',
          imageAlt: '',
        },
        containmentLines: {
          title: 'Containment Lines',
          description: 'View containment lines on the map',
          imageAlt: '',
        },
        firefightingOptions: {
          title: 'Module not available',
          description: 'Requires the \'Aerial Firefighting\' module',
          imageAlt: '',
        },
        clouds: {
          title: 'Real-Time Clouds',
          description: 'Allow a basemap showing daily satellite imagery including clouds.'
        },
        droplines: {
          title: 'Drop Lines',
          description: 'Display where water or retardant was dropped on the map.',
        },
        marine: {
          title: 'Marine maps',
          description: 'Allow display of nautical charts',
        },
        annotations: {
          title: 'Annotations',
          description: 'Create features on your map including points, paths and search patterns. These are only visible to you and your current session.',
        }
      },
      manage: {
        geofencing: {
          title: 'Geofences',
          description: 'Get notified when an asset enters, leaves, or crosses the boundary of an enclosed area.',
          imageAlt: ''
        },
        assetGroups: {
          title: 'Asset Groups',
          description: 'Enable ability to create/edit/delete asset groups which are a collection of assets.',
          imageAlt: ''
        },
        notifications: {
          title: 'Notifications',
          description: 'Enable users to create/edit/delete notifications for any given event/event group.',
          imageAlt: ''
        },
      },
      beacon: {
        ios: {
          title: 'Beacon',
          description: 'Manage beacon'
        }
      },
      assets: {
        jobs: {
          title: 'Jobs System',
          description: 'Jobs system build for NIWA',
        },
      },
      reporting: {
        activitySummaryReport: {
          title: 'Activity Summary Report',
          description: 'View aggregated statistics for asset activity.'
        },
        aerialFirefightingReport: {
          title: 'Aerial Firefighting Report',
          description: 'Detailed analysis of drops by asset and suppressant type.'
        },
        dailyFlightSummary: {
          title: 'Daily Flight Summary',
          description: 'View aggregated flights for an asset for a day, edit supplementary data, and approve flights.'
        },
        geofences: {
          title: 'Geofence Event Reporting',
          description: 'View historic data for geofence crossings events.'
        },
        newTripReports: {
          title: 'Trip Reports',
          description: 'View graphs and data for trips.'
        },
        supplementaryData: {
          title: 'Supplementary Data',
          description: 'Supplementary data associated with trips.'
        },
        trackingDataReports: {
          title: 'Tracking Data Reports',
          description: 'View and download reports of tracked asset activity',
        },
      },
    },
  },
};

export default messages;

type DeepRequired<T> = {
  [K in keyof T]: Required<DeepRequired<T[K]>>
}

export type MessagesEN = DeepRequired<typeof messages>;
